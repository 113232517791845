
import React, { useState, useEffect, useRef,useContext } from 'react';
import axios from 'axios';
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import {Link, useNavigate } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import $ from 'jquery';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
import { Chart } from 'react-chartjs-2';
import UserContext from './UserContext';
import moment from 'moment';

function ViewDashboard(){

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const API_URL = process.env.REACT_APP_API_URL;
    const [inquiry, setInquiry] = useState([]);
    const [customer, setCustomer] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [uniqueCustomers, setUniqueCustomers] = useState([]);
    const [totalRevenue, setTotalRevenue] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [todayBookings, setTodayBookings] = useState([]);
    const [totalRevenueAll, setTotalRevenueAll] = useState([]);
    const [locations, setLocations] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [localInventory, setLocalInventory] = useState([]);
    const chartRef = useRef(null);
    const barChartInstanceRef = useRef(null);
    const barChartInstanceRef2 = useRef(null);
    const chartInstanceRef = useRef(null);
    const chartInstanceRef2 = useRef(null);
    const lineChartRef = useRef(null);
    const userContext = useContext(UserContext);
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [percentageChange, setPercentageChange] = useState(0);
    const [customerPercentageChange , setCustomerPercentageChange] = useState(0);
    const [revenuePercentageChange , setRevenuePercentageChange] = useState(0);


    const filteredResult = bookings.filter((item) => {
        return `${item.firstname} ${item.lastname}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.booking_ref_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.location_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.game_name.toLowerCase().includes(searchQuery.toLowerCase()) 
      });

      const filteredTodayBookings = todayBookings.filter((item) => {
        return `${item.firstname} ${item.lastname}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.booking_ref_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.location_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.game_name.toLowerCase().includes(searchQuery.toLowerCase()) 
      });


      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

      const getDayName = (date) => {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return days[date.getDay()];
      };

    useEffect(() => {
        if (userContext.username) {
          setIsLoading(true);
        //   AOS.init({ duration: 1200 });
            getLocationByUser();
        }
      }, [userContext.username]);

      const calculatePercentageChange = (current, previous) => {
        if (previous === 0) return 0; // Avoid division by zero
        return ((current - previous) / previous) * 100;
    };
    
      
      async function getLocationByUser() {

        axios.get(`${API_URL}/view-team-member-by-username/${userContext.username}`).then(res => {
         if(res.data.status === 200){
           const bookings = res.data.bookings;
           setBookings(res.data.bookings);

           const today = new Date();
           const todayDate = today.toISOString().split('T')[0]; // Format the date

           // Filter the bookings to only include today's bookings
           const todaysBookings = bookings.filter(booking => {
               const bookingDate = new Date(booking.booked_date).toISOString().split('T')[0]; // Adjust `booking.date` according to your API format
               return bookingDate === todayDate;
           });


           const currentWeekStart = getStartOfWeek(today);
           const previousWeekStart = new Date(currentWeekStart);
           previousWeekStart.setDate(currentWeekStart.getDate() - 7);

           const currentWeekBookings = getBookingsByWeek(bookings, currentWeekStart);
           const previousWeekBookings = getBookingsByWeek(bookings, previousWeekStart);
         
           const percentageChange = calculatePercentageChange(currentWeekBookings.length, previousWeekBookings.length);
        //    console.log(currentWeekBookings,previousWeekBookings, percentageChange );
           setPercentageChange(percentageChange);


           



           setTodayBookings(todaysBookings);
           setLocations(res.data.locations);
           setCustomers(res.data.customers);
           const uniqueCustomers = Array.from(new Map(res.data.customers.map(customer => [customer.phone, customer])).values());
           setUniqueCustomers(uniqueCustomers);


           const currentWeekCustomers = getCustomersByWeek(uniqueCustomers, currentWeekStart);
           const previousWeekCustomers = getCustomersByWeek(uniqueCustomers, previousWeekStart);
           
           // Calculate percentage change for customers
           const customerPercentageChange = calculatePercentageChange(currentWeekCustomers.length, previousWeekCustomers.length);
           setCustomerPercentageChange(customerPercentageChange);
           setTotalRevenueAll(res.data.totalRevenue);
           setPaymentHistory(res.data.paymentsByBookingId);
       
           const totalRevenue = res.data.totalRevenue.reduce((sum, payment) => sum + parseFloat(payment.payment_amount), 0);
       
           setTotalRevenue(totalRevenue.toFixed(2));
   

           const currentWeekRevenue = getRevenueByWeek(res.data.totalRevenue, currentWeekStart);
           const previousWeekRevenue = getRevenueByWeek(res.data.totalRevenue, previousWeekStart);
           
           // Calculate percentage change for revenue
           const revenuePercentageChange = calculatePercentageChange(currentWeekRevenue, previousWeekRevenue);
           setRevenuePercentageChange(revenuePercentageChange);



            const formattedBookedTime = formatBookedTime(bookings.booked_time);

            processBookingData(bookings);
         }
         else if(res.data.status === 404){
             //setMessage(res.data.message);
             //console.log(res.data.status);
             }

             const $sticky = $(".sticky");
             const $stickyrStopper = $(".sticky-stopper");
             if (!!$sticky.offset()) {
               // Sticky element exists
               // Add your sticky logic here
               // Make sure to replace '$' with 'window.$' if using jQuery in a separate file
             }
    
             const counters = document.querySelectorAll(".value");
             const speed = 400;
         
             counters.forEach((counter) => {
               const animate = () => {
                 const value = +counter.getAttribute("count");
                 const data = +counter.innerText;
         
                 const time = value / speed;
                 if (data < value) {
                   counter.innerText = Math.ceil(data + time);
                   setTimeout(animate, 1);
                 } else {
                   counter.innerText = value;
                 }
               };
         
               animate();
             });
    
    
             
         setIsLoading(false);
      });
        
    
 }
     
   
 const getStartOfWeek = (date) => {
    const d = new Date(date);
    const day = d.getDay(); 
    const diff = d.getDate() - day; // Adjust to get the previous Sunday
    return new Date(d.setDate(diff));
};

const getBookingsByWeek = (bookings, startOfWeek) => {
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6); // Add 6 days to get the end of the week

    return bookings.filter(booking => {
        const bookingDate = new Date(booking.booked_date);
        return bookingDate >= startOfWeek && bookingDate <= endOfWeek;
    });
};

const getCustomersByWeek = (customers, weekStart) => {
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekStart.getDate() + 6); // End of the week (Saturday)
    
    return customers.filter(customer => {
        const customerDate = new Date(customer.created_at); // Ensure `created_at` is correctly formatted
        return customerDate >= weekStart && customerDate <= weekEnd;
    });
};

const getRevenueByWeek = (payments, weekStart) => {
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekStart.getDate() + 6); // End of the week (Saturday)
    
    return payments
        .filter(payment => {
            const paymentDate = new Date(payment.created_at); // Ensure `payment_date` is correctly formatted
            return paymentDate >= weekStart && paymentDate <= weekEnd;
        })
        .reduce((sum, payment) => sum + parseFloat(payment.payment_amount), 0); // Sum up payments
};

        function processBookingData(bookings) {
            // Assuming bookings has properties like: { location, booked_time }
            const monthlyData = {};
            const locationsList = [...new Set(bookings.map(b => b.location_name))];
    
            locationsList.forEach(location => {
                monthlyData[location] = Array(12).fill(0); // Initialize array for each month
            });
    
            bookings.forEach(booking => {
                const month = new Date(booking.booked_date).getMonth();
                const location = booking.location_name;
    
                if (monthlyData[location]) {
                    monthlyData[location][month] += 1; // Increment count for the month
                }
            });
    
            const labels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            const datasets = locationsList.map(location => ({
                label: location,
                data: monthlyData[location],
                backgroundColor: getRandomColor(), // Function to get random color
                borderWidth: 1,
            }));
    
            updateChart(labels, datasets);
        }
    
        function updateChart(labels, datasets) {
            if (barChartInstanceRef.current) {
                barChartInstanceRef.current.destroy();
            }
    


            const ctx = document.getElementById('dashboardBarChart1').getContext('2d');
            barChartInstanceRef.current = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: labels,
                    datasets: datasets,
                },
                options: {
                    scales: {
                        x: { grid: {
                            display: false, // Hide y-axis grid lines
                          },beginAtZero: true },
                        y: { beginAtZero: true },
                    },
                },
            });
        }
    
        function getRandomColor() {
            // Generate a random color
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }


        useEffect(() => {

//             if (barChartInstanceRef.current) {
//                 barChartInstanceRef.current.destroy();
//               }
           
//               // Create a new chart instance
//               const bar = document.getElementById('dashboardBarChart1').getContext('2d');
//               barChartInstanceRef.current = new Chart(bar, {
//                 type: 'bar',
//                 data: {
//                     labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
//                   datasets: [{
//                     label: "Sales Value",
//                     data: [12, 19, 3, 5, 2, 3],
//                     backgroundColor: ["green", "green", "green", "green", "green", "green"],
//                     borderWidth: 1,
//                 },
//                 {
//                     label: "Inventory",
//                     data: [10, 15, 10, 8, 12, 17],
//                     borderWidth: 1,
//                 },
//             ],
//         },
//         options: {
//             scales: {
//                 y: {
//                     beginAtZero: true,
//                 },
//             },
//         },
                
//               }); 




//               if (barChartInstanceRef2.current) {
//                 barChartInstanceRef2.current.destroy();
//               }
           
//               // Create a new chart instance
//               const bar2 = document.getElementById('dashboardBarChart2').getContext('2d');
//               barChartInstanceRef2.current = new Chart(bar2, {
//                 type: 'bar',
//                 data: {
//                     labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
//                   datasets: [{
//                     label: "Sales Value",
//                     data: [12, 19, 3, 5, 2, 3],
//                     backgroundColor: ["green", "green", "green", "green", "green", "green"],
//                     borderWidth: 1,
//                 },
//                 {
//                     label: "Inventory",
//                     data: [10, 15, 10, 8, 12, 17],
//                     borderWidth: 1,
//                 },
//             ],
//         },
//         options: {
//             scales: {
//                 y: {
//                     beginAtZero: true,
//                 },
//             },
//         },
                
//               }); 


if (lineChartRef.current) {
    lineChartRef.current.destroy();
  }

  const getWeekDates = (weekOffset) => {
    const now = new Date();
    now.setDate(now.getDate() - now.getDay() + (weekOffset * 7)); // Start of the week
    const startDate = new Date(now);
    now.setDate(now.getDate() + 6); // End of the week
    const endDate = new Date(now);
    return { startDate, endDate };
  };

  const { startDate: currentWeekStart, endDate: currentWeekEnd } = getWeekDates(0);
    const { startDate: previousWeekStart, endDate: previousWeekEnd } = getWeekDates(-1);

    // Function to get daily revenue
    const getDailyRevenue = (startDate, endDate) => {
      const dailyRevenue = {};
      totalRevenueAll.forEach(payment => {
        const paymentDate = new Date(payment.created_at);
        if (paymentDate >= startDate && paymentDate <= endDate) {
          const dayName = getDayName(paymentDate);
          if (!dailyRevenue[dayName]) {
            dailyRevenue[dayName] = 0;
          }
          dailyRevenue[dayName] += parseFloat(payment.payment_amount);
        }
      });
      return dailyRevenue;
    };

    const currentWeekRevenue = getDailyRevenue(currentWeekStart, currentWeekEnd);
    const previousWeekRevenue = getDailyRevenue(previousWeekStart, previousWeekEnd);

    // Generate the list of days for the x-axis
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const currentWeekData = days.map(day => currentWeekRevenue[day] || 0);
    const previousWeekData = days.map(day => previousWeekRevenue[day] || 0);

  // Create a new chart instance
  const ctx1 = document.getElementById('dashboardLineChart').getContext('2d');
  lineChartRef.current = new Chart(ctx1, {
    type: 'line',
    data: {
      labels: days,
      datasets: [
        {
          label: 'Current Week Revenue',
          data: currentWeekData,
          fill: false,
          borderColor: '#42A5F5',
          tension: 0.1
        },
        {
          label: 'Previous Week Revenue',
          data: previousWeekData,
          fill: false,
          borderColor: '#FF5733',
          tension: 0.1
        }
      ],
    },
    options: {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              return `${context.dataset.label}: $${context.raw.toFixed(2)}`;
            }
          }
        }
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Day'
          }
        },
        y: {
          title: {
            display: true,
            text: 'Revenue ($)'
          }
        }
      }
    }
  });


            if (chartInstanceRef.current) {
             chartInstanceRef.current.destroy();
           }
       
       
            const customerData = locations.map(location => {
                const uniqueCustomers = new Set(customers
                  .filter(customer => customer.location_id === location.id)
                  .map(customer => customer.phone));
          
                return { name: location.location_name, customerCount: uniqueCustomers.size };
              });
          
              const customerLabels = customerData.map(data => data.name);
              const customerCounts = customerData.map(data => data.customerCount);
              const backgroundColors1 = [
                "#FF9999", // Light Red
  "#66B2FF", // Light Blue
  "#99FF99", // Light Green
  "#FFCC99", // Light Orange
  "#FFB3E6", // Light Pink
  "#C2C2F0", // Light Blue-Violet
  "#FF6666", // Red
  "#D9EAD3", // Pale Green
  "#EAD1DC", // Pale Pink
  "#B9B9B9"  // Gray
              ];


           // Create a new chart instance
           const ctx = document.getElementById('dashboardPieChart1').getContext('2d');
           chartInstanceRef.current = new Chart(ctx, {
             type: 'pie',
             data: {
                labels: customerLabels,
                datasets: [{
                label: "Customers",
                data: customerCounts,
                backgroundColor: backgroundColors1.slice(0, customerLabels.length), // Adjust colors based on number of locations
                borderWidth: 2,
            }, ],
        },
           });

           if (chartInstanceRef2.current) {
            chartInstanceRef2.current.destroy();
          }

const revenueData = locations.map(location => {
    const revenue = totalRevenueAll
      .filter(payment => payment.location_id === location.id)
      .reduce((sum, payment) => sum + parseFloat(payment.payment_amount), 0);
    
    return { name: location.location_name, revenue };
  });
       
const labels = revenueData.map(data => data.name);
const data = revenueData.map(data => data.revenue);
const backgroundColors = [
  "#F6DFB2", "#9fd79a", "#E4F59D", "#B2E9F6", "#DCB2F6", "#F6B2E7"
];


          // Create a new chart instance
          const ctx2 = document.getElementById('dashboardPieChart2').getContext('2d');
          chartInstanceRef2.current = new Chart(ctx2, {

            type: 'doughnut',
            data: {
              labels: labels,
              datasets: [{
                label: "Revenue",
                data: data,
                backgroundColor: backgroundColors.slice(0, labels.length), // Adjust colors based on number of locations
                borderWidth: 2,


            // type: 'doughnut',
            // data: {
            //     labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
            //   datasets: [{
            //     label: "Sales Value",
            //     data: [50, 20, 23, 32, 5, 9],
            //     backgroundColor: [
            //         "#F6DFB2",
            //         "#9fd79a",
            //         "#E4F59D",
            //         "#B2E9F6",
            //         "#DCB2F6",
            //         "#F6B2E7",
            //     ],
            //     borderWidth: 2,
            }, ],
        },
          });

              // Clean up the chart instance on unmount
   return () => {

    // if (barChartInstanceRef.current) {
    //     barChartInstanceRef.current.destroy();
    //     barChartInstanceRef.current = null;
    //   }
  
    //   if (barChartInstanceRef2.current) {
    //     barChartInstanceRef2.current.destroy();
    //     barChartInstanceRef2.current = null;
    //   }

    if (lineChartRef.current) {
        lineChartRef.current.destroy();
        lineChartRef.current = null;
      }

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
      chartInstanceRef.current = null;
    }

    if (chartInstanceRef2.current) {
        chartInstanceRef2.current.destroy();
        chartInstanceRef2.current = null;
      }
  };
   
 }, [locations, totalRevenueAll]);


 const formatBookedTime = (bookedTime) => {
    try {
      const timeSlots = JSON.parse(bookedTime); // Assuming bookedTime is a JSON string
      if (Array.isArray(timeSlots) && timeSlots.length > 0) {
        const startTime = timeSlots[0].split(' - ')[0];
        const endTime = timeSlots[timeSlots.length - 1].split(' - ')[1];
        // Convert to moment objects and format them
        const formattedStartTime = moment(startTime, 'hh:mm A').format('h:mm A');
        const formattedEndTime = moment(endTime, 'hh:mm A').format('h:mm A');
        return `${formattedStartTime} - ${formattedEndTime}`;
      }
      return 'No time slots available';
    } catch (error) {
      console.error("Error parsing booked time:", error);
      return 'Invalid time format';
    }
  };


        
         async function getCustomerList(){
 
    axios.get(`${API_URL}/view-booked-slots`).then(res => {
        if(res.data.status === 200){
          const customer = res.data.bookedSlots;
            setCustomer(res.data.bookedSlots);
        // console.log(res.data.bookedSlots);
        }
        else if(res.data.status === 404){
            //setMessage(res.data.message);
            //console.log(res.data.status);
            }
            
        setIsLoading(false);
     });
       
    }


    
    const handleViewBookings = (bookingId) => {
      console.log(bookingId);
      navigate('/booking-details/'+bookingId);      
    }; 
        

        function getInquiry(){
              axios.get(`${API_URL}/view-inqueries`).then(res=>{
                if(res.data.status === 200){
                 setInquiry(res.data.inquiry);
                }
                else if(res.data.status === 404){
                    //setMessage(res.data.message);
                    }
                }); 
            }

            function formatDate(dateString) {
                const date = new Date(dateString);
                const options = { month: 'short', day: 'numeric', year: 'numeric' };
                return date.toLocaleDateString('en-US', options);
            }

            function getCustomerList(){
 
                axios.get(`${API_URL}/view-customer`).then(res=>{
                    if(res.data.status === 200){
                      const customer = res.data.customer;
                        setCustomer(res.data.customer);
                    }
                    else if(res.data.status === 404){
                        //setMessage(res.data.message);
                        console.log(res.data.status);
                        }
                        
                    setIsLoading(false);
                 });
                }

                function getInventoryList(){
 
                    axios.get(`${API_URL}/view-inventory`).then(res=>{
                        if(res.data.status === 200){
                          const localInventory = res.data.localInventory;
                          setLocalInventory(res.data.localInventory);
            

                        }
                        else if(res.data.status === 404){
                           // setMessage(res.data.message);
                            //console.log(res.data.status);
                            }
                         
                     });
                       
                    }
                   






                   
    return(
        <div class=" d-flex dashboard">
           
            <SideNav />
            <TopBar />
            {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null} 


<div class="right_panel position contact_section h-100" style={{backgroundColor:'#f1f1f1'}} id="right_panel">
      
        <div class="main_title" >
            <h1><i class="bi bi-graph-up-arrow"></i> Dashboard</h1>
        </div>

        <div class="content_wrapper pt-0">
          
            <div class="row highlights">
                <div class="col-lg-3 col-md-6 col-6 pb-3 pb-lg-0" >
                    <div class="item vm_content_box_1 h-100">
                        <p class="text-uppercase">Location</p>
                        <h2 class="vm_font_semi_bold lh-base d-flex">
                            <i style={{fontSize:'1.0rem'}} class="bi bi-pin-map pe-2"></i>
                            <div class="value" count={locations.length}>{locations.length}</div>
                        </h2>
                    </div>
                </div>
               
                <div class="col-lg-3 col-md-6 col-6 pb-3 pb-lg-0" >
                    <div class="item vm_content_box_1 h-100">
                        <div class="d-md-flex my-2 my-md-0 justify-content-between">
                            <p class="text-uppercase">Bookings</p>
                            <div className={`d-flex align-items-center ${percentageChange >= 0 ? 'vm_bg_light_green' : 'vm_bg_light_red'} px-2`}>
                    <h5>
                        <i className={`bi bi-arrow-${percentageChange >= 0 ? 'up' : 'down'} text-${percentageChange >= 0 ? 'success' : 'danger'}`}></i>
                    </h5>
                    <p className={`text-uppercase vm_font_bold text-${percentageChange >= 0 ? 'success' : 'danger'}`}>
                        {percentageChange.toFixed(2)} %
                    </p>
                </div>
                
                        </div>
                      
                        <h2 class="vm_font_semi_bold lh-base d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <i class="bi bi-calendar-check pe-2"></i>
                <div class="value" count={bookings.length}>{bookings.length}</div>
            </div>
            {/* <span class="text-muted small" style={{fontSize:'0.75rem'}}>from last week</span> */}
        </h2>
                        
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-6 pb-3 pb-lg-0" >
                    <div class="item vm_content_box_1 h-100">
                        <div class="d-md-flex my-2 my-md-0 justify-content-between">
                            <p class="text-uppercase">Customers</p>
                            {/* <div class="d-flex align-items-center vm_bg_light_green px-2">
                                <h5><i class="bi bi-arrow-up text-success"></i></h5>
                                <p class="text-uppercase vm_font_bold text-success">+02.00 %</p>
                            </div> */}
<div className="d-flex align-items-center vm_bg_light_red px-2">
    <h5>
        <i className={`bi bi-arrow-${customerPercentageChange >= 0 ? 'up' : 'down'} text-${customerPercentageChange >= 0 ? 'success' : 'danger'}`}></i>
    </h5>
    <p className={`text-uppercase vm_font_bold text-${customerPercentageChange >= 0 ? 'success' : 'danger'}`}>
        {customerPercentageChange.toFixed(2)} %
    </p>
</div>


                        </div>
                        <h2 class="vm_font_semi_bold lh-base d-flex">
                            <i class="bi bi-people pe-1"></i>
                    <div class="value" count={uniqueCustomers.length}>{uniqueCustomers.length}</div>

                        </h2>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-6 pb-3 pb-lg-0" >
                    <div class="item vm_content_box_1 h-100">
                        <div class="d-md-flex my-2 my-md-0 justify-content-between">
                            <p class="text-uppercase">Revenue</p>
                            {/* <div class="d-flex align-items-center vm_bg_light_green px-2">
                                <h5><i class="bi bi-arrow-up vm_text_color_secondary"></i></h5>
                                <p class="text-uppercase vm_font_bold vm_text_color_secondary">+29.08 %</p>
                            </div> */}

<div className={`d-flex align-items-center ${revenuePercentageChange >= 0 ? 'vm_bg_light_green' : 'vm_bg_light_red'} px-2`}>
                    <h5>
                        <i className={`bi bi-arrow-${revenuePercentageChange >= 0 ? 'up' : 'down'} text-${revenuePercentageChange >= 0 ? 'success' : 'danger'}`}></i>
                    </h5>
                    <p className={`text-uppercase vm_font_bold text-${revenuePercentageChange >= 0 ? 'success' : 'danger'}`}>
                        {revenuePercentageChange.toFixed(2)} %
                    </p>
                </div>

                        </div>
                        <h2 class="vm_font_semi_bold lh-base d-flex">
                            <span class="currency">CAD</span>
                            <div class="value currency_value" count={totalRevenue}>{totalRevenue}</div>
                        </h2>
                    </div>
                </div>
            
            
            </div>


            

            <div class="row pt-4"  style={{ display: 'flex', alignItems: 'stretch' }}>
                <div class="col-md-6 pb-4 pb-lg-0" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div class="vm_content_box_1" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                        <div class="title d-flex justify-content-between">
                            <h2>Latest Bookings</h2>
                            {/* <div class="d-flex">
                                <div class="btn_shake">
                                    <i class="bi bi-bell-fill text-danger fs-6 btn_shake"></i>
                                </div>
                                <span>3</span>
                            </div> */}
                        </div>
                        <div class="content"style={{overflowY: 'auto', height: '326px' }}>


                        {/* <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div> */}


<div class="table-responsive">
<table class="display inventory_table_list dataTable dtr-inline" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th>Booking Id</th>
                                    <th>Customer Name</th>
                                    <th>Players</th>
                                    <th>Game Package</th>
                                    <th>Location</th>
                                    {/* <th>Booked By</th> */}
                                    {/* <th>Booked On</th> */}
                                    <th class="d-flex justify-content-md-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredResult.length === 0 ? (
        <tr>
          <td colSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
            <p className="odd" style={{ margin: 0 }}>No records found</p>
          </td>
        </tr>
      ) : (
                            filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                           
                                <>
                                {/* <tr data-aos="fade-right" data-aos-delay="" data-aos-duration="1200"> */}
                                   <tr >
                                   <td style={{ verticalAlign: 'middle'}}>{item.booking_ref_id}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.firstname} {item.lastname}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.total_players}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.game_type_name ||
                                     (item.package_name === 'Corporate' 
                                    ? `${item.package_name} - ${item.corporate_package_name}`
                                   : `${item.package_name} - ${item.game_package_name}`
                                    )}
                                        
                                       </td>
                                   
                                    <td style={{ verticalAlign: 'middle'}}>{item.location_name}</td>
                                    {/* <td style={{ verticalAlign: 'middle'}}>{item.game_name}</td> */}
                                    {/* <td style={{ verticalAlign: 'middle'}}>{item.first_name} {item.last_name}</td> */}
                                    {/* <td style={{ verticalAlign: 'middle'}}>{moment(item.created_at).format('MMM D, YYYY, h:mm A')}</td> */}
                                    <td >
                                        <div class="d-flex justify-content-md-end" style={{margin:"7px 0px 0px 0px"}}>
                                        <button class="btn btn-sm btn_secondary"  onClick={() => handleViewBookings(item.id)} style={{ background: "orange", color: "white", margin: "0px 10px 0px 0px"}} >
                                        <i class="bi bi-eye"></i></button>
                                        {/* <Link style={{color:"white"}} to={`/edit-customer/${item.id}`} class="btn btn-sm btn_secondary " data-bs-target="#createNewCustomre"><i class="bi bi-pencil-square"></i></Link>
                                          
                                            <button class="btn btn-sm btn_secondary" data-bs-target="#createNewCustomre" style={{ background: "red", margin: "0px 0px 0px 10px" }}
                                     onClick={(e) => deleteCustomer(e, item.id)}><i class="bi bi-trash3"></i></button> */}
                               </div>
                                    </td>
                                </tr></>
)))}
                            </tbody>
                            <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                         
                        </table>
                        </div>
                        <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Show 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>



                            {/* <div>
                            {filteredResult.length==0?
                <p style={{ textAlign: "center" }}>No Latest Bookings available</p>
                :

(filteredResult.map((item, index) => (  



    <div class="border-bottom pb-3 pt-2">
            <Link to={`/booking-details/${item.booking_id}`}>
            <h5 class="vm_font_semi_bold  pb-1">{item.firstname} {item.lastname} - {item.booking_ref_id}</h5>
              
               
                <div class="d-md-flex justify-content-between">


                <div className="d-flex align-items-center">
                            <i className="bi bi-calendar3 me-2 text-success" ></i>
                            <span>{moment(item.booked_date).format('D MMMM YYYY')}</span>
                     </div>

                     <div className="d-flex align-items-center">
                            <i className="bi bi-clock-fill me-2 text-success" ></i>
                            <span>{formatBookedTime(item.booked_time)}
                            </span>
                            </div>
                 
                    <p class="lh-1 pt-2 pt-md-0"><span class="vm_font_semi_bold"><i style={{fontSize:'0.7rem'}} class="bi bi-pin-map"></i></span> {item.location_name} </p>
                </div>
               
            </Link>
        </div>
                               ))
                               )}
                                
                            </div> */}


                            
                        </div>
                    </div>
                </div>

                
                <div class="col-lg-6 pb-4 pb-lg-0" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div class="vm_content_box_1" style={{ flex: 1 }}>
                        <div class="title">
                            <h2>Monthly Booking Report</h2>
                            {/* <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div> */}
                        </div> 
                        <div class="content" style={{ flex: 1 }}>
                        {/* <canvas id="dashboardPieChart1" width="680" height="680" style={{display: "block", boxSizing: "borderBox", height: "272px",width: "272px"}}></canvas> */}
                        <canvas id="dashboardBarChart1" width="1520" height="760" style={{display: "block", boxSizing: "borderBox", height: "304px", width: "608px"}}></canvas>
                        </div>
                    </div>
                </div>
            </div>
           




            {/* <div className="row highlights pt-1 pb-4 ">
            {locations.map(location => (
                <div key={location.id} className="col-lg-3 col-md-6 col-6 pb-3 pb-lg-0 mt-4">
                    <div className="item vm_content_box_1 h-100">
                        <p className="text-uppercase">Location: {location.location_name}</p> */}
                        {/* <h2 className="vm_font_semi_bold lh-base d-flex">
                            <i style={{ fontSize: '1.0rem' }} className="bi bi-pin-map pe-2"></i>
                            <div className="value" count={locations.length}>{locations.length}</div>
                        </h2> */}
                        {/* <p>Bookings: {bookings.filter(b => b.location_id === location.id).length}</p>
                        <p>Customers: {Array.from(new Set(customers.filter(b => b.location_id === location.id).map(b => b.phone))).length}</p>
                        <p>Revenue: CAD {totalRevenueAll.filter(b => b.location_id === location.id).reduce((sum, payment) => sum + parseFloat(payment.payment_amount), 0)}</p>
                   
                       
                    </div>
                </div>
            ))}
        </div> */}




<div class="row pt-4">
            <div class="col-lg-6 pb-4 pb-lg-0" >
                {/* <div class="col-lg-6 pb-4 pb-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200"> */}
                    <div class="vm_content_box_1">
                        <div class="title">
                        <h2>Revenue Comparison: Current Week vs Previous Week</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content">
                        <canvas id="dashboardLineChart" width="1520" height="760" style={{display: "block", boxSizing: "borderBox", height: "304px", width: "608px"}}></canvas>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 pb-4 pb-md-0" 
                // data-aos="fade-down" data-aos-delay="" data-aos-duration="1200"
                >
                    <div class="vm_content_box_1 h-100">
                        <div class="title">
                            <h2>Total Customers</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content">
                        <canvas id="dashboardPieChart1" width="680" height="680" style={{display: "block", boxSizing: "borderBox", height: "272px", width: "272px"}}></canvas>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6" 
                // data-aos="fade-down" data-aos-delay="" data-aos-duration="1200"
                >
                    <div class="vm_content_box_1 h-100">
                        <div class="title">
                            <h2>Total Revenue</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content">
                          <canvas id="dashboardPieChart2" width="680" height="680" style={{display: "block", boxSizing: "borderBox", height: "272px", width: "272px"}}></canvas>
                        </div>
                    </div>
                </div>
            </div>



        <div class="col-md-12 pb-4 pt-4 pb-lg-0" >
                    <div class="vm_content_box_1">
                        <div class="title d-flex justify-content-between">
                            <h2>Today Bookings</h2>
                        
                        </div>
                        <div class="content" >

                        <div class="table-responsive">
<table class="display inventory_table_list dataTable dtr-inline" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th>Booking Id</th>
                                    <th>Customer Name</th>
                                    <th>Players</th>
                                    <th>Adults</th>
                                    <th>Kids</th>
                                    <th>Game Package</th>
                                    <th>Amount Paid</th>
                                    <th>Balance</th>
                                    <th>Status</th>
                                    <th>Location</th>
                                    <th class="d-flex justify-content-md-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                           

{filteredTodayBookings.length === 0 ? (
        <tr>
          <td colSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
            <p className="odd" style={{ margin: 0 }}>No records found</p>
          </td>
        </tr>
      ) : (

                                   filteredTodayBookings.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => {
                                             const bookingPayments = paymentHistory[item.booking_id] || [];
                                             const totalPaymentAmount = bookingPayments.reduce((total, payment) => total + parseFloat(payment.payment_amount), 0).toFixed(2);
                                           
                     
                                             return (   

                                   <tr  key={index} >
                                   <td style={{ verticalAlign: 'middle'}}>{item.booking_ref_id}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.firstname} {item.lastname}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.total_players}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.no_of_adults}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.no_of_children}</td>
                                    <td style={{ verticalAlign: 'middle'}}>
                                    {item.game_type_name ||
                                     (item.package_name === 'Corporate' 
                                    ? `${item.package_name} - ${item.corporate_package_name}`
                                   : `${item.package_name} - ${item.game_package_name}`
                                    )}
                                        {/* {item.game_type_name ? item.game_type_name : `${item.package_name} - ${item.game_package_name}`} */}
                                        </td>
                                   
                                    <td style={{ verticalAlign: 'middle'}}>{totalPaymentAmount}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{(item.grand_total - totalPaymentAmount).toFixed(2)}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{(item.grand_total - totalPaymentAmount) === 0 ? (   <span className="bg-success rounded-1 px-2 py-1 text-white">Paid</span>
                                     ) : (  <span className="bg-danger rounded-1 px-2 py-1 text-white">Pending</span>
                                     )}</td>
                                      <td style={{ verticalAlign: 'middle'}}>{item.location_name}</td>
                                    <td >
                                        <div class="d-flex justify-content-md-end" style={{margin:"7px 0px 0px 0px"}}>
                                        <button class="btn btn-sm btn_secondary"  onClick={() => handleViewBookings(item.id)} style={{ background: "orange", color: "white", margin: "0px 10px 0px 0px"}} >
                                        <i class="bi bi-eye"></i></button>
                                       
                               </div>
                                    </td>
                                </tr> 
                                             )
}))}
                            </tbody>
                            <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                         
                        </table>
                        </div>
                        <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredTodayBookings.length > 0 && `Show 1 to ${Math.min(perPage, filteredTodayBookings.length)} of ${filteredTodayBookings.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredTodayBookings.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>





                            
                        </div>
                    </div>
                </div>











            

            

          
            {/* <div class="row pt-4">
                <div class="col-lg-6 pb-4 pb-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1 h-100" style={{height:"400px"}}>
                        <div class="title">
                            <h2>Inventory - Approval Pending</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content">
                            <p>See and talk to your users and leads immediately by importing your data into the Front Dashboard platform.</p>
                        </div>
                        <div class="subtitle_1">
                            <h2>Latest Arrival</h2>
                        </div>
                        <div class="content pt-3">
                            <div class="d-flex align-items-center justify-content-between pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <div class="d-flex align-items-center">
                                    <div class="circle_img bg-light" style={{background: "url('https://www.topgear.com/sites/default/files/cars-car/image/2021/02/cx-5-skyactiv-g-awd-gt-sport-auto-action-3.jpg')"}}></div>
                                    <div class="ps-2">
                                        <p class="vm_font_semi_bold">Mazda 2023</p>
                                        <p>03 - April - 2023</p>
                                    </div>
                                </div>
                                <div>
                                    <button class="btn_light"><i class="bi bi-pencil-square"></i> Edit</button>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <div class="d-flex align-items-center">
                                    <div class="circle_img bg-light" style={{background: "url('https://media.cnn.com/api/v1/images/stellar/prod/220822155902-01-new-delorean-2022.jpg?c=16x9&q=h_720,w_1280,c_fill/f_webp')"}}></div>
                                    <div class="ps-2">
                                        <p class="vm_font_semi_bold">Mazda 2023</p>
                                        <p>03 - April - 2023</p>
                                    </div>
                                </div>
                                <div>
                                    <button class="btn_light"><i class="bi bi-pencil-square"></i> Edit</button>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <div class="d-flex align-items-center">
                                    <div class="circle_img bg-light" style={{background: "url('https://www.topgear.com/sites/default/files/cars-car/image/2021/02/cx-5-skyactiv-g-awd-gt-sport-auto-action-3.jpg')"}}></div>
                                    <div class="ps-2">
                                        <p class="vm_font_semi_bold">Mazda 2023</p>
                                        <p>03 - April - 2023</p>
                                    </div>
                                </div>
                                <div>
                                    <button class="btn_light"><i class="bi bi-pencil-square"></i> Edit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" style={{height:"400px"}} data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1 h-100">
                        <div class="title">
                            <h2>My To Do List</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content">
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="todo1"/>
                                <label for="todo1">
                                    <p class="vm_font_semi_bold text-success">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="todo2"/>
                                <label for="todo2">
                                    <p class="vm_font_semi_bold text-decoration-line-through">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="todo3"/>
                                <label for="todo3">
                                    <p class="vm_font_semi_bold text-success">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="todo4"/>
                                <label for="todo4">
                                    <p class="vm_font_semi_bold text-decoration-line-through">Call New Lead Submission</p>
                                    <p>Contact : Jaskaran Singh</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="todo5"/>
                                <label for="todo5">
                                    <p class="vm_font_semi_bold text-success">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

   
            {/* <div class="pt-4">
                <div class="vm_content_box_1">
                    <div class="title">
                        <h2>Customers</h2>
                        <div class="dropdown">
                            <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                            <ul class="dropdown-menu">
                                <li><a class="dropdown-item" href="#">Action</a></li>
                                <li><a class="dropdown-item" href="#">Another action</a></li>
                                <li><a class="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="content overflow_y_hidden">
                    <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>


                        <table id="example" class="display dataTable dtr-inline" >
                            <thead>
                                <tr>
                                    <th>Customer Name</th>
                                    <th>Vehicle Name</th>
                                    <th>Status</th>
                                    <th>Progress</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredResult.length === 0 ? 
                

                <div class="row" style={{margin: "10px -900px 10px 10px"}}>
                <p id="recordMessage" valign="top" class="odd" style={{ textAlign: "center" }} >No records found</p> 
                </div>
                  : null} 
                            {filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (

                                <tr data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                    <td>
                                        <div class="d-flex align-items-start">
                                            <input class="mt-1 me-2" type="checkbox" name="select" id="ramesh_kumar"/>
                                            <label for="ramesh_kumar">
                                                <p class="vm_font_semi_bold">{item.job_title}</p>
                                                <p>{item.first_name} {item.last_name}</p>
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-md-flex align-items-center">
                                            <div class="circle_img bg-light" style={{background: "url('https://www.topgear.com/sites/default/files/cars-car/image/2021/02/cx-5-skyactiv-g-awd-gt-sport-auto-action-3.jpg')"}}></div>
                                            <div class="ps-md-2">
                                                <p class="vm_font_semi_bold">Mazda 2023</p>
                                                <p>03 - April - 2023</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="d-flex align-items-center position-absolute mt-2">
                                            <span class="bg-primary rounded-1 px-2 py-1 text-white">Approved</span>
                                        </div>
                                    </td>
                                    <td>
                                        <p class="text-center vm_font_semi_bold">80%</p>
                                        <div class="progress" style={{height:"5px"}}>
                                            <div class="progress-bar bg-primary" role="progressbar" style={{width: "80%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </td>
                                    <td><button class="btn_light position-absolute mt-2"><i class="bi bi-eye-fill"></i> View</button></td>
                                </tr>
                                ))}
                               
                               
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>Customer Name</th>
                                    <th>Vehicle Name</th>
                                    <th>Status</th>
                                    <th>Progress</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                        </table>

                        <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />

                                    </div>


                    </div>
                </div>
            </div> */}

         
            {/* <div class="row pt-4">
                <div class="col-lg-6 pb-4 pb-lg-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1">
                        <div class="title">
                            <h2>Task</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content pt-3" style={{height: "300px"}}>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="task1"/>
                                <label for="task1">
                                    <p class="vm_font_semi_bold">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="task2"/>
                                <label for="task2">
                                    <p class="vm_font_semi_bold">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="task3"/>
                                <label for="task3">
                                    <p class="vm_font_semi_bold">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="task4"/>
                                <label for="task4">
                                    <p class="vm_font_semi_bold">Call New Lead Submission</p>
                                    <p>Contact : Jaskaran Singh</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="task5"/>
                                <label for="task5">
                                    <p class="vm_font_semi_bold">Make Initial Call</p>
                                    <p>Contact : Crystal Miles</p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1 h-100">
                        <div class="title">
                            <h2>Appointment</h2>
                            <div class="dropdown">
                                <i class="bi bi-three-dots-vertical vm_cursor_pointer" data-bs-toggle="dropdown" aria-expanded="false"></i>
                                <ul class="dropdown-menu">
                                    <li><a class="dropdown-item" href="#">Action</a></li>
                                    <li><a class="dropdown-item" href="#">Another action</a></li>
                                    <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="content">
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="app1"/>
                                <label for="app1">
                                    <p class="vm_font_semi_bold">Familyt Da (Regional Holiday)</p>
                                    <p>12:00am - 11:56pm</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="app2"/>
                                <label for="app2">
                                    <p class="vm_font_semi_bold">Nova Scotia Heritage Day</p>
                                    <p>12:00am - 11:56pm</p>
                                </label>
                            </div>
                            <div class="d-flex align-items-start pb-3" data-aos="fade-right" data-aos-delay="" data-aos-duration="1200">
                                <input class="mt-1 me-2" type="checkbox" name="select" id="app3"/>
                                <label for="app3">
                                    <p class="vm_font_semi_bold">Louis Riel Day</p>
                                    <p>12:00am - 11:56pm</p>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    </div>


        </div>
    );
}
export default ViewDashboard;