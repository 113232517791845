import SideNav from "./SideNav";
import TopBar from "./TopBar";
import React, { useState,  useEffect, useRef, useContext } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'datatables.net-dt/css/jquery.dataTables.css';
import $ from 'jquery';
import 'datatables.net';
import UserContext from './UserContext';
import {Link, useParams, useNavigate} from "react-router-dom";
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import moment from 'moment';


function Reports(){
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState([]);
    const [payments, setPayments] = useState([]);
    const userContext = useContext(UserContext);

    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(25);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [selectedGameLocation, setSelectedGameLocation] = useState('');
    const [game_available, setGame_available] = useState([]);
    const [selectedGame, setSelectedGame] = useState('');
    const [error_list, setErrorList] = useState([]);
    const [dateFrom, setDateFrom] = useState(""); // Start date
const [dateTo, setDateTo] = useState(""); 
const [selectedOption, setSelectedOption] = useState('bookings');
const [selectedStatus, setSelectedStatus] = useState('Completed'); 
const [showDateFields, setShowDateFields] = useState(false);  
const [searchQueryCustomers, setSearchQueryCustomers] = useState('');
const [searchQueryPayments, setSearchQueryPayments] = useState('');
const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('all');




const handleDateOptionChange = (event) => {
    const value = event.target.value;
    const today = new Date();
    
    switch (value) {
        case "today":
            setDateFrom(today.toISOString().split("T")[0]);
            setDateTo(today.toISOString().split("T")[0]);
            setShowDateFields(false);
            break;
        case "yesterday":
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);
            setDateFrom(yesterday.toISOString().split("T")[0]);
            setDateTo(yesterday.toISOString().split("T")[0]);
            setShowDateFields(false);
            break;
        case "this_week":
            const startOfWeek = new Date(today);
            startOfWeek.setDate(today.getDate() - today.getDay());
            setDateFrom(startOfWeek.toISOString().split("T")[0]);
            setDateTo(today.toISOString().split("T")[0]);
            setShowDateFields(false);
            break;
        case "last_week":
            const lastWeekStart = new Date(today);
            lastWeekStart.setDate(today.getDate() - today.getDay() - 7);
            const lastWeekEnd = new Date(lastWeekStart);
            lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
            setDateFrom(lastWeekStart.toISOString().split("T")[0]);
            setDateTo(lastWeekEnd.toISOString().split("T")[0]);
            setShowDateFields(false);
            break;
        case "this_month":
            const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
            setDateFrom(firstDayOfMonth.toISOString().split("T")[0]);
            setDateTo(today.toISOString().split("T")[0]);
            setShowDateFields(false);
            break;
        case "last_month":
            const lastMonth = new Date(today);
            lastMonth.setMonth(today.getMonth() - 1);
            const firstDayOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1);
            const lastDayOfLastMonth = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0);
            setDateFrom(firstDayOfLastMonth.toISOString().split("T")[0]);
            setDateTo(lastDayOfLastMonth.toISOString().split("T")[0]);
            setShowDateFields(false);
            break;
            case "this_year":
                const firstDayOfYear = new Date(today.getFullYear(), 0, 1);
                setDateFrom(firstDayOfYear.toISOString().split("T")[0]);
                setDateTo(today.toISOString().split("T")[0]);
                setShowDateFields(false);
                break;
            case "last_year":
                const lastYear = new Date(today.getFullYear() - 1, 0, 1);
                const firstDayOfLastYear = lastYear;
                const lastDayOfLastYear = new Date(today.getFullYear() - 1, 11, 31);
                setDateFrom(firstDayOfLastYear.toISOString().split("T")[0]);
                setDateTo(lastDayOfLastYear.toISOString().split("T")[0]);
                setShowDateFields(false);
                break;
        case "custom":
            setShowDateFields(true);
            setDateFrom('');
            setDateTo('');
            break;
        default:
            setDateFrom('');
            setDateTo('');
            setShowDateFields(false);

    }
};

    const filteredResult = customer.filter((item) => {
        const matchesSearchQuery = `${item.firstname} ${item.lastname}`.toLowerCase().includes(searchQueryCustomers.toLowerCase()) ||
             item.booking_ref_id.toLowerCase().includes(searchQueryCustomers.toLowerCase()) ||
             item.phone.toLowerCase().includes(searchQueryCustomers.toLowerCase()) ||
             item.email.toLowerCase().includes(searchQueryCustomers.toLowerCase()) || 
             item.matchingBooking.status.toLowerCase().includes(searchQueryCustomers.toLowerCase()) || 
             `${item.matchingBooking.game_type_name} ${item.matchingBooking.package_name} ${item.matchingBooking.game_package_name}`
             .toLowerCase()
             .includes(searchQueryCustomers.toLowerCase()) ||
             item.location_name.toLowerCase().includes(searchQueryCustomers.toLowerCase());


             const matchesLocation = selectedGameLocation === "all" || item.location_id === parseInt(selectedGameLocation);
             const matchesGame = selectedGame || item.game_id === parseInt(selectedGame) ;
        
        
            // Check if booking date is within the selected date range
    const matchesDate = (!dateFrom || item.matchingBooking.booked_date >= dateFrom) &&
    (!dateTo || item.matchingBooking.booked_date <= dateTo);
    const matchesStatus = selectedStatus === "all" || item.matchingBooking.status === selectedStatus;

return matchesSearchQuery && matchesLocation && matchesGame && matchesDate && matchesStatus;
  
            });


            const filteredPayments = payments.filter((item) => {
                const matchesSearchQuery = 
                      item.matchingBooking.booking_ref_id.toLowerCase().includes(searchQueryPayments.toLowerCase()) ||
                    //   item.transaction_id.toLowerCase().includes(searchQueryPayments) ||
                      item.payment_amount.includes(searchQueryPayments) || 
                      item.payment_mode.toLowerCase().includes(searchQueryPayments.toLowerCase()) || 
                      item.matchingBooking.status.toLowerCase().includes(searchQueryPayments.toLowerCase()) || 
                      item.payment_method.toLowerCase().includes(searchQueryPayments.toLowerCase()) ||
                      item.matchingBooking.status.toLowerCase().includes(searchQueryPayments.toLowerCase()) || 
                      item.matchingBooking.location_name.toLowerCase().includes(searchQueryPayments.toLowerCase());


                     const matchesLocation = selectedGameLocation === "all" || item.location_id === parseInt(selectedGameLocation);
                     const matchesGame = selectedGame || item.matchingBooking.game_id === parseInt(selectedGame) ;
                
                
                    // Check if booking date is within the selected date range
            const matchesDate = (!dateFrom || item.created_at >= dateFrom) &&
            (!dateTo || item.created_at <= dateTo);
            const matchesStatus = selectedStatus === "all" || item.matchingBooking.status === selectedStatus;
            const matchesPaymentMethods = selectedPaymentMethod === "all" || item.payment_method === selectedPaymentMethod;

        return matchesSearchQuery && matchesLocation && matchesGame && matchesDate && matchesStatus && matchesPaymentMethods;
          
                    });

   const totalAmountPaid = filteredPayments.reduce((acc, item) => acc + parseFloat(item.payment_amount), 0);


      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

      const handleGameAvailableLocationChange = (e) => {
        const selectedGameLocationId = e.target.value;
        setSelectedGameLocation(selectedGameLocationId);
       
        if(selectedGameLocationId == "all")
        {
            const locationIds = filteredLocations.map(location => location.id);
            fetchAllLocationGameAvailable(locationIds);
        }
        else{
            fetchGameAvailable(selectedGameLocationId);
        }
    };

    async  function fetchGameAvailable(locationId) {
        // setIsLoading(true); // Start loader for game fetching
         axios.get(`${API_URL}/view-available-game-by-locationId/${locationId}`)
             .then(res => {
                 if (res.data.games) {
                     setGame_available(res.data.games);
                     if (res.data.games.length > 0) {
                         setSelectedGame(res.data.games[0].id);
                     } else {
                         setSelectedGame('');
                     }
                 } else {
                     setGame_available([]);
                 }
             })
             .catch(error => {
                 console.error("Error fetching games:", error);
             })
             .finally(() => {
                setIsLoading(false); // Now that both data (location and games) have loaded, stop main loader
              });
     }




useEffect(() => {
  if (userContext.username) {
    // AOS.init({ duration: 1200 });
      getLocationByUser();
     // console.log(userContext.username)
  }
}, [userContext.username]); 


async function getLocationByUser() {
    setIsLoading(true);
    try {
        const res = await axios.get(`${API_URL}/view-team-member-by-username/${userContext.username}`);
        
        if (res.data.status === 200) {
            // Handle locations
            const filteredLocations = res.data.locations;
            setFilteredLocations(filteredLocations);
            
            if (filteredLocations.length > 0) {
                const defaultLocation = filteredLocations[0].id;
                setSelectedGameLocation('all');
                fetchGameAvailable(defaultLocation);
            }

            // Handle customer and bookings
            const customers = res.data.customers;
         
            const bookingsAll = res.data.bookingsAll;
            const enrichedCustomers = customers.map(customer => {
                const matchingBooking = bookingsAll.find(
                    booking => booking.id === customer.booking_id
                );

                return {
                    ...customer,
                    matchingBooking,
                };
            });
            
            setCustomer(enrichedCustomers);
            //  console.log(res.data.payments);
            const payments = res.data.payments;
            const payment = payments.map(payment => {
                const matchingBooking = bookingsAll.find(
                    booking => booking.id === payment.booking_id
                );

                return {
                    ...payment,
                    matchingBooking,
                };
            });
            console.log(payment);

            setPayments(payment);
        } else if (res.data.status === 404) {
            // Handle 404 status if needed
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    } 
}



    const handleViewBookings = (bookingId) => {
      navigate('/booking-details/'+bookingId);      
    };

    const deleteCustomer = (e,id) =>{
        e.preventDefault();
        var buttonClicked = window.confirm("Are you sure you want to delete Customer record?");
        if(buttonClicked == true){
           // const thisClicked = e.currentTarget;
          //  thisClicked.innerText = "Deleting";
            axios.delete(`${API_URL}/delete-customer/`+id).then(res => {  
               
          Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){  
                  
                       
               // thisClicked.closest("tr").remove();                           
              });
            //  getCustomerList();
            })
        }
        else{
           // getCustomerList();
        }
 
       }

       const getStatusStyle = (status) => {
        switch (status) {
            case 'Completed':
                return { color: 'green', fontWeight: 'bold' };
            case 'Incomplete':
                return { color: 'red', fontWeight: 'bold' };
            case 'Cancelled':
                return { color: 'blue', fontWeight: 'bold' };
            default:
                return { color: 'black' }; // Default color for unknown statuses
        }
    };


    function fetchAllLocationGameAvailable(locationIds) {
        // setIsLoading(true); // Start loader for game fetching
        const locationIdsString = locationIds.join(','); // Convert array to comma-separated string
    
        axios.get(`${API_URL}/view-available-game-by-locationIds/${locationIdsString}`)
            .then(res => {
                if (res.data.games) {
                    setGame_available(res.data.games);
                    if (res.data.games.length > 0) {
                        setSelectedGame(res.data.games[0].id);
                    } else {
                        setSelectedGame('');
                    }
                } else {
                    setGame_available([]);
                }
                // setIsLoading(false); // Stop loader for game fetching
            })
            .catch(error => {
                console.error("Error fetching games:", error);
                // setIsLoading(false); // Stop loader for game fetching
            });
    }
    const handleGameChange = (e) => {
        const selectedGameId = e.target.value;
        setSelectedGame(selectedGameId);
    };


    const handleDateFromChange = (e) => {
        setDateFrom(e.target.value);
    };
    
    const handleDateToChange = (e) => {
        setDateTo(e.target.value);
    };


    const formatBookedTime = (bookedTime) => {
        try {
          const timeSlots = JSON.parse(bookedTime); // Assuming bookedTime is a JSON string
          if (Array.isArray(timeSlots) && timeSlots.length > 0) {
            
            // Sort time slots by start time before merging
            const sortedSlots = timeSlots.sort((a, b) => {
              const [startA] = a.split(' - ');
              const [startB] = b.split(' - ');
              return moment(startA, 'hh:mm A').diff(moment(startB, 'hh:mm A'));
            });
      
            let mergedSlots = [];
            let currentRangeStart = null;
            let currentRangeEnd = null;
      
            sortedSlots.forEach((slot, index) => {
              const [startTime, endTime] = slot.split(' - ');
              const currentStartTime = moment(startTime, 'hh:mm A');
              const currentEndTime = moment(endTime, 'hh:mm A');
      
              if (!currentRangeStart) {
                // First time slot, initialize the range
                currentRangeStart = currentStartTime;
                currentRangeEnd = currentEndTime;
              } else if (currentRangeEnd.isSame(currentStartTime)) {
                // If the current slot starts when the last one ended, extend the range
                currentRangeEnd = currentEndTime;
              } else {
                // If the current slot does not continue from the last one, close the current range and start a new one
                mergedSlots.push(`${currentRangeStart.format('h:mm A')} - ${currentRangeEnd.format('h:mm A')}`);
                currentRangeStart = currentStartTime;
                currentRangeEnd = currentEndTime;
              }
      
              // If it's the last slot, push the current range
              if (index === sortedSlots.length - 1) {
                mergedSlots.push(`${currentRangeStart.format('h:mm A')} - ${currentRangeEnd.format('h:mm A')}`);
              }
            });
      
            return mergedSlots.join(', '); // Join the merged slots with a comma
          }
          return 'No time slots available';
        } catch (error) {
          console.error("Error parsing booked time:", error);
          return 'Invalid time format';
        }
      };
      

      const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleStatusChange = (e) => {
        setSelectedStatus(e.target.value);
    };

    const convertToCSV = (data) => {
        const headers = Object.keys(data[0]).join(',') + '\n';
        const rows = data.map(item => Object.values(item).join(',')).join('\n');
        return headers + rows;
    };
    

    const handleExport = (data) => {
        if (selectedOption === 'bookings') {
            downloadCSVBookings(data); // function for exporting booking data
        } else if (selectedOption === 'payments') {
            downloadCSVPayments(data); // function for exporting payment data
        }
    };



    const downloadCSVBookings = (data) => {

        const csvRows = [
            // Add your CSV headers here
                ['Booking ID', 'Customer Name', 'Phone Number', 'Email', 'Booked date','Booked time', 'Players','Adults','Kids','Game Package','Location Name','Status'], // Example headers
                ...data.map(item => [
                    item.booking_ref_id,
                    `${item.firstname} ${item.lastname}`,
                    item.phone,
                item.email,
                moment(item.matchingBooking.booked_date).format('DD-MMM-YYYY'),
                `"${formatBookedTime(item.matchingBooking.booked_time)}"`,
                item.matchingBooking.total_players,
                item.matchingBooking.no_of_adults,
                item.matchingBooking.no_of_children,
                item.matchingBooking.game_type_name ? item.matchingBooking.game_type_name : `${item.matchingBooking.package_name} - ${item.matchingBooking.game_package_name}`,
                item.location_name,
                item.matchingBooking.status,
            ])
        ];

        const csvContent = csvRows.map(e => e.join(",")).join("\n");
       // const csvData = convertToCSV(data);
       //const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'lasercombat_bookings.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


        const downloadCSVPayments = (data) => {
 
            const csvRows = [
                // Add your CSV headers here
                    ['Date','Booking ID', 'Transaction Id', 'Payment Method', 'Payment Amount', 'Payment mode','Location', 'status'], // Example headers
                    ...data.map(item => [
                       `"${moment(item.created_at).format('MMM D, YYYY, h:mm A')}"`,
                        item.matchingBooking.booking_ref_id,
                        item.transaction_id,         
                    item.payment_method,
                    item.payment_amount,
                    item.payment_mode,
                    item.matchingBooking.location_name,
                    item.matchingBooking.status,
                
                ])
            ];

        const csvContent = csvRows.map(e => e.join(",")).join("\n");
       // const csvData = convertToCSV(data);
       //const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'lasercombat_payments.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    const handlePaymentMethodChange = (e) => {
        setSelectedPaymentMethod(e.target.value);
      };
      



    return(
        <div>
<SideNav/>
      <TopBar/>

      {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}
      <div class="right_panel position contact_section vh-100" id="right_panel">

        <div class="main_title"  data-aos-delay="" data-aos-duration="1200">
            <h1><i class="bi bi-file-earmark-text"></i> Reports</h1>
           
            <button class="btn btn-sm btn_secondary" style={{ background: "#059618"}} onClick={() => handleExport(selectedOption === 'bookings' ? filteredResult : filteredPayments)}>
            <i style={{fontSize:'0.8rem'}} class="bi bi-download"></i>Export
        </button>

                {/* <button class="btn btn-sm btn_secondary">
                <Link style={{color:"white"}} to={'/create-customer'}>Create New Customer </Link>
                  </button> */}
          
        </div>
        <div class="content_wrapper pt-0" data-aos-delay="" data-aos-duration="1200">
        <div class="vm_content_box_1">
        <div class="content pt-1">
                <div class=" pt-1 pb-1">
                <div class="row ">

                <div class="content col-md-2">
                                <div class="form-group pt-2 pb-2">
                                  
                                    <label>Options</label>
        <select class="form-select" id="options" name="options" value={selectedOption} onChange={handleOptionChange}>
            <option value="bookings">Bookings</option>
            <option value="payments">Payments</option>
        </select>
                                  
                                </div>
                            </div>





                <div class="content col-md-2">
                                <div class="form-group pt-2 pb-2">
                                    <label>Location</label>
                                    <select class="form-select" id="selectedGameLocation" name="selectedGameLocation" value={selectedGameLocation} onChange={handleGameAvailableLocationChange}>
                                        <option value="">Select game location</option>
                                        <option value="all">All</option>
                                        {filteredLocations.map((location) => (
                                            <option key={location.id} value={location.id}>
                                                {location.location_name}
                                            </option>
                                        ))}
                                    </select>   {!selectedGameLocation && (
                                        <span class="text-danger">{error_list.location_id}</span>)}
                                </div>
                            </div>


                            {/* <div class="content col-md-2 ">
                                <div class="form-group pt-2 pb-2">
                                <label>Game</label>
                                    <select className="form-select me-2" id="game" name="game" value={selectedGame} onChange={handleGameChange}>
                                        <option value="">Select a game</option>
                                        {game_available.map((game) => (
                                            <option key={game.id} value={game.id}>
                                                {game.name}
                                            </option>
                                        ))}
                                    </select> {!selectedGame && (
                                        <span class="text-danger">{error_list.game_id}</span>)}
                                </div>
                            </div> */}








                          <div class="content col-md-2">
                                <div class="form-group pt-2 pb-2">
                                  
                                    <label>Status</label>
        <select class="form-select" id="status" name="status" value={selectedStatus} onChange={handleStatusChange}>
             <option value="all">All</option>
            <option value="Completed">Completed</option>
            <option value="Incomplete">Incomplete</option>
            <option value="Cancelled">Cancelled</option>
        </select>
                                  
                                </div>
                            </div>


                            {selectedOption === 'payments' && (
    <>
        <div className="content col-md-2">
            <div className="form-group pt-2 pb-2">
                <label>Payment Method</label>
                <select 
                    className="form-select" 
                    id="paymentMethod" 
                    name="paymentMethod" 
                    value={selectedPaymentMethod} 
                    onChange={handlePaymentMethodChange}
                >
                    <option value="all">All</option>
                    {Array.from(new Set(payments.map(item => item.payment_method))).map((method, index) => (
            <option key={index} value={method}>
                {method || 'Unknown'}
            </option>
        ))}
                 
                    {/* Add other payment methods if applicable */}
                </select>
            </div>
        </div>
    </>
)}

                            <div className="content col-md-2">
                <div className="form-group pt-2 pb-2">
                    <label>Date Options</label>
                    <select className="form-select" id="dateOptions" onChange={handleDateOptionChange}>
                        <option value="">Select date filter</option>
                        <option value="today">Today</option>
                        <option value="yesterday">Yesterday</option>
                        <option value="this_week">This Week</option>
                        <option value="last_week">Last Week</option>
                        <option value="this_month">This Month</option>
                        <option value="last_month">Last Month</option>
                        <option value="this_year">This Year</option>
                        <option value="last_year">Last Year</option>
                        <option value="custom">Custom</option>
                    </select>
                </div>
            </div>         

            {showDateFields && (
                <>
                            <div class="content col-md-2">
    <div class="form-group pt-2 pb-2">
        <label>Date From</label>
        <input
            type="date"
            className="form-control"
            id="dateFrom"
            name="dateFrom"
            value={dateFrom}
            onChange={handleDateFromChange}
        />
    </div>
</div>

<div class="content col-md-2">
    <div class="form-group pt-2 pb-2">
        <label>Date To</label>
        <input
            type="date"
            className="form-control"
            id="dateTo"
            name="dateTo"
            value={dateTo}
            onChange={handleDateToChange}
        />
    </div>
</div>
</>
            )}
                          

                    </div>
                    </div>
                    </div>
                    </div>
                    </div>

        <div class="content_wrapper pt-0"  data-aos-delay="" data-aos-duration="1200">
            
            <div>

                <div class="vm_content_box_1">
                    <div class="content">
                    {selectedOption === 'bookings' && (
                        
                       <div>
                        {/* <div class="main_title" style={{paddingLeft:'0px'}}>
                      <h1><i class="bi bi-book"></i> Bookings</h1>
                      </div> */}
                    <div class="row pt-3">
                    
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                   <option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQueryCustomers" id="searchQueryCustomers" placeholder="Search..." type="text" 
                                      value={searchQueryCustomers} onChange={(e) => {setSearchQueryCustomers(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                          <div class="table-responsive">
                        <table class="display inventory_table_list dataTable dtr-inline" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th>Booking Id</th>
                                    <th>Customer Name</th>
                                    <th>Booked Date</th>
                                    <th>Booked Time</th>
                                    <th>Players</th>
                                    <th>Adults</th>
                                    <th>Kids</th>
                                    <th>Game Package</th>
                                    <th>Location</th>
                                    <th>Booked On</th>
                                    <th>Status</th>
                                    <th class="d-flex justify-content-md-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredResult.length === 0 ? (
        <tr>
          <td colSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
            <p className="odd" style={{ margin: 0 }}>No records found</p>
          </td>
        </tr>
      ) : (
                          filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item) => (
                                 <tr key={item.id}>
                                   <td style={{ verticalAlign: 'middle'}}>{item.booking_ref_id}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.firstname} {item.lastname}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{moment(item.matchingBooking.booked_date).format('DD-MMM-YYYY')}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{formatBookedTime(item.matchingBooking.booked_time)}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.matchingBooking.total_players}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.matchingBooking.no_of_adults}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.matchingBooking.no_of_children}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.matchingBooking.game_type_name ? item.matchingBooking.game_type_name : `${item.matchingBooking.package_name} - ${item.matchingBooking.game_package_name}`}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.location_name}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{moment(item.created_at).format('MMM D, YYYY, h:mm A')}</td>
                                    <td style={{ verticalAlign: 'middle', ...getStatusStyle(item.matchingBooking.status) }}>{item.matchingBooking.status}</td>
                                  
                                    <td >
                                        <div class="d-flex justify-content-md-end" style={{margin:"7px 0px 0px 0px"}}>
                                        <button class="btn btn-sm btn_secondary"  onClick={() => handleViewBookings(item.booking_id)} style={{ background: "orange", color: "white", margin: "0px 10px 0px 0px"}} >
                                        <i class="bi bi-eye"></i></button>
                                        {/* <Link style={{color:"white"}} to={`/edit-customer/${item.id}`} class="btn btn-sm btn_secondary " data-bs-target="#createNewCustomre"><i class="bi bi-pencil-square"></i></Link> */}
                                          
                                           {/*  <button class="btn btn-sm btn_secondary" data-bs-target="#createNewCustomre" style={{ background: "red", margin: "0px 0px 0px 10px" }}
                                     onClick={(e) => deleteCustomer(e, item.id)}><i class="bi bi-trash3"></i></button> */}
                               </div>
                                    </td>
                                </tr>
)))}
                            </tbody>
                            <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                            {/* <tfoot>
                                <tr>
                                <th>Booking Id</th>
                                <th>Customer Name</th>
                                
                                    <th>Location</th>
                                    <th>Game</th>
                                    <th>Booked By</th>
                                    <th>Booked On</th>
                                    <th class="d-flex justify-content-md-end">Action</th>
                                </tr>
                            </tfoot> */}
                        </table>
                        </div>
                        <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>


</div>
)}


{selectedOption === 'payments' && (
    
    <div>

 <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                   <option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQueryPayments" id="searchQueryPayments" placeholder="Search..." type="text" 
                                      value={searchQueryPayments} onChange={(e) => {setSearchQueryPayments(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                          <div class="table-responsive">
                        <table class="display inventory_table_list dataTable dtr-inline" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Booking Id</th>
                                    <th>Transaction Id</th>
                                    <th>Payment Method</th>
                                    {/* <th>Card Type</th> */}
                                    {/* <th>Subtotal</th>
                                    <th>Hst</th>
                                    <th>Grand Total</th> */}
                                    <th>Payment Amount</th>
                                    <th>Payment Mode</th>
                                    <th>Location</th>
                                  
                                    <th>Status</th>
                                    {/* <th class="d-flex justify-content-md-end">Action</th> */}
                                </tr>
                            </thead>
                            <tbody>

                            {filteredPayments.length === 0 ? (
        <tr>
          <td colSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
            <p className="odd" style={{ margin: 0 }}>No records found</p>
          </td>
        </tr>
      ) : (


                          filteredPayments.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item) => (
                                 <tr key={item.id}>
                                      <td style={{ verticalAlign: 'middle'}}>{moment(item.created_at).format('MMM D, YYYY, h:mm A')}</td>
                                   <td style={{ verticalAlign: 'middle'}}>{item.matchingBooking.booking_ref_id}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.transaction_id}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.payment_method}</td>
                                 

                                    {/* <td style={{ verticalAlign: 'middle'}}>${item.matchingBooking.subtotal}</td>
                                    <td style={{ verticalAlign: 'middle'}}>${item.matchingBooking.hst}</td>
                                    <td style={{ verticalAlign: 'middle'}}>${item.matchingBooking.grand_total}</td> */}
                                    <td style={{ verticalAlign: 'middle'}}>CAD {item.payment_amount}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.payment_mode}</td>
                                    {/* <td style={{ verticalAlign: 'middle'}}>{item.location}</td> */}
                                  
                                    <td style={{ verticalAlign: 'middle'}}>{item.matchingBooking.location_name}</td>
                                    <td style={{ verticalAlign: 'middle', ...getStatusStyle(item.matchingBooking.status) }}>{item.matchingBooking.status}</td>
                                  
                                    <td >
                                        {/* <div class="d-flex justify-content-md-end" style={{margin:"7px 0px 0px 0px"}}>
                                        <button class="btn btn-sm btn_secondary"  onClick={() => handleViewBookings(item.booking_id)} style={{ background: "orange", color: "white", margin: "0px 10px 0px 0px"}} >
                                        <i class="bi bi-eye"></i></button>
                                      
                               </div> */}
                                    </td>
                                </tr>
)))}
                            </tbody>
                            <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
  <tr>
    <td colSpan="4" style={{ textAlign: "right", fontWeight: "bold" }}>Total Amount Paid:</td>
    <td style={{ verticalAlign: 'middle', fontWeight: 'bold' }}>CAD {totalAmountPaid.toFixed(2)}</td>
    <td colSpan="3"></td>
  </tr>
</tfoot>
                          
                        </table>
                        </div>
                        <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredPayments.length > 0 && `Showing 1 to ${Math.min(perPage, filteredPayments.length)} of ${filteredPayments.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredPayments.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>


</div>
)}


                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>   
    );
}
export default Reports;
