import SideNav from "./SideNav";
import TopBar from "./TopBar";
import { useState, useEffect, useContext, useRef } from "react";
import Swal from 'sweetalert2';
import axios from 'axios';
import {Link, useParams, useNavigate} from "react-router-dom";
import ReactPaginate from 'react-paginate';
import UserContext from './UserContext';

function WebSettingCorporate(){

      const API_URL = process.env.REACT_APP_API_URL;
      const [name, setName] = useState('');
      const [price, setPrice] = useState('');
      const [bulletPoints, setBulletPoints] = useState(['']);

      const [isLoading, setIsLoading] = useState(true);
      const [loading, setLoading] = useState(false);
      const userContext = useContext(UserContext);

      const [error_list, setErrorList] = useState([]);
      const [web_setting_corporate, setWeb_setting_corporate] = useState([]);
      const [searchQuery, setSearchQuery] = useState("");
      const [currentPage, setCurrentPage] = useState(0);
      const [perPage, setPerPage] = useState(10);
      const scrollToRef = useRef(null);
      const [selectedPackageSlug, setSelectedPackageSlug] = useState('');
      const [corporate_packages, setCorporate_packages] = useState([]);
   

      const filteredResult = web_setting_corporate.filter((item) => {
        return `${item.sub_package_name}`.toLowerCase().includes(searchQuery.toLowerCase()) 
      });

   
      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };
      const addBulletPoint = () => {
        setBulletPoints([...bulletPoints, ""]); // Adds a new empty bullet point
    };
    
    const removeBulletPoint = (index) => {
        setBulletPoints(bulletPoints.filter((_, i) => i !== index)); // Removes the selected bullet point
    };
    
    const handleBulletPointChange = (index, value) => {
        const updatedBulletPoints = [...bulletPoints];
        updatedBulletPoints[index] = value;
        setBulletPoints(updatedBulletPoints); // Updates the bullet point at the specified index
    };

      useEffect(() => {
        setIsLoading(true);
        getWebSettingCorporateList();
        fetchCorporatePackages();
       
    }, []);


       function getWebSettingCorporateList()
      {

        axios.get(`${API_URL}/view-corporate-package-bullet-points`).then(res=>{
          if(res.data.status === 200){
            setWeb_setting_corporate(res.data.corporateEvents); 
              scrollToRef.current.scrollIntoView({ behavior: "smooth" }); 
          }     
          else if(res.data.status === 404){
            setWeb_setting_corporate([]);
              }
              setIsLoading(false);
       });  
        
       
       
      }

      const updateLocationsForUsers = async () => {
        try {
            const response = await axios.post(`${API_URL}/update-locations-for-users`);
            if (response.data.status === 200) {
               // console.log(response.data.message);
            } else {
                //console.error('Failed to update locations for users');
            }
        } catch (error) {
            console.error('Error updating locations for users', error);
        }
    };

      async function save(event)
      {
        
          event.preventDefault();
          setLoading(true);

          const formData = new FormData()
  
          formData.append('package_slug', selectedPackageSlug);
          bulletPoints.forEach((point, index) => {
            formData.append(`bulletPoints[${index}]`, point); // You can use `bulletPoints[]` syntax for array-like values in FormData
          });
  
          formData.append('bullet_points', JSON.stringify(bulletPoints));
         //  formData.append('created_by',  userContext.userDetails.id);
          await axios.post(`${API_URL}/update-corporate-package-by-slug`, formData).then(res =>{
         
            Swal.fire({
              icon:"success",
              text:res.data.message
            }).then(function(){ 
                setLoading(false);        
                 resetData();
                 setErrorList([]);
                 getWebSettingCorporateList();
                // updateLocationsForUsers();
                 
                 
          });           
          }).catch(function(error) {
            setLoading(false);
            // if(error.response.status===422){         
            setErrorList(error.response.data.validate_err);
            // }
            // else{
            //   Swal.fire({
            //     text:error.response.data.message,
            //     icon:"error"
            //   })
            // }
          })



        
     }
    
    
     const resetData = () => {
        setName("");
        setPrice("");
        setBulletPoints(['']);   
        };


        async function deleteWebSettingCorporate(e, package_slug) {
            e.preventDefault();
            const buttonClicked = window.confirm("Are you sure you want to delete this corporate web setting record?");
            
            if (buttonClicked) {
                // Prepare the data to update the is_active status
             
                const formData = new FormData()
      
                formData.append('package_slug', package_slug);
                formData.append('bullet_points', '');
               
                await axios.post(`${API_URL}/update-corporate-package-by-slug`, formData).then(res =>{
               
                  Swal.fire({
                    icon:"success",
                    text:"Bullet Points deleted successfully"
                  }).then(function(){ 
                      setLoading(false); 
                      getWebSettingCorporateList();
                      // updateLocationsForUsers();
                       
                       
                });           
                }).catch(function(error) {
                  setLoading(false);
                  // if(error.response.status===422){         
                  setErrorList(error.response.data.validate_err);
                  // }
                  // else{
                  //   Swal.fire({
                  //     text:error.response.data.message,
                  //     icon:"error"
                  //   })
                  // }
                })
              }
              else{
                getWebSettingCorporateList(); 
              }
        };
        
        const fetchCorporatePackages = async () => {

          try {
              const response = await axios.get(`${API_URL}/view-corporate-package-by-slug`);
              if (response.data.corporateEvents) {
                const filteredCorporatePackages = response.data.corporateEvents.filter(
                  (corporateEvent) => !corporateEvent.bullet_points // Modify this key based on your actual data structure
              );
                  setCorporate_packages(filteredCorporatePackages);
            
              }
              else {
                setCorporate_packages([]);
              }
  
          } catch (error) {
              console.error("Error fetching games:", error);
  
          }
      };
  
      const handleCorporatePackageChange = (e) => {
        setSelectedPackageSlug(e.target.value);
    };
 

    return(
        <div>
            <SideNav/>
            <TopBar/>

            {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}


   <div class="right_panel position contact_section vh-100" id="right_panel" ref={scrollToRef}>

<div class="main_title d-flex align-items-center">
    <h1><i class="bi bi-sticky"></i> Web Setting</h1>
</div>

<div class="content_wrapper pt-0">
    <div class="vm_content_box_1">
        <div class="title">
            <h2>Create Corporate Package</h2>
        </div>
        <div class="content pt-3">  
                    
                        <div class="content pt-3 pb-4">
                            <div class="row">
                            <div class="col-md-4 form-group">
                            <label>Select Corporate Package</label>
                                <div>
          
          <select
              id="gamePackageDropdown"
              value={selectedPackageSlug}
              onChange={handleCorporatePackageChange}
              className="form-select"
          >
              <option value="">Select Corporate Package</option>
              {corporate_packages.map((corporatePackage) => (
                  <option key={corporatePackage.package_slug} value={corporatePackage.package_slug}>
                      {corporatePackage.sub_package_name}
                  </option>
              ))}
          </select>
      </div>
                                       <span class="text-danger">{error_list.selectedPackageSlug}</span>
                                </div>
                                {/* <div class="col-md-4 form-group">
                                <label>Price</label>
                                <input type="number" class="form-control" name="price" id="price" value={price} placeholder="Price"
                                    onChange={(event) => { setPrice(event.target.value); }} min={0}/>
                                         <span class="text-danger">{error_list.price}</span>
                                </div> */}

                                <div className="col-md-12 form-group ">
  <label>Bullet Points</label>
  {bulletPoints.map((point, index) => (
    <div key={index} className="row align-items-center mb-2">
      <div className="col-md-8 pt-3">
        <input
          type="text"
          className="form-control"
          placeholder={`Bullet Point ${index + 1}`}
          value={point}
          onChange={(e) => handleBulletPointChange(index, e.target.value)}
          required
        />
      </div>
   
      <div className="col-md-4 pt-3">
        {index > 0 && (
          <button
            type="button"
            className="btn btn-sm btn-danger me-2"
            onClick={() => removeBulletPoint(index)}
          >
          Remove
          </button>
        )}
        {index === bulletPoints.length - 1 && (
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={addBulletPoint}
          >
            Add
          </button>
        )}
      </div>
    
    </div>
  ))}
  <span className="text-danger">{error_list.bulletPoints}</span>
</div>

</div>                        
                           
                         
                    <button onClick={save} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i> {loading?"Saving":"Save"}</button>
                            
                            </div>
            
        </div>
    </div>

    <div class="vm_content_box_1 mt-4">
        <div class="title">
            <h2>Manage Corporate Package</h2>
        </div>

        <div class="content">
        <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div ><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                              
                   
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search " type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>

                         
                            <>
                            <div class="table-responsive">
                            <table class="display dataTable dtr-inline" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Price</th>
                                            <th>Bullet Points</th>
                                            <th class="text-end">Action</th>
                                        </tr>
                                    </thead>


                                    <tbody>
                                    {filteredResult.length === 0 ? (
        <tr>
          <td colSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
            <p className="odd" style={{ margin: 0 }}>No records found</p>
          </td>
        </tr>
      ) : (

                                        filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                            <tr key={index}>

                                      <td><p>{item.sub_package_name}</p> </td>
                                      <td><p>{item.basic_price}</p> </td>
                                                <td>
  { (item.bullet_points.length > 0) ? (
    JSON.parse(item.bullet_points).map((point, bulletIndex) => (
      point.trim() !== "" ? ( // Check if the point is not an empty string
        <p key={bulletIndex}>{bulletIndex + 1}. {point}</p> // Only render if point is not empty
      ) : <p>No bullet points available</p> // Render nothing for empty bullet points
    ))
  ) : (
    <p>No bullet points available</p> // Fallback in case bullet_points is not an array
  )}
</td>
                                               


                                                <td class="d-flex justify-content-end">

                                                    <Link to={`/edit-web-setting-corporate/${item.package_slug}`} class="btn btn-sm btn_secondary" data-bs-target="#createNewCustomre">
                                                        <i class="bi bi-pencil-square"></i> Edit</Link>

                                                    {/* <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => deleteWebSettingCorporate(e, item.package_slug)}><i class="bi bi-trash3"></i> Delete</button> */}
                                                </td>
                                            </tr>


                                        )))}
                                    </tbody>
                                    <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                                    {/* <tfoot>
                                        <tr>
                                            <th>Location Name</th>
                                            <th class="text-end">Action</th>
                                        </tr>
                                    </tfoot> */}
                                </table>
                                </div>
                                <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div></>
                      

        </div>
    </div>
</div>

</div>



        </div>
    )
}

export default WebSettingCorporate;