import { useState, useEffect, useRef } from "react";
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Swal from 'sweetalert2';
import placeholderImage from '../img/customer_placeholder.jpg';

function CreateTeamMember(){

    const API_URL = process.env.REACT_APP_API_URL;
    const [user_role, setUser_role] = useState("");
    const [first_name, setFirst_name] = useState("");
    const [last_name, setLast_name] = useState("");
    const [phone_no, setPhone_no] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [error_list, setErrorList] = useState([]);
    const [file, setFile] = useState("");
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [imagePreview, setImagePreview] = useState(null); 
    const [isLoading, setIsLoading] = useState(false);
    const [locations, setLocations] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);

    const handleUserRole = (event) => {
        setUser_role(event.target.value);
    };

   
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file); // Store the file in the component state
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result); // Store the file's data URL
            };
            reader.readAsDataURL(file);
        }
       
      };

      const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => { 
        AOS.init({ duration: 1200 });
        fetchLocations();

}, []);


const handleLocationChange = (e) => {
    const value = parseInt(e.target.value, 10);
  //  let updatedLocations = selectedLocations.split(',').map(Number); // Ensure it's an array
    let updatedLocations = [...selectedLocations];
    if (e.target.checked) {         
      updatedLocations = [...updatedLocations, value];
    } else {
      updatedLocations = updatedLocations.filter((id) => id !== value);
    }
  
    setSelectedLocations(updatedLocations); // Convert back to a comma-separated string

  };




const fetchLocations = async () => {
    try {
        setIsLoading(true);
        const response = await axios.get(`${API_URL}/view-game-location`);
        setLocations(response.data.gameLocation || []);
    } catch (error) {
        console.error("Error fetching locations:", error);
    }
    setIsLoading(false);
};


async function save(event)
{
  event.preventDefault();
  setLoading(true);
  const formData = new FormData()
    formData.append('file', file);
    formData.append('user_role', user_role)
    formData.append('first_name', first_name)
    formData.append('last_name', last_name)
    formData.append('phone_no', phone_no)
    formData.append('email', email)
    formData.append('address', address)
    formData.append('username', username)
    formData.append('password', password)
    formData.append('location_id', selectedLocations)

   

      await axios.post(`${API_URL}/save-team-member`, formData).then(res =>{

        Swal.fire({
            icon:"success",
            text:res.data.message
          }).then(function(){     
            setLoading(false);        
               resetData();
               setErrorList([]);
               
        });
        }).catch(function(error) {
          // if(error.response.status===422){    
             
          setErrorList(error.response.data.validate_err);
          // }
          // else{
            // Swal.fire({
            //   text:error.response.data.message,
            //   icon:"error"
            // })
            setLoading(false);    
          // }
        })
       
}

const resetData = () => {
    setFile("");
    setImagePreview(null);
    fileInputRef.current.value = null;
    setUser_role("");
    setFirst_name("");
    setLast_name("");
    setPhone_no("");
    setEmail("");
    setAddress("");
    setUsername("");
    setPassword("");
    setSelectedLocations([]);
    };


    return(
        <div>
             <SideNav/>
             <TopBar/>
             
             <div class="right_panel position create_inventory h-100" id="right_panel">
  
        <div class="main_title" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            <h1><i class="bi bi-plus-lg h1"></i> Add Team Member</h1>
        </div>

        <div class="content_wrapper pt-0">
          
            <div id="inventory_section1">
                <div class="col" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
                    <div class="vm_content_box_1">
                      
                        <div class="content pt-3 pb-4 mb-5">
                    
                        <div className="row">
                            <div class="col-md-3">
                                <div class=" form-group py-3 text-center">
                                    <label for="firstname">Add Photo</label>
                                    <img style={{ width: '150px', height: '120px', display: 'block', margin: '2px auto' }} src={imagePreview || placeholderImage} alt="Placeholder" />
                                    <input type="file" class="d-none"  ref={fileInputRef} name="user_photo" id="user_photo"  onChange={handleFileChange} />
                                    <button class="btn btn-sm btn_secondary text-white" style={{ width: '150px', marginTop: '10px' }}  onClick={() => fileInputRef.current.click()}>
            <i class="bi bi-cloud-upload me-2"></i> Upload Photo
        </button>
                                </div>
                                </div>
                                <div class="col-md-4 py-3">
                                <div class=" form-group">
                                    <label for="firstname">First Name</label>
                                    <input type="text" class="form-control" name="first_name" id="first_name" placeholder="Enter First Name" value={first_name} 
                                    onChange={(event) =>{ setFirst_name(event.target.value);}}/>
                                     <span class="text-danger">{error_list.first_name}</span>
                                </div>
                                

                                <div class="form-group">
                                    <label for="email">Email</label>
                                    <input type="text" class="form-control" name="email" id="email" value={email} placeholder="Enter Email Address"
                                     onChange={(event) => { setEmail(event.target.value); }}/>
                                        <span class="text-danger">{error_list.email}</span>
                                </div>

                                
                                <div class="form-group">
                                    <label for="phone">Phone Number</label>
                                    <input type="text" class="form-control" name="phone" id="phone" placeholder="Enter Phone Number" value={phone_no}
                                    onChange={(event) => { setPhone_no(event.target.value);} }/>
                                       <span class="text-danger">{error_list.phone_no}</span>
                                </div>

                                
                                <div class="form-group">
                                    <label for="username">Username</label>
                                    <input type="text" class="form-control" name="username" id="username" placeholder="Enter Username" value={username}
                                    onChange={(event) => { setUsername(event.target.value);} } />
                                       <span class="text-danger">{error_list.username}</span>
                                </div>

                                <div className="col-md-12">
                                <label>Select Locations</label>
                                <div class="px-3 mb-1 bg-light mt-1 pb-3 pt-3">
        

                            <div class="row ">
        {locations.map((location) => (
            <div className="col-md-12 pb-2" key={location.id}>
      <div className="form-group-checkbox d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
        
            <input
             style={{ boxShadow: "2px 2px #059618", transition: "all .5s ease" }}
              type="checkbox"
              id={`location-${location.id}`}
              value={location.id}
              onChange={handleLocationChange}
              checked={selectedLocations.includes(location.id)}
            />
            <label htmlFor={`location-${location.id}`}>{location.location_name}</label>
          </div>
          </div>
          </div>
                         
        ))}
                                </div>
                                </div>
                                <span class="text-danger">{error_list.location_id}</span>
                            </div>
                               
                                </div>
                                <div class="col-md-4 py-3">
                                <div class=" form-group">
                                    <label for="lastname">Last Name</label>
                                    <input type="text" class="form-control" name="last_name" id="last_name" placeholder="Enter Last Name" value={last_name}
                                     onChange={(event) =>{ setLast_name(event.target.value);}}/>
                                        <span class="text-danger">{error_list.last_name}</span>
                                </div>
                                <div class=" form-group">
                                    <label for="empcountry">Select User Role</label>
                                    <select class="form-select" name="user_role" id="user_role" value={user_role} onChange={handleUserRole}>
                                    <option value="">Select User Type</option>
                                        <option value="Super Admin">Super Admin</option>
                                        <option value="Coordinator">Coordinator</option>
                                    </select>
                                    <span class="text-danger">{error_list.user_role}</span>
                                </div>

                                <div class=" form-group">
                                    <label for="address">Address</label>
                                    <input type="text" class="form-control" name="address" id="address" value={address} placeholder="Address"
                                    onChange={(event) => { setAddress(event.target.value); }}/>
                                         <span class="text-danger">{error_list.address}</span>
                                </div>
                                <div class="form-group" style={{ position: 'relative' }}>
                                    <label for="password">Password</label>
                                    <input  type={showPassword ? 'text' : 'password'} class="form-control" name="password" id="password" placeholder="Enter Password" value={password}
                                    onChange={(event) => { setPassword(event.target.value);} }/>
                                        <button
                                        type="button"
                                        class={`btn btn-outline-secondary`}
                                        onClick={togglePasswordVisibility}
                                        style={{
                                            position: 'absolute',
                                            right: '10px',
                                            top: error_list.password ? '50%' : '60%', 
                                            transform: 'translateY(-50%)',
                                            background: 'none',
                                            border: 'none',
        
                                            cursor: 'pointer',
                                            color: showPassword ? '#212529' : '#6c757d' 
                                        }}
                                    >
                                        <i class={showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'}></i>
                                    </button>
                                       <span class="text-danger">{error_list.password}</span>
                                </div>  
                          
                        </div>

                         
                                <div class="col-11 text-end mt-3" >

<button onClick={save} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i> {loading?"Saving":"Save"}</button>
</div>   
                             
                               
                            </div>
                            </div>
                    </div>
                </div>

             
            </div>

        </div>
    </div>
        </div>
    );
}
export default CreateTeamMember;