import SideNav from "./SideNav";
import TopBar from "./TopBar";
import React, { useState,  useEffect, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link} from "react-router-dom";
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import noImagePlaceholder from '../img/no_image_placeholder.png';
import placeholderImage from '../img/image_placeholder.png';
import moment from 'moment';

function ViewTransactions(){
    const API_URL = process.env.REACT_APP_API_URL;
    const tableRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [bookings, setBookings] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;
    const [isImageLoading, setIsImageLoading] = useState(true);
    const [locations, setLocations] = useState([]);

    const filteredResult = transactions.filter((item) => {
     return item;
      });

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

    useEffect(() => { 
        setIsLoading(true);
          AOS.init({ duration: 1200 });
          getTransactionList();
          getBookingDetails();
}, []);



  async function getTransactionList(){
 
    axios.get(`${API_URL}/view-online-transactions`).then(res=>{
        if(res.data.status === 200){
          const transactions = res.data.transactions;
            setTransactions(res.data.transactions);
            console.log(transactions);
        }
        else if(res.data.status === 404){
            //setMessage(res.data.message);
            //console.log(res.data.status);
            }
            
        setIsLoading(false);
     });
       
    }


    function getBookingDetails() {
        axios.get(`${API_URL}/view-booked-slots`).then(res => {
            if (res.data.status === 200) {
                const bookingData = res.data.bookedSlots;
                // Assuming you want to add the booking details to each transaction:
                setBookings(bookingData);
            //    console.log(bookingData);
            } else if (res.data.status === 404) {
                console.log('No booking details found');
            }
        }).catch(error => {
            console.error("Error fetching booking details ", error);
        });
    }

    return(
        <div>
<SideNav/>
      <TopBar/>

      {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}
      <div class="right_panel position contact_section vh-100" id="right_panel">

        <div class="main_title" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            <h1><i class="bi bi-credit-card"></i> Online Transactions</h1>
           
                {/* <button class="btn btn-sm btn_secondary">
               <Link style={{color:"white"}} to={'/create-team-member'}>Create Team Member </Link> 
                  </button> */}
          
        </div>

        <div class="content_wrapper pt-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            
            <div>
                <div class="vm_content_box_1">
                    <div class="content">

                    <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              {/* <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div> */}
                          </div>
                          <div class="table-responsive">
                        <table class="display inventory_table_list dataTable dtr-inline" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                <th>Booking Id</th>
                                <th>Location</th>
                                <th>Date/Time</th>
                                <th>Transaction Id</th>
                                <th>Order Id</th>
                                <th>Customer IP</th>
                                <th>Card details</th>
                                <th>AVS result</th>
                                <th>CVV result</th>
                                <th>Address Match</th>
                                <th>Postal Match</th>
                                <th>Risk Score</th>
                                <th>Status</th>
                                <th>Amount </th>
                               
                              
                                
                                    
                                    {/* <th class="d-flex justify-content-md-end">Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                            {filteredResult.length === 0 ? (
        <tr>
          <td colSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
            <p className="odd" style={{ margin: 0 }}>No records found</p>
          </td>
        </tr>
      ) : (
                            filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => {

                                        const location = bookings.find(booking => booking.id === item.booking_id) || {};    
                                        
                                
                                           return (  
                                            
                               
                                   <tr>
                                     <td> <p>{location.booking_ref_id}</p></td>
                                     <td> <p>{location.location_name  ? location.location_name  : 'Location not found'}</p> </td>
                                        <td style={{width:'100px'}}><p>{moment(item.tran_date).format('MMM D, YYYY')}<p>  {moment(item.tran_time, 'HH:mm:ss').format('h:mm:ss A')}</p></p></td>
                                   
                                        <td> <p>{item.transaction_id} </p></td>
                                        <td> <p>{item.id} </p></td>
                                        <td> <p>{item.customer_ip} </p></td>
                                        <td> <p>{item.tran_card_type} Ending with {item.card_last_four} </p></td>
                                        <td>
          <p>{item.avs_result === 1 ? 'SUCCESS' : 'FAILED'}</p>
        </td>
        <td>
          <p>{item.cvd_result === 1 ? 'SUCCESS' : 'FAILED'}</p>
        </td>
                                        <td> <p>{item.avs_msg} </p></td>
                                        <td> <p>{item.postal_result === 1 ? 'YES' : 'NO'} </p></td>
                                        <td> <p>{item.risk_score} </p></td>
                                 
                                  <td > <p>{item.message_text} </p></td>
                               
                                  <td> <p>{item.tran_amount} </p></td>
                                 
                              
                                  <td>
                                 {/* <div class="d-flex justify-content-md-end" style={{ margin: "7px 0px 0px 0px" }}>
                                                           <Link style={{ color: "white" }} to={`/edit-team-member/${item.id}`} class="btn btn-sm btn_secondary " data-bs-target="#createTeamMember"><i class="bi bi-pencil-square"></i></Link>
                                                           <button class="btn btn-sm btn_secondary" data-bs-target="#createTeamMember" style={{ background: "red", margin: "0px 0px 0px 10px" }}
                                                               onClick={(e) => deleteTeam(e, item.id)}><i class="bi bi-trash3"></i></button>
                                                       </div> */}
                                                   </td>
                                </tr>
                                
                                           );
                                        }))}
                            </tbody>
                            <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                            {/* <tfoot>
                                <tr>
                                    <th>Team Member Name</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Location</th>
                                    <th class="d-flex justify-content-md-end">Action</th>
                                </tr>
                            </tfoot> */}
                        </table>
                        </div>
                        <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>   
    );
}
export default ViewTransactions;
