import React, {useState,useEffect} from "react";
import './App.css';
import $ from 'jquery';
import ViewInventory from './components/ViewInventory';
import SideNav from './components/SideNav';
import TopBar from "./components/TopBar";
import TopBarInventory from "./components/TopBarInventory";
import {BrowserRouter as Router,Routes, Switch, Route, useNavigate} from 'react-router-dom'
import Inventory from './components/Inventory';
import EditInventory from './components/EditInventory';
import InventorySection2 from "./components/InventorySection2";
import InventorySection3 from "./components/InventorySection3";
import EditInventorySection2 from "./components/EditInventorySection2";
import EditInventorySection3 from "./components/EditInventorySection3";
import SettingInvoicePreset from "./components/SettingInvoicePreset";
import EditSettingInvoicePreset from "./components/EditSettingInvoicePreset";
import SettingImagePreset from "./components/SettingImagePreset";
import Login from "./components/Login";
import SettingImageEditing from "./components/SettingImageEditing";
import InventoryDetails from "./components/InventoryDetails";
import { UserProvider } from './components/UserContext';
import VehicleList from "./components/VehicleList";
import EmailLogs from "./components/EmailLogs";
import ViewCustomers from "./components/ViewCustomers";
import ViewCustomerDetails from "./components/ViewCustomerDetails";
import CreateCustomer from "./components/CreateCustomer";
import EditCustomer from "./components/EditCustomer";
import Customer from "./components/Customer";
import ViewLeads from "./components/ViewLeads";
import LeadsDetails from "./components/LeadsDetails";
import SettingEmailTemplate from "./components/SettingEmailTemplate";
import EditSettingEmailTemplate from "./components/EditSettingEmailTemplate";
import SettingSmsTemplate from "./components/SettingSmsTemplate";
import EditSettingSmsTemplate from "./components/EditSettingSmsTemplate";
import SettingNoteTemplate from "./components/SettingNoteTemplate";
import EditSettingNoteTemplate from "./components/EditSettingNoteTemplate";
import SettingPinTemplate from "./components/SettingPinTemplate";
import EditSettingPinTemplate from "./components/EditSettingPinTemplate";
import SettingMeetingTemplate from "./components/SettingMeetingTemplate";
import ViewDashboard from "./components/ViewDashboard";
import SettingUploadTemplate from "./components/SettingUploadTemplate";
import EditSettingUploadTemplate from "./components/EditSettingUploadTemplate";
import ViewTeam from "./components/ViewTeam";
import CreateTeamMember from "./components/CreateTeamMember";
import EditTeamMember from "./components/EditTeamMember";
import SettingGameType from "./components/SettingGameType";
import EditSettingGameType from "./components/EditSettingGameType";
import ViewCalender from "./components/ViewCalender";

import SettingGame from "./components/SettingGame";
import EditSettingGame from "./components/EditSettingGame";
import SettingGameLocation from "./components/SettingGameLocation";
import EditSettingGameLocation from "./components/EditSettingGameLocation";
import SettingGameAvailableLocation from "./components/SettingGameAvailableLocation";
import EditSettingGameAvailableLocation from "./components/EditSettingGameAvailableLocation";
import SettingGameException from "./components/SettingGameException";
import EditSettingGameException from "./components/EditSettingGameException";
import ViewBooking from "./components/ViewBooking";
import SettingGameMainPackage from "./components/SettingGameMainPackage";
import EditSettingGameMainPackage from "./components/EditSettingGameMainPackage";
import SettingGamePackage from "./components/SettingGamePackage";
import EditSettingGamePackage from "./components/EditSettingGamePackage";
// import SettingCreateGame from "./components/SettingCreateGame";
import SettingCreateGames from "./components/SettingCreateGames";
import ConsentForm from "./components/ConsentForm";
import MultipleConsentForms from "./components/MultipleConsentForms";
import ConsentFormPage from "./components/ConsentFormPage";
import ThankYouPage from './components/ThankYouPage';
import ThankYouCompleted from './components/ThankYouCompleted';
import KidConsentForm from './components/KidConsentForm';
import Bookings from './components/Bookings';
import ViewAllBookingsBookings from './components/ViewAllBookings';
import ViewTransactions from "./components/ViewTransactions";
import Reports from "./components/Reports";
import WebSetting from "./components/WebSetting";
import EditWebSetting from "./components/EditWebSetting";
import WebSettingBirthday from "./components/WebSettingBirthday";
import EditWebSettingBirthday from "./components/EditWebSettingBirthday";
import WebSettingCorporate from "./components/WebSettingCorporate";
import EditWebSettingCorporate from "./components/EditWebSettingCorporate";
import Help from "./components/Help";
import { Navigate } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';



// import Step1 from "./components/Step1";
// import Step2 from "./components/Step2";


function App() {


  useEffect(() => {
   
  }, []);
  
  return (
    <UserProvider>
    <Router >
    <div className="App">
    
      <Routes>


   <Route path='*' element={<Login />} /> 
   <Route path='/top-bar' element={<TopBar />} />
   <Route path='/top-bar-inventory' element={<TopBarInventory />} />

   <Route path='/view-dashboard' element={<ProtectedRoute><ViewDashboard /></ProtectedRoute>} />

   <Route path='/view-inventory' element={<ProtectedRoute><ViewInventory /></ProtectedRoute>} />
   <Route path='/inventory/:id' element={<ProtectedRoute><Inventory /></ProtectedRoute>}/>
    <Route path='/inventory' element={<ProtectedRoute><Inventory /></ProtectedRoute>}/>
    <Route path='/edit-inventory/:id' element={<ProtectedRoute><EditInventory /></ProtectedRoute>} />

    <Route path='/inventorySection2/:inventory_section_id' element={<ProtectedRoute><InventorySection2 /></ProtectedRoute>} />
    <Route path='/edit-inventorySection2/:id' element={<ProtectedRoute><EditInventorySection2 /></ProtectedRoute>} />

    <Route path='/inventorySection3/:inventory_section_id' element={<ProtectedRoute><InventorySection3 /></ProtectedRoute>} />
    <Route path='/edit-inventorySection3/:inventory_section_id' element={<ProtectedRoute><EditInventorySection3 /></ProtectedRoute>} />

    <Route path='/setting-invoice-preset' element={<ProtectedRoute><SettingInvoicePreset /></ProtectedRoute>} />
    <Route path='/edit-invoice-preset/:id' element={<ProtectedRoute><EditSettingInvoicePreset /></ProtectedRoute>} />

    <Route path='/setting-image-preset' element={<ProtectedRoute><SettingImagePreset /></ProtectedRoute>} />

    <Route path='/setting-image-editing' element={<ProtectedRoute><SettingImageEditing /></ProtectedRoute>} />

    <Route path='/setting-email-template' element={<ProtectedRoute><SettingEmailTemplate /></ProtectedRoute>} />
    <Route path='/edit-email-template/:id' element={<ProtectedRoute><EditSettingEmailTemplate /></ProtectedRoute>} />

    <Route path='/setting-sms-template' element={<ProtectedRoute><SettingSmsTemplate /></ProtectedRoute>} />
    <Route path='/edit-sms-template/:id' element={<ProtectedRoute><EditSettingSmsTemplate /></ProtectedRoute>} />

    <Route path='/view-calender' element={<ProtectedRoute><ViewCalender /></ProtectedRoute>} />

    <Route path='/setting-note-template' element={<ProtectedRoute><SettingNoteTemplate /></ProtectedRoute>}  />
    <Route path='/edit-note-template/:id' element={<ProtectedRoute><EditSettingNoteTemplate /></ProtectedRoute>} />

    <Route path='/setting-pin-template' element={<ProtectedRoute><SettingPinTemplate /></ProtectedRoute>} />
    <Route path='/edit-pin-template/:id' element={<ProtectedRoute><EditSettingPinTemplate /></ProtectedRoute>} />



    <Route path='/setting-upload-template' element={<ProtectedRoute><SettingUploadTemplate /></ProtectedRoute>} />
    <Route path='/edit-upload-template/:id' element={<ProtectedRoute><EditSettingUploadTemplate /></ProtectedRoute>} />

    <Route path='/inventory-details/:id' element={<ProtectedRoute><InventoryDetails /></ProtectedRoute>} />
    <Route path='/vehicle-list' element={<ProtectedRoute><VehicleList /></ProtectedRoute>} />
    <Route path='/email-logs' element={<ProtectedRoute><EmailLogs /></ProtectedRoute>} />

    <Route path='/view-customers' element={<ProtectedRoute><ViewCustomers /></ProtectedRoute>} />
    <Route path='/view-customer-details/:id' element={<ProtectedRoute><ViewCustomerDetails /></ProtectedRoute>} />
    <Route path='/create-customer' element={<ProtectedRoute><CreateCustomer /></ProtectedRoute>} />
    <Route path='/edit-customer/:id' element={<ProtectedRoute><EditCustomer /></ProtectedRoute>} />

    <Route path='/view-leads' element={<ProtectedRoute><ViewLeads /></ProtectedRoute>} />
    <Route path='/booking-details/:id' element={<ProtectedRoute><LeadsDetails /></ProtectedRoute>} />
    <Route path='/booking-details' element={<ProtectedRoute><LeadsDetails /></ProtectedRoute>} />

    <Route path='/customer/:phoneNo' element={<ProtectedRoute><Customer /></ProtectedRoute>} />
    {/* <Route path="/customer" element={<Navigate to="/view-customers" />} /> */}

    <Route path='/view-team' element={<ProtectedRoute><ViewTeam /></ProtectedRoute>} />
    <Route path='/create-team-member' element={<ProtectedRoute><CreateTeamMember /></ProtectedRoute>} />
    <Route path='/edit-team-member/:id' element={<ProtectedRoute><EditTeamMember /></ProtectedRoute>} />

    

    <Route path='/setting-game' element={<ProtectedRoute><SettingGame /></ProtectedRoute>} />
    <Route path='/edit-game/:id' element={<ProtectedRoute><EditSettingGame /></ProtectedRoute>} />

    <Route path='/setting-game-location' element={<ProtectedRoute><SettingGameLocation /></ProtectedRoute>} />
    <Route path='/edit-game-location/:id' element={<ProtectedRoute><EditSettingGameLocation /></ProtectedRoute>} />
    {/* <Route path='/edit-game-location/:location_slug' element={<EditSettingGameLocation />} /> */}

    <Route path='/setting-game-available-location' element={<ProtectedRoute><SettingGameAvailableLocation /></ProtectedRoute>} />
    <Route path='/edit-game-available-location/:id' element={<ProtectedRoute><EditSettingGameAvailableLocation /></ProtectedRoute>} />


    <Route path='/setting-game-type' element={<ProtectedRoute><SettingGameType /></ProtectedRoute>} />
    <Route path='/edit-game-type/:id' element={<ProtectedRoute><EditSettingGameType /></ProtectedRoute>} />

    
    <Route path='/setting-game-exception' element={<ProtectedRoute><SettingGameException /></ProtectedRoute>} />
    <Route path='/edit-game-exception/:id' element={<ProtectedRoute><EditSettingGameException /></ProtectedRoute>} />

    <Route path='/view-booking' element={<ProtectedRoute><ViewBooking /></ProtectedRoute>} />
    <Route path='/view-booking/:customer_id' element={<ProtectedRoute><ViewBooking /></ProtectedRoute>} />

    <Route path='/setting-game-main-package' element={<ProtectedRoute><SettingGameMainPackage /></ProtectedRoute>} />
    <Route path='/edit-game-main-package/:id' element={<ProtectedRoute><EditSettingGameMainPackage /></ProtectedRoute>} />

    <Route path='/setting-game-package' element={<ProtectedRoute><SettingGamePackage /></ProtectedRoute>} />
    <Route path='/edit-game-package/:id' element={<ProtectedRoute><EditSettingGamePackage /></ProtectedRoute>} />

    {/* <Route path='/setting-create-game' element={<SettingCreateGame />} /> */}

    {/* <Route path='/step1' element={<Step1 />} />
    <Route path='/step2' element={<Step2 />} /> */}

      <Route path='/setting-create-games' element={<ProtectedRoute><SettingCreateGames /></ProtectedRoute>} />

      <Route path='/consent-form' element={<ConsentForm />} />

      <Route path='/multiple-consent-form' element={<MultipleConsentForms />} />

      <Route path='/consent-form-page/:id' element={<ConsentFormPage />} />
      <Route path="/thank-you/:bookingId" element={<ThankYouPage />} />
      <Route path="/thank-you-completed/:bookingId" element={<ThankYouCompleted />} />
      <Route path="/kidsForm" element={<KidConsentForm />} />

      <Route path="/bookings" element={<ProtectedRoute><Bookings /></ProtectedRoute>} />
      <Route path="/viewallbookings" element={<ProtectedRoute><ViewAllBookingsBookings /></ProtectedRoute>} />
      <Route path="/viewTransactions" element={<ProtectedRoute><ViewTransactions /></ProtectedRoute>} />

      <Route path="/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />

      <Route path="/web-setting" element={<ProtectedRoute><WebSetting /></ProtectedRoute>} />
      <Route path='/edit-web-setting/:type_slug' element={<ProtectedRoute><EditWebSetting /></ProtectedRoute>} />


      <Route path="/web-setting-birthday" element={<ProtectedRoute><WebSettingBirthday /></ProtectedRoute>} />
      <Route path='/edit-web-setting-birthday/:package_slug' element={<ProtectedRoute><EditWebSettingBirthday /></ProtectedRoute>} />

      <Route path="/web-setting-corporate" element={<ProtectedRoute><WebSettingCorporate /></ProtectedRoute>} />
      <Route path='/edit-web-setting-corporate/:package_slug' element={<ProtectedRoute><EditWebSettingCorporate /></ProtectedRoute>} />
      
      
      <Route path="/help" element={<ProtectedRoute><Help /></ProtectedRoute>} />

  </Routes>
 

    </div>
    </Router>
    </UserProvider>
  );
}

export default App;
