import SideNav from "./SideNav";
import TopBar from "./TopBar";
import React, { useState,  useEffect, useRef, useContext } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'datatables.net-dt/css/jquery.dataTables.css';
import $ from 'jquery';
import 'datatables.net';
import {Link, useParams, useNavigate} from "react-router-dom";
import axios from 'axios';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
import moment from 'moment';
import UserContext from './UserContext';

function Bookings(){
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [customer, setCustomer] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [error_list, setErrorList] = useState([]);
    const userContext = useContext(UserContext);
    const [email_template, setEmail_template] = useState([]);
    const [template, setTemplate] = useState("");
    const [template_details, setTemplate_details] = useState([]);
    const [emailData, setEmailData] = useState({
        to: '',
        subject: '',
        message: ''
    });

    // const filteredResult = customer.filter((item) => {
    //     return item.job_title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     item.first_name.toLowerCase().includes(searchQuery.toLowerCase()) 
        
    //   });

      const filteredResult = customer.filter((item) => {
        return `${item.firstname} ${item.lastname}`.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.booking_ref_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.location_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.game_name.toLowerCase().includes(searchQuery.toLowerCase()) 
      });

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };
      

//     useEffect(() => { 
//         setIsLoading(true);
//           AOS.init({ duration: 1200 });
//           getCustomerList();
// }, []);

useEffect(() => {
  if (userContext.username) {
    setIsLoading(true);
    AOS.init({ duration: 1200 });
    getEmailTemplateList();
      getLocationByUser();
  }
}, [userContext.username]); 



async function getLocationByUser() {

       axios.get(`${API_URL}/view-team-member-by-username/${userContext.username}`).then(res => {
        if(res.data.status === 200){
          const customer = res.data.bookings;
            setCustomer(res.data.bookings);

        }
        else if(res.data.status === 404){
            //setMessage(res.data.message);
            //console.log(res.data.status);
            }
            
        setIsLoading(false);
     });
       
   
}


useEffect(() => {
    // Check if email_template is loaded and has the required default template
    const defaultTemplate = email_template.find(item => item.template_name === 'Booking Cancellation');
    if (defaultTemplate) {
        setTemplate('Booking Cancellation');
        setTemplate_details(defaultTemplate);

        setEmailData({
            ...emailData,
            subject: defaultTemplate.subject,
            message: defaultTemplate.message
        });
    } else {
        // Reset if default template is not found
        setTemplate("");
        setTemplate_details({});
        setEmailData({ subject: "", message: "", to: "" });
    }
}, [email_template]);

// useEffect(() => {
//     $(tableRef.current).DataTable();
//   }, []);

function getEmailTemplateList()
{    
  axios.get(`${API_URL}/view-email-template`).then(res=>{
      if(res.data.status === 200){
          setEmail_template(res.data.emailTemplate);       
      }     
      else if(res.data.status === 404){
        setEmail_template([]);
         // setMessage(res.data.message);     
          }             
   });    
}

  async function getCustomerList(){
 
    axios.get(`${API_URL}/view-booked-slots`).then(res => {
        if(res.data.status === 200){
          const customer = res.data.bookedSlots;
            setCustomer(res.data.bookedSlots);
        // console.log(res.data.bookedSlots);
        }
        else if(res.data.status === 404){
            //setMessage(res.data.message);
            //console.log(res.data.status);
            }
            
        setIsLoading(false);
     });
       
    }

    const formatBookedTime = (bookedTime) => {
        try {
          const timeSlots = JSON.parse(bookedTime); // Assuming bookedTime is a JSON string
          if (Array.isArray(timeSlots) && timeSlots.length > 0) {
            
            // Sort time slots by start time before merging
            const sortedSlots = timeSlots.sort((a, b) => {
              const [startA] = a.split(' - ');
              const [startB] = b.split(' - ');
              return moment(startA, 'hh:mm A').diff(moment(startB, 'hh:mm A'));
            });
      
            let mergedSlots = [];
            let currentRangeStart = null;
            let currentRangeEnd = null;
      
            sortedSlots.forEach((slot, index) => {
              const [startTime, endTime] = slot.split(' - ');
              const currentStartTime = moment(startTime, 'hh:mm A');
              const currentEndTime = moment(endTime, 'hh:mm A');
      
              if (!currentRangeStart) {
                // First time slot, initialize the range
                currentRangeStart = currentStartTime;
                currentRangeEnd = currentEndTime;
              } else if (currentRangeEnd.isSame(currentStartTime)) {
                // If the current slot starts when the last one ended, extend the range
                currentRangeEnd = currentEndTime;
              } else {
                // If the current slot does not continue from the last one, close the current range and start a new one
                mergedSlots.push(`${currentRangeStart.format('h:mm A')} - ${currentRangeEnd.format('h:mm A')}`);
                currentRangeStart = currentStartTime;
                currentRangeEnd = currentEndTime;
              }
      
              // If it's the last slot, push the current range
              if (index === sortedSlots.length - 1) {
                mergedSlots.push(`${currentRangeStart.format('h:mm A')} - ${currentRangeEnd.format('h:mm A')}`);
              }
            });
      
            return mergedSlots.join(', '); // Join the merged slots with a comma
          }
          return 'No time slots available';
        } catch (error) {
          console.error("Error parsing booked time:", error);
          return 'Invalid time format';
        }
      };

      function convertPlainTextToHTML(text) {
        return text
            .replace(/\n/g, '<br>')  // Replace line breaks with <br>
            .replace(/ {2,}/g, match => '&nbsp;'.repeat(match.length));  // Replace multiple spaces with &nbsp;
    }
    
    const handleViewBookings = (bookingId) => {
    
      navigate('/booking-details/'+bookingId);      
    };

    const cancelBooking = (e, id) => {
      e.preventDefault();
      
      var buttonClicked = window.confirm("Are you sure you want to cancel booking?");
      
      if (buttonClicked === true) {

        var sendNotification = window.confirm("Do you want to send a cancellation notification email?");
          
        
        // Make a PUT request to update booking_completed to 0
      

         axios.put(`${API_URL}/cancel-booked-slot/` + id, {
              booking_completed: 0 ,
              updated_by: userContext.userDetails.id,
              booked_date: customer[0].booked_date,       // Add booked_date if needed
              booked_time: customer[0].booked_time,       // Add booked_time if needed
              no_of_adults: customer[0].no_of_adults,              // Add no_of_adults
              no_of_children: customer[0].no_of_children   
        
          }).then(res => {
            if (sendNotification === true) {
            const data = customer.find(item=>item.id === id)
            const emailData =  prepareEmailData(data);
            // API call to send email
            const emailResponse =  axios.post(`${API_URL}/send-email`, emailData);
            }
             
              Swal.fire({
                  icon: "success",
                  text: res.data.message
              }).then(function() {
                setIsLoading(true);
                  // Optionally update UI after booking is cancelled
                  getLocationByUser(); 
              });
          
          }).catch(error => {
              // Handle any errors here
            //   Swal.fire({
            //   text:error.response.data.message,
            //   icon:"error"
            // })
            setIsLoading(false);
              console.error("Error canceling booking:", error);
          });
      } else {
        setIsLoading(false);
          getLocationByUser();
      }
  }
   function prepareEmailData(bookingDetails) {

    const formattedBookedTime = formatBookedTime(bookingDetails.booked_time);
    // Convert the plain text template to HTML
    const message = convertPlainTextToHTML(emailData.message)
        .replace('[customer_name]', `${bookingDetails.firstname} ${bookingDetails.lastname}`)
        .replace('[event_date]', moment(bookingDetails.booked_date).format('D MMMM YYYY'))
        .replace('[event_time]', formattedBookedTime)  // Joining the times array into a string
        .replace('[location]', bookingDetails.location_name)
        .replace('[number_of_players]', bookingDetails.total_players)
        .replace('[package_name]',
          (bookingDetails.package_name === 'Corporate' 
            ? `${bookingDetails.package_name} - ${bookingDetails.corporate_package_name}`
            : `${bookingDetails.package_name} - ${bookingDetails.game_package_name}`
          ))
          //bookingDetails.game_type_name || `${bookingDetails.package_name} - ${bookingDetails.game_package_name}`)
      
    return {
        booking_id: bookingDetails.booking_id,
        to: bookingDetails.email,
        subject: emailData.subject,
        message // This is now HTML formatted
    };
}

    return(
        <div>
<SideNav/>
      <TopBar/>

      {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}
      <div class="right_panel position contact_section vh-100" id="right_panel">

        <div class="main_title" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            <h1><i class="bi bi-book"></i> Bookings</h1>
           
                {/* <button class="btn btn-sm btn_secondary">
                <Link style={{color:"white"}} to={'/create-customer'}>Create New Customer </Link>
                  </button> */}
          
        </div>

        <div class="content_wrapper pt-0" data-aos="fade-down" data-aos-delay="" data-aos-duration="1200">
            
            <div>
                <div class="vm_content_box_1">
                    <div class="content">

                    <div class="row pt-3">
                          
                          <div class="col-md-9 form-group" >
                                  <div class="d-flex input_wrapper">
                                  <div><label>Show   <select name="example_length" aria-controls="example" value={perPage} onChange={(e) => {setCurrentPage(0);setPerPage(Number(e.target.value))}}>
                                      <option value="10">10</option><option value="25">25</option><option value="50">50</option><option value="100">100</option>
                                    </select>  entries</label>
                                    </div>
                                  </div>
                              </div>
  
                              <div class="col-md-3 form-group">
                                  <div class="d-flex input_wrapper">
                                      <div class="icon_wrapper">
                                          <i class="bi bi-search icon"></i>
                                      </div>
                                      <input class="form-control" name="searchQuery" id="searchQuery" placeholder="Search..." type="text" 
                                      value={searchQuery} onChange={(e) => {setSearchQuery(e.target.value)}}/>
                                  </div>
                              </div>
                          </div>
                          <div class="table-responsive">
                        <table class="display inventory_table_list dataTable dtr-inline" style={{width:"100%"}}>
                            <thead>
                                <tr>
                                    <th>Booking Id</th>
                                    <th>Customer Name</th>
                                    <th>Location</th>
                                    {/* <th>Game</th> */}
                                    <th>Booked By</th>
                                    <th>Booked On</th>
                                    <th class="d-flex justify-content-md-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {filteredResult.length === 0 ? (
        <tr>
          <td colSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
            <p className="odd" style={{ margin: 0 }}>No records found</p>
          </td>
        </tr>
      ) : (
                            filteredResult.slice((currentPage * perPage), ((currentPage * perPage) + perPage)).map((item, index) => (
                                           
                                <>
                                {/* <tr data-aos="fade-right" data-aos-delay="" data-aos-duration="1200"> */}
                                   <tr >
                                   <td style={{ verticalAlign: 'middle'}}>{item.booking_ref_id}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.firstname} {item.lastname}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{item.location_name}</td>
                                    {/* <td style={{ verticalAlign: 'middle'}}>{item.game_name}</td> */}
                                    <td style={{ verticalAlign: 'middle'}}>{item.first_name} {item.last_name}</td>
                                    <td style={{ verticalAlign: 'middle'}}>{moment(item.created_at).format('MMM D, YYYY, h:mm A')}</td>
                                    <td >
                                        <div class="d-flex justify-content-md-end" style={{margin:"7px 0px 0px 0px"}}>
                                        <button class="btn btn-sm btn_secondary"  onClick={() => handleViewBookings(item.id)} style={{ background: "orange", color: "white", margin: "0px 10px 0px 0px"}} >
                                        <i class="bi bi-eye"></i></button>
                                      {/*   <Link style={{color:"white"}} to={`/edit-customer/${item.id}`} class="btn btn-sm btn_secondary " data-bs-target="#createNewCustomre"><i class="bi bi-pencil-square"></i></Link>
                                          */} 
                                            <button class="btn btn-sm btn_secondary" data-bs-target="#createNewCustomre" style={{ background: "red", margin: "0px 0px 0px 10px" }}
                                     onClick={(e) => cancelBooking(e, item.id)}><i class="bi-x-circle"></i></button>
                               </div>
                                    </td>
                                </tr></>    
)))}
                            </tbody>
                            <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                            {/* <tfoot>
                                <tr>
                                <th>Booking Id</th>
                                <th>Customer Name</th>
                                
                                    <th>Location</th>
                                    <th>Game</th>
                                    <th>Booked By</th>
                                    <th>Booked On</th>
                                    <th class="d-flex justify-content-md-end">Action</th>
                                </tr>
                            </tfoot> */}
                        </table>
                        </div>
                        <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />


                                    </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>   
    );
}
export default Bookings;
