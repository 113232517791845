import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import UserContext from './UserContext';

const ProtectedRoute = ({ children }) => {
  const { username } = useContext(UserContext);

  // If the user is not authenticated, redirect to the login page
  if (!username) {
    return <Navigate to="/login" replace />;
  }

  // If authenticated, render the protected content
  return children;

  
};

export default ProtectedRoute;
