

import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import Swal from 'sweetalert2';
import UserContext from './UserContext';
import moment from 'moment';
import {Link, useParams, useNavigate} from "react-router-dom";
import { Modal, Button, Form } from 'react-bootstrap'; 
import QRCode from 'qrcode';

function ViewAllBookings() {

    const API_BASE_URL = process.env.REACT_APP_BASE_URL;
    const LARAVEL_API_URL = process.env.REACT_APP_LARAVEL_API;

    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [events, setEvents] = useState([]);

    const [error_list, setErrorList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const userContext = useContext(UserContext);

  
    const [selectedGame, setSelectedGame] = useState('');

    const [selectedGameLocation, setSelectedGameLocation] = useState('');

 
  
    const [game_available, setGame_available] = useState([]);
    const [bookingDetails, setBookingDetails] = useState({});
    const [view, setView] = useState('form');


    const [filteredLocations, setFilteredLocations] = useState([]);
  
 
  
const today = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format

const filteredEvents = events.filter(event => event.start >= today);

    const showToast = (message) => {
        Swal.fire({
            icon: 'success',
            title: message,
            color: "white",
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3500,
            customClass: {
                popup: 'sweetalert-popup',
                title: 'sweetalert-title',
                icon: 'sweetalert-icon',
            },
            background: '#333',
            showCloseButton: true,
            padding: '4px',
        });
    };



    useEffect(() => {
        if (userContext.username) {
            getLocationByUser();
        }
    }, [userContext.username]); 

   
    
    async function getLocationByUser() {
        setIsLoading(true);
        try {
            const res = await axios.get(`${API_URL}/view-team-member-by-username/${userContext.username}`);
            if (res.data.status === 200) {
                const filtered = res.data.locations;
                setFilteredLocations(filtered);
    
                if (filtered.length > 0) {
                    const defaultLocation = filtered[0].id;
                    setSelectedGameLocation('all')
                    // setSelectedGameLocation(defaultLocation);
                      fetchGameAvailable(defaultLocation);
                }
            } else if (res.data.status === 404) {
                // Handle 404 status if needed
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching locations:", error);
        } 
    
    }



    useEffect(() => {
       
  
        if (selectedGameLocation && selectedGame) {
            setIsLoading(true);
            fetchEvents();
        }
    }, [selectedGameLocation, selectedGame]);


    const handleViewBookings = (bookingId) => {
        navigate('/booking-details/'+bookingId);      
      };


    function fetchGameAvailable(locationId) {
       // setIsLoading(true); // Start loader for game fetching
        axios.get(`${API_URL}/view-available-game-by-locationId/${locationId}`)
            .then(res => {
                if (res.data.games) {
                    setGame_available(res.data.games);
                    if (res.data.games.length > 0) {
                        setSelectedGame(res.data.games[0].id);
                    } else {
                        setSelectedGame('');
                    }
                } else {
                    setGame_available([]);
                }
            })
            .catch(error => {
                console.error("Error fetching games:", error);
            })
           
    }

    function fetchAllLocationGameAvailable(locationIds) {
        // setIsLoading(true); // Start loader for game fetching
        const locationIdsString = locationIds.join(','); // Convert array to comma-separated string
    
        axios.get(`${API_URL}/view-available-game-by-locationIds/${locationIdsString}`)
            .then(res => {
                if (res.data.games) {
                    setGame_available(res.data.games);
                    if (res.data.games.length > 0) {
                        setSelectedGame(res.data.games[0].id);
                    } else {
                        setSelectedGame('');
                    }
                } else {
                    setGame_available([]);
                }
                // setIsLoading(false); // Stop loader for game fetching
            })
            .catch(error => {
                console.error("Error fetching games:", error);
                // setIsLoading(false); // Stop loader for game fetching
            });
    }

    const handleGameChange = (e) => {
        const selectedGameId = e.target.value;
        setSelectedGame(selectedGameId);
    };


    const handleGameAvailableLocationChange = (e) => {
        const selectedGameLocationId = e.target.value;
        setSelectedGameLocation(selectedGameLocationId);
       
        if(selectedGameLocationId == "all")
        {
            const locationIds = filteredLocations.map(location => location.id);
            fetchAllLocationGameAvailable(locationIds);
        }
        else{
            fetchGameAvailable(selectedGameLocationId);
        }
    };


    // const locationColors = {
    //     "Stouffville": "#FF5733", // Example location name and color
    //     "Scarborough": "#006400",
    //     "Ajax": "#3357FF",
    //     // Add more locations and colors as needed
    // };



    function assignColorsToLocations(filteredLocations) {
        const predefinedColors = ["#FF5733", "#006400", "#3357FF", "#4B0082", "#FF1493", "#4B0082"]; // Your predefined colors
        const locationColors = {};
    
        filteredLocations.forEach((location, index) => {
            locationColors[location.location_name.trim()] = predefinedColors[index % predefinedColors.length];
        });
    
        return locationColors;
    }

    
    const locationColors = assignColorsToLocations(filteredLocations);

    function fetchEvents() {

        console.log(selectedGameLocation)
         
        axios.get(`${API_URL}/view-booked-slots/${selectedGameLocation}/${selectedGame}`).then(res => {
  
            if (res.data.status === 200) {

                const formattedEvents = res.data.bookedSlots.map(slot => {
                    const bookingTimes = Array.isArray(slot.booked_time) ? slot.booked_time : JSON.parse(slot.booked_time);
                    // const startTime = new Date(`${slot.booked_date} ${bookingTimes[0].split(' - ')[0]}`);
                    // const endTime = new Date(`${slot.booked_date} ${bookingTimes[bookingTimes.length - 1].split(' - ')[1]}`);

                    const startTime = moment(`${slot.booked_date} ${bookingTimes[0].split(' - ')[0]}`, 'YYYY-MM-DD h:mm A');
                    const endTime = moment(`${slot.booked_date} ${bookingTimes[bookingTimes.length - 1].split(' - ')[1]}`, 'YYYY-MM-DD h:mm A');

                    // Format times
                    const startFormatted = startTime.format('h:mm A');
                    const endFormatted = endTime.format('h:mm A');

                    // const typeOrPackageName = slot.game_type_name ? slot.game_type_name : slot.package_name;
                    
                    const locationShort = slot.location_name.substring(0, 3);
                    const typeOrPackageName = slot.game_type_name ? slot.game_type_name : slot.package_name;
                    // const typeOrPackageShort = typeOrPackageName.substring(0, 3);
                    const typeOrPackageShort = typeOrPackageName ? typeOrPackageName.substring(0, 3) : "";



                    const className = typeOrPackageName === 'Birthday' ? 'birthday-event' : '';
                 const eventColor = locationColors[slot.location_name] || '#000000'; // Default color if location not found


                 
                    const formattedEvent = {
                        // title: `${slot.location_name} - ${typeOrPackageName} - Adults: ${slot.no_of_adults}, Kids: ${slot.no_of_children}`,
                        title: `${startFormatted} - ${endFormatted} - ${locationShort} - ${typeOrPackageShort} A-${slot.no_of_adults} K-${slot.no_of_children}`,
                        start: startTime.toISOString(),
                        end: endTime.toISOString(),
                        description: `${slot.game_name} - ${typeOrPackageName} at ${slot.location_name}`,
                        className: className,
                        backgroundColor: eventColor,  // Set the event background color
                        borderColor: eventColor,      // Optional: set border color to match
                        textColor: '#FFFFFF',   
                        extendedProps: {
                            booked_date: slot.booked_date,
                            location_name: slot.location_name,
                            game_type_name: slot.game_type_name,
                            package_name: slot.package_name,
                            game_name: slot.game_name,
                            booked_time: bookingTimes.join(', '),
                            no_of_adults: slot.no_of_adults,
                            no_of_children: slot.no_of_children,
                            firstname: slot.firstname, // Add customer first name
                            lastname: slot.lastname,  // Add customer last name
                            email: slot.email,        // Add customer email
                            phone: slot.phone,
                            start_time: startFormatted, // Start time
                            end_time: endFormatted,
                            birthday_kid_name: slot.birthday_kid_name,
                            food_details: slot.food_details,
                            drink_details: slot.drink_details,
                            amount_paid: slot.amount_paid,
                           game_package_name: slot.game_package_name,
                           booking_id: slot.booking_id,

                        }
                    };

                    return formattedEvent;
                });
                formattedEvents.forEach(event => {
                });
                setEvents(formattedEvents);
                setIsLoading(false);
            }
            else if (res.data.status === 404) {
                setEvents([]);
                setIsLoading(false);
            }
  
           
        });

    }



    const handleEventClick = ({ event }) => {
        if (event.extendedProps) {
            setBookingDetails(event.extendedProps);
          //  console.log(event.extendedProps);
            setView('details');
        } else {
           // console.log('No details found in event extendedProps');
        }
    };

    function renderEventContent(eventInfo) {
        const { event } = eventInfo;
        
        const backgroundColor = event.backgroundColor || '#03a9f3'; // Default color
    
        return (
            <div
                style={{
                    backgroundColor,
                    color: event.textColor || '#ffffff',
                    padding: '6px',
                    borderRadius: '5px',
                    textAlign: 'left',
                    width: '100%',
                    boxSizing: 'border-box',
                    overflowWrap: 'break-word', 
                    whiteSpace: 'normal', 
                    
                    
                }}
            >
                {event.title}
            </div>
        );
    }

  
    
    return (
        <div>
            <SideNav />
            <TopBar />
            {isLoading ?
                <div className="loader-container" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed", top: "0", left: "0", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", zIndex: "9999" }}>
                    <div class="d-flex justify-content-center">
                        <div class="spinner-border" role="status">
                        </div>
                    </div>
                </div> : null}

            <div className="right_panel position contact_section vh-100" id="right_panel">
                <div className="main_title d-flex align-items-center">
                    <h1><i className="bi bi-calendar-check"></i> Calender</h1>
                </div>

                <div className="content_wrapper pt-0">
                    <div className="vm_content_box_1">
                        <div className="title">
                            <h2>Booking details</h2>
                        </div>
                        <div className='row px-4'>


                            <div class="content col-md-2">
                                <div class="form-group pt-3 pb-2">

                                    <select class="form-select" id="selectedGameLocation" name="selectedGameLocation" value={selectedGameLocation} onChange={handleGameAvailableLocationChange}>
                                        <option value="">Select game location</option>
                                        <option value="all">All</option>
                                        {filteredLocations.map((location) => (
                                            <option key={location.id} value={location.id}>
                                                {location.location_name}
                                            </option>
                                        ))}
                                    </select>   {!selectedGameLocation && (
                                        <span class="text-danger">{error_list.location_id}</span>)}
                                </div>
                            </div>


                            <div class="content col-md-2 ">
                                <div class="form-group pt-3 pb-2">

                                    <select className="form-select me-2" id="game" name="game" value={selectedGame} onChange={handleGameChange}>
                                        <option value="">Select a game</option>
                                        {game_available.map((game) => (
                                            <option key={game.id} value={game.id}>
                                                {game.name}
                                            </option>
                                        ))}
                                    </select> {!selectedGame && (
                                        <span class="text-danger">{error_list.game_id}</span>)}
                                </div>
                            </div>





                        </div>
                        <div className='row'>


                            <div className='col'>
                                <div className="content pt-3 pb-5 mb-5" id="calender" style={{ boxShadow: "0px 0px 21px 2px rgba(0, 0, 0, 0.18)", backgroundColor: "white", marginTop: "20px", width: '100%' }}  >


                                    <FullCalendar
                                        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
                                        initialView="dayGridMonth"
                                        events={filteredEvents}
                                        headerToolbar={{
                                            left: 'prev,next today',
                                            center: 'title',
                                            right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
                                        }}
                                        themeSystem="bootstrap-premium"
                                        height="auto"
                                        contentHeight="auto"
                                        //eventColor="#03a9f3" // Set event color
                                        eventTextColor="#ffffff" // Set event text color
                                        // dateClick={handleDateClick}
                                        eventContent={renderEventContent} 
                                        dayCellDidMount={(info) => {
                                            const today = new Date().toISOString().split('T')[0]; // Current date
                                            if (info.date < today) {
                                                info.el.classList.add('fc-day-past');
                                            }
                                        }}
                                        eventClick={handleEventClick}
                                    />

                                </div>
                            </div>

                           



{view === 'details' && (
    <div className='col-md-4'>
        <div className="py-3 mt-4 border-top bg-light rounded shadow-sm">
            <h3 className="vm_font_semi_bold border-bottom mb-3 px-3 pb-2 ">
                Booking Details
            </h3>
<div className="content py-0 bg-white px-3 py-3 shadow-lg mb-3 w-100" >
            <div className="text-center">
                <h2 className="vm_font_bold mt-2">{bookingDetails.firstname} {bookingDetails.lastname}</h2>
                <div className="d-flex justify-content-center mb-3">
                    <a style={{ cursor: "pointer" }} className="d-flex align-items-center pe-3 text-decoration-none text-dark">
                        <i className="bi bi-telephone-fill me-2 text-success"></i>
                        <p className="mb-0">{bookingDetails.phone}</p>
                    </a>

                    <a style={{ cursor: "pointer" }} className="d-flex align-items-center text-decoration-none text-dark">
                        <i className="bi bi-envelope-fill me-2 text-success"></i>
                        <p className="mb-0">{bookingDetails.email}</p>
                    </a>
                </div>
            </div>

            <div className="row border-top pb-3">
                {[
                    { label: 'Game Name', value: bookingDetails.game_name },
                    { label: 'Location', value: bookingDetails.location_name },
                    { label: 'Game Package', value: bookingDetails.game_type_name ? bookingDetails.game_type_name : `${bookingDetails.package_name} - ${bookingDetails.game_package_name}` },
                    { label: 'Booked Date', value: (
                        <div className="d-flex align-items-center">
                            <i className="bi bi-calendar3 me-2 text-success" ></i>
                            <span>{moment(bookingDetails.booked_date).format('D MMMM YYYY')}</span>
                        </div>
                    ) },
                    { label: 'Booked Time', value: (
                        <div className="d-flex align-items-center">
                            <i className="bi bi-clock-fill me-2 text-success" ></i>
                            <span>{bookingDetails.start_time} - {bookingDetails.end_time}</span>
                        </div>
                    ) },
                    { label: 'Adult Count', value: bookingDetails.no_of_adults },
                    { label: 'Kids Count', value: bookingDetails.no_of_children },
                    { label: 'Amount paid', value: bookingDetails.amount_paid },
                    
                    ...(bookingDetails.package_name 
                        ? [
                          
                            { label: 'Birthday Kid Name', value: bookingDetails.birthday_kid_name },
                            ...(bookingDetails.food_details 
                                ? [{ label: 'Food details', value: (bookingDetails.food_details || '').split(',').map(item => item.trim()).join(', ') }] 
                                : []),
                                ...(bookingDetails.drink_details 
                                    ? [{ label: 'Drink details', value: (bookingDetails.drink_details || '').split(',').map(item => item.trim()).join(', ') }] 
                                    : []),
                           

                        ]
                        : [])
                  

                ].map((detail, index) => (
                    <div className="d-flex border-bottom py-2" key={index}>
                        <div className="col-5 p-1 border-end vm_font_bold text-secondary">{detail.label}</div>
                        <div className="col-7 p-1 ps-3">{detail.value}</div>
                    </div>
                ))}
          
   <div class="d-flex justify-content-center pt-3 ">
<button class="btn btn-sm btn_secondary"  onClick={() => handleViewBookings(bookingDetails.booking_id)}  >
View More Details</button>
</div>

            </div>
        </div>
    </div>
    </div>
)}

 </div>


                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewAllBookings;
