import SideNav from "./SideNav";
import TopBar from "./TopBar";
import { useState, useEffect } from "react";
import video1 from '../img/V1-Login and Dashboard.mp4';
import video2 from '../img/V2-Manual booking,Calendar,&Bookings.mp4';
import video3 from '../img/V3-Gametype & Location.mp4';
import video4 from '../img/V4-Customer & Team.mp4';
import video5 from '../img/V5-Settings & Websetting.mp4';
import video6 from '../img/V6-Report & Online transaction.mp4';

function Help() {
    const [isLoading, setIsLoading] = useState(false);
    const [playingVideo, setPlayingVideo] = useState(null);

    // Video data (make sure the paths are correct)
    const videos = [
        { title: "Video 1: Login and Dashboard", url: video1 },
        { title: "Video 2: Manual booking,Calendar,&Bookings", url:video2  },
        { title: "Video 3: Gametype & Location", url: video3 },
        { title: "Video 4: Customer & Team", url: video4 },
        { title: "Video 5: Settings & Websetting", url: video5 },
        { title: "Video 6: Report & Online transaction", url: video6 }
    ];


    

    const closeModal = () => {
        setPlayingVideo(null); // Close the modal and stop the video
    };

    return (
        <div>
            <SideNav />
            <TopBar />

            {isLoading && (
                <div className="loader-container" style={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "9999"
                }}>
                    <div className="spinner-border" role="status"></div>
                </div>
            )}

            <div className="right_panel position contact_section vh-100" id="right_panel">
                <div className="main_title d-flex align-items-center">
                    <h1><i className="bi bi-question-circle"></i> Help</h1>
                </div>

                <div className="content_wrapper pt-0">
                    <div className="vm_content_box_1">
                        <div className="title">
                            <h2>Videos</h2>
                        </div>
                        <div className="content pt-3">
                            <div className="row">
                                {videos.map((video, index) => (
                                    <div key={index} className="col-md-4 mb-4">
                                        <div className="video-card" >
                                            <h3>{video.title}</h3>
                                            <video 
                                                width="100%" 
                                                className="video-thumbnail" 
                                                controls
                                              
                                            >
                                                <source src={video.url} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Video Modal */}
                {playingVideo && (
                    <div id="videoModal" className="modal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', zIndex: '999', left: '0', top: '0', width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                        <div className="modal-content" style={{ position: 'relative', padding: '20px', backgroundColor: 'white' }}>
                            <span className="close" onClick={closeModal} style={{ position: 'absolute', top: '10px', right: '20px', fontSize: '24px', cursor: 'pointer' }}>&times;</span>
                            <video controls width="100%" >
                                <source src={playingVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Help;
