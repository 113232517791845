

import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import SideNav from "./SideNav";
import TopBar from "./TopBar";
import ReactPaginate from 'react-paginate';
import {Link, useParams, useNavigate} from "react-router-dom";
import UserContext from './UserContext';
import { Modal, Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { TabContainer, TabContent, TabPane, Nav, NavItem, NavLink } from 'react-bootstrap';

function SettingCreateGames() {

    const API_URL = process.env.REACT_APP_API_URL;
    const [events, setEvents] = useState([]);
    const [common_title, setCommon_title] = useState('');
    const [common_description, setCommon_description] = useState('');
    const [startDatetime, setStartDatetime] = useState('');
    const [endDatetime, setEndDatetime] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [error_list, setErrorList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [availableTimeSlots, setAvailableTimeSlot] = useState([]);
    const userContext = useContext(UserContext);
    const [team, setTeam] = useState([]);

   
    // const scrollToRef = useRef(null);
    const [game_type, setGame_type] = useState([]);
    const [game_package, setGame_package] = useState([]);
    const [corporate_event, setCorporate_event] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const [games, setGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState('');
    const [min_players, setMin_players] = useState('');
    const [max_players, setMax_players] = useState('');
    const [game_available, setGame_available] = useState([]);
    const [selectedGameLocation, setSelectedGameLocation] = useState('');
    const [locations, setLocations] = useState([]);
    const [show, setShow] = useState(false);
    const [gameToEdit, setGameToEdit] = useState(null);
    const [showHistory, setShowHistory] = useState({});
    const [activeTab, setActiveTab] = useState('gameTypes'); 

    const [gameTypes, setGameTypes] = useState([]);
    const [gamePackages, setGamePackages] = useState([]);
    const [corporateEvents, setCorporateEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [packageShowModal, setPackageShowModal] = useState(false);
    const [corporateShowModal, setCorporateShowModal] = useState(false);
    const [showTimeSlotModal, setShowTimeSlotModal] = useState(false);
    const [showTimeSlotDateModal, setShowTimeSlotDateModal] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [packageEditingIndex, setPackageEditingIndex] = useState(null);
    const [timeSlotDetails, setTimeSlotDetails] = useState([]);
    const [game_exception, setGame_exception] = useState([]);
    const [editingRow, setEditingRow] = useState(null);
    const [editingRowSpecificDate, setEditingRowSpecificDate] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedException, setSelectedException] = useState(null);
    const [filteredLocations, setFilteredLocations] = useState([]);
    const [gameDetails, setGameDetails] = useState({
        start_time: '',
        end_time: '',
        time_slot_interval: '',
    });

    const [formData, setFormData] = useState({
        game_type_name: '',
        common_title: '',
        common_description: '',
        min_players: '',
        max_players: '',
        max_players_in_a_slot: '',
        adults_first_price: '',
        adults_second_price: '',
        adults_third_price: '',
        deposit_method:'',
        deposit_amount_per_person:'',
        deposit_percentage:'',
    });

    const [packageFormData, setPackageFormData] = useState({
      sub_package_name: '',
      description: '',
      min_games: '',
      min_players: '',
      max_players_in_a_slot: '',
      basic_price: '',
      food_details: '',
      drink_details: '',
      birthday_kid: '',
      price_per_game: '',
      deposit_required: '',
      deposit_amount:'',
  });

  const [corporateFormData, setCorporateFormData] = useState({
    sub_package_name: '',
    description: '',
    min_games: '',
    min_players: '',
    max_players_in_a_slot: '',
    basic_price: '',
    food_details: '',
    drink_details: '',
    price_per_game: '',
    deposit_required: '',
    deposit_amount:'',
});
    const [updateOption, setUpdateOption] = useState('update_all');
    const [selectedDate, setSelectedDate] = useState(null);
    const [gameTypeRecordsExist, setGameTypeRecordsExist] = useState(false);
    const [gamePackageRecordsExist, setGamePackageRecordsExist] = useState(false);
    const [corporateEventRecordsExist, setCorporateEventRecordsExist] = useState(false);
    const [timeSlotChangeHistoryExist, setTimeSlotChangeHistoryExist] = useState(false);
    const [selectedGameTypeForView, setSelectedGameTypeForView] = useState(null);
    const [isTimeSlotSaved, setIsTimeSlotSaved] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');

    const [formDataTimeSlot, setFormDataTimeSlot] = useState({
       start_time: '',
       end_time: '',
        adults_first_price: '',
        adults_second_price: '',
        adults_third_price: '',
    });

  
    const indexToDayName = {
        0: "Sunday",
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
    };

      const dayNameToIndex = {
       
        "Monday": 0,
        "Tuesday": 1,
        "Wednesday": 2,
        "Thursday": 3,
        "Friday": 4,
        "Saturday": 5,
        "Sunday": 6,
    };

    // const locationIds = userContext.userDetails.location_id
    // ? userContext.userDetails.location_id.split(',').map(id => parseInt(id.trim(), 10))
    // : [];
    
    // // Filter locations to include only those with IDs in locationIds
    // const filteredLocations = locations.filter(location => locationIds.includes(location.id));
    

//     useEffect(() => {
//         console.log(filteredLocations)
// if(filteredLocations.length > 0)
// {
//     setSelectedGameLocation(filteredLocations[0].id);
//      fetchGameAvailable(filteredLocations[0].id)
// }

//     }, [filteredLocations]);

    
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    

    const handleViewTimeSlots = (gameTypeId) => {
        setIsLoading(true);

        setSelectedGameTypeForView(gameTypeId);
        fetchData(gameTypeId);
      };
    
    const toggleHistory = (gameTypeIndex, dayIndex) => {

        setShowHistory(prevState => ({
            ...prevState,
            [`${gameTypeIndex}-${dayIndex}`]: !prevState[`${gameTypeIndex}-${dayIndex}`]
        }));
    };


   


    function getGamePackageList()
    {

     // setIsLoading(true);
      axios.get(`${API_URL}/view-game-package-by-id/${selectedGameLocation}/${selectedGame}`).then(res=>{
          if(res.data.status === 200){
              setGame_package(res.data.gamePackages);  
              setGamePackages(res.data.gamePackages);

              //  scrollToRef.current.scrollIntoView({ behavior: "smooth" });            
          }     
          else if(res.data.status === 404){
              setGame_package([]); 
              setGamePackages([]); 
              }
            //  setIsLoading(false);

            });  
  }

  
  function getCorporateEventList()
  {

   // setIsLoading(true);
    axios.get(`${API_URL}/view-corporate-event-by-id/${selectedGameLocation}/${selectedGame}`).then(res=>{
        if(res.data.status === 200){
            setCorporate_event(res.data.corporateEvents);  
            setCorporateEvents(res.data.corporateEvents);

            //  scrollToRef.current.scrollIntoView({ behavior: "smooth" });            
        }     
        else if(res.data.status === 404){
            setCorporate_event([]); 
            setCorporateEvents([]); 
            }
          //  setIsLoading(false);

          });  
}
 

    // Get the index for "Monday"
    const getDayIndex = (dayName) => dayNameToIndex[dayName];

      const groupTimeSlotChanges = (game_exception) => {
        const groupedData = [];
        const existingEntries = {};
    
        game_exception.forEach((item) => {
            const key = `${item.day}-${item.start_time}-${item.end_time}-${item.adults_first_price}-${item.adults_second_price}-${item.adults_third_price}`;
            if (existingEntries[key]) {
                existingEntries[key].game_type_ids.push(item.game_type_id);
                // existingEntries[key].specific_date = "Updated All";
            } else {
                existingEntries[key] = {
                    ...item,
                    game_type_ids: [item.game_type_id],
                };
                groupedData.push(existingEntries[key]);
            }
        });
    
        return groupedData;
    };

    const filteredResult = gameTypes.filter((item) => {
        return item.game_type_name.toLowerCase().includes(searchQuery.toLowerCase()) 

      });

      
    const filteredPackageResult = gamePackages.filter((item) => {
      return item.sub_package_name.toLowerCase().includes(searchQuery.toLowerCase()) 

    });

    const filteredCorporateResult = corporateEvents.filter((item) => {
        return item.sub_package_name.toLowerCase().includes(searchQuery.toLowerCase()) 
  
      });

            // const filteredTimeSlotChange = groupTimeSlotChanges(game_exception);
            const filteredTimeSlotChangeResults = game_exception
            .filter(item => {
              const day = item.day ? item.day.toLowerCase() : ''; // Check if item.day is defined
              const start_time = item.start_time ? item.start_time.toLowerCase() : ''; // Check if item.start_time is defined
              const end_time = item.end_time ? item.end_time.toLowerCase() : ''; // Check if item.end_time is defined
          
              return (
                day.includes(searchQuery.toLowerCase()) ||
                start_time.includes(searchQuery.toLowerCase()) ||
                end_time.includes(searchQuery.toLowerCase())
              );
            })
            .filter(item => item.game_type_id === selectedGameTypeForView)
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

   

      const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
      };

      

      

      const handleUpdateOptionChange = (event) => {
        setUpdateOption(event.target.value);
        setSelectedDate(null); // Reset selected date when update option changes
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    
    };

    function fetchGameAvailable(locationId) {

       // setIsLoading(true); // Start loader for game fetching
        axios.get(`${API_URL}/view-available-game-by-locationId/${locationId}`)
            .then(res => {
                if (res.data.games) {
                
                    setGame_available(res.data.games);
                    if (res.data.games.length > 0) {
                        setSelectedGame(res.data.games[0].id);
                    } else {
                        setSelectedGame('');
                    }
                } else {
                   
                    setGame_available([])
                    setIsLoading(false);
                }
            })
            .catch(error => {
                setIsLoading(false);
                console.error("Error fetching games:", error);
            })
           
    }

    async function getTeamList(){
 
        axios.get(`${API_URL}/view-team-members`).then(res=>{
            if(res.data.status === 200){
              const team = res.data.team;
                setTeam(res.data.team);
            }
            else if(res.data.status === 404){
                //setMessage(res.data.message);
                //console.log(res.data.status);
                }
                
          
         });
           
        }

const getGameExceptionList =  () => {
   // axios.get(`${API_URL}/view-game-exception-by-id/${selectedGameLocation}/${selectedGame}/${gameTypeIndex}`)
    axios.get(`${API_URL}/view-game-exception-by-id/${selectedGameLocation}/${selectedGame}`) 
    .then(response => {


            if (response.data.status === 200) {
                setGame_exception(response.data.gameException);

               
            } else if (response.data.status === 404) {
                setGame_exception([]);

            }
      
        })
        .catch(error => {
            setIsLoading(false);
            console.error('Error fetching game exceptions:', error);
        })
        .finally(() => {
            setIsLoading(false);
        });
};


    // useEffect(() => {
    //      setIsLoading(true);
    //    //  getTeamList();
    //      fetchLocations();

    // }, []); // Empty dependency array ensures the effect runs only once, similar to componentDidMount
   

    useEffect(() => {
        if (userContext.username) {
            getTeamList();
            getLocationByUser();
        }
    }, [userContext.username]); 
    
    async function getLocationByUser() {
        setIsLoading(true);
        // console.log(userContext.username);
        try {
            const res = await axios.get(`${API_URL}/view-team-member-by-username/${userContext.username}`);
            if (res.data.status === 200) {
                const filtered = res.data.locations;
                setFilteredLocations(filtered);
                // console.log(filtered)
    
                if (filtered.length > 0) {
                    const defaultLocation = filtered[0].id;
                    setSelectedGameLocation(defaultLocation);
                      fetchGameAvailable(defaultLocation);
                }
            } else if (res.data.status === 404) {
                // Handle 404 status if needed
            }
        } catch (error) {
            console.error("Error fetching locations:", error);
            setIsLoading(false);
        } 
    
    }

    useEffect(() => {
      
        if (selectedGameLocation && selectedGame) {
            setIsLoading(true);
            setErrorList([]);
            fetchGameDetails();
            getGameTypeList();
            getGamePackageList();
            getCorporateEventList();
            setTimeSlotDetails([]);
            getGameExceptionList();

        }
      
   }, [selectedGameLocation,selectedGame]);
  


   useEffect(() => {
    setGameTypeRecordsExist(gameTypes.length > 0);
}, [gameTypes]);

useEffect(() => {
  setGamePackageRecordsExist(gamePackages.length > 0);
  
}, [gamePackages]);

useEffect(() => {
    setCorporateEventRecordsExist(corporateEvents.length > 0);
    
  }, [corporateEvents]);

useEffect(() => {
    setTimeSlotChangeHistoryExist(game_exception.length > 0);
}, [game_exception]);


const fetchData = (gameTypeId) => {
    setIsLoading(true); // Start loading indicator

    axios.get(`${API_URL}/consolidated-data`, {
        params: {
            location_id: selectedGameLocation,
            game_id: selectedGame,
            game_type_id: gameTypeId,
            created_by: userContext.userDetails.id,
        }
    })
    .then(response => {
        setTimeSlotDetails(prevState => ({
            ...prevState,
            [gameTypeId]: response.data // Assuming the response is an array with one object for the selected game type
        }));
        //console.log('fetchData', response.data);
    })
    .catch(error => {
        console.error('Error fetching data:', error);
    })
    .finally(() => {
        setIsLoading(false); // Ensure loading indicator is stopped regardless of success or failure
    });
};

    
   
    function getGameTypeList()
      {
       // setIsLoading(true);
        axios.get(`${API_URL}/view-game-type-by-id/${selectedGameLocation}/${selectedGame}`).then(res=>{
            if(res.data.status === 200){
                setGame_type(res.data.gameType);  
                setGameTypes(res.data.gameType); 
                //  scrollToRef.current.scrollIntoView({ behavior: "smooth" });            
            }     
            else if(res.data.status === 404){
                setGame_type([]);  
                setGameTypes([]); 
                }
              //  setIsLoading(false);
         });  

// fetchData();
       
      }
   
      const handleGameAvailableLocationChange = (e) => {
        setValidationMessage('');
        const selectedGameLocationId = e.target.value;
        setSelectedGameLocation(selectedGameLocationId);
        fetchGameAvailable(selectedGameLocationId);
  
      };


 
      const fetchGames = async () => {
        try {
          const response = await axios.get(`${API_URL}/view-game`);
          setGames(response.data.game);
        } catch (error) {
          console.error("Error fetching games:", error);
        }
      };

      const fetchLocations = async () => {
        try {
         
          const response = await axios.get(`${API_URL}/view-game-location`);
          setLocations(response.data.gameLocation || []); 
        } catch (error) {
            setIsLoading(false);
          console.error("Error fetching locations:", error);
        }
     //   setIsLoading(false);
      };
      
      const handleGameChange = (e) => {
        const selectedGameId = e.target.value;
        setSelectedGame(selectedGameId);
      
      };

      

  const generateTimeSlots = (bookedTimes) => {
    // Generate time slots from 9 AM to 4 PM
    const startTime = 9;
    const endTime = 16;
    const timeSlots = [];

    for (let hour = startTime; hour <= endTime; hour++) {
      const formattedHour = hour % 12 || 12;
      const ampm = hour < 12 ? 'AM' : 'PM';
      const timeSlot = `${formattedHour}:00 ${ampm}`;
      timeSlots.push(timeSlot);
    }


   // console.log(timeSlots.filter(timeSlot => !timeSlot.includes(bookedTimes)))

    const availableTimeSlots = timeSlots.filter(timeSlot => !bookedTimes.includes(timeSlot));
    setAvailableTimeSlot(availableTimeSlots);
    return availableTimeSlots;
  };



  async function saveGameDetails(e){
    e.preventDefault();
    setLoading(true);

    const formData = {
        ...gameDetails,
        location_id: selectedGameLocation,
        game_id: selectedGame,
        created_by: userContext.userDetails.id,
    };
  
    // const formData = new FormData();
 
    // formData.append("location_id", selectedGameLocation);
    // formData.append("game_id", selectedGame);
    // formData.append("start_time", start_time);
    // formData.append("end_time", end_time);
    // formData.append("time_slot_interval", time_slot_interval);
    // formData.append('created_by',  userContext.userDetails.id);
   
   
      await axios.post(`${API_URL}/save-game-details`, formData).then(res =>{

        handleClose(); 
        Swal.fire({
            icon:"success",
            text:res.data.message
          }).then(function(){ 
            setLoading(false);        
            // resetData();
            setErrorList([]); 
            setValidationMessage('');
            setIsTimeSlotSaved(true);
            getGameTypeList();
          //  fetchData();
     
        });
        }).catch(function(error) {
            setLoading(false);
            setErrorList(error.response.data.validate_err);
            // Swal.fire({
            //   text:error.response.data.message,
            //   icon:"error"
            // })
        })
   }

   


   const saveGameType = async (e) => {
    e.preventDefault();
    setLoading(true);

    const postData = {
        ...formData,
        location_id: selectedGameLocation,
        game_id: selectedGame,
        created_by: userContext.userDetails.id,
    };
   

    try {
        if (editingIndex !== null) {
            // Update existing game type
            postData.id = gameTypes[editingIndex].id; // Assuming you have an ID to identify the game type
            await axios.post(`${API_URL}/update-game-type`, postData).then(res => {
                const updatedGameTypes = [...gameTypes];
                updatedGameTypes[editingIndex] = res.data.gameType; // Assuming the response includes the updated game type
                setGameTypes(updatedGameTypes);
                handleCloseModal();
                Swal.fire({
                    icon: "success",
                    text: res.data.message
                }).then(() => {
                    setLoading(false);
                    setErrorList([]);
                    getGameTypeList();
                });
            });
        } else {
            // Create new game type

            await axios.post(`${API_URL}/save-game-type`, postData).then(res => {
       
                setGameTypes([...gameTypes, res.data.gameType]); 
                // Assuming the response includes the new game type
                handleCloseModal();
                Swal.fire({
                    icon: "success",
                    text: res.data.message
                }).then(() => {
                    setLoading(false);
                    setErrorList([]);
                    getGameTypeList();
                });
            });
        }
    } catch (error) {
        setLoading(false);
        setErrorList(error.response.data.validate_err);
        Swal.fire({
            text: error.response.data.message,
            icon: "error"
        });
    }
};

//    const resetGameTypeData = () => {
//     setGame_type_name("");
//     setCommon_title("");
//     setCommon_description("");
//     setMin_players("");
//     setMax_players("");
//     setMax_players_in_a_slot("");
//     setAdults_first_price("");
//     setAdults_second_price("");
//     setAdults_third_price("");
//     };
   
    const deleteGametype = (e,id) =>{
        e.preventDefault();
        var buttonClicked = window.confirm("Are you sure you want to delete Game type record ?");
        if(buttonClicked == true){
            setIsLoading(true);
           // const thisClicked = e.currentTarget;
           // thisClicked.innerText = "Deleting";
            axios.delete(`${API_URL}/delete-game-type/`+id).then(res => {
                setIsLoading(false);
          Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){
                   // thisClicked.closest("tr").remove();  
                    
              });

               getGameTypeList();
            })
        }
        else
        {
            getGameTypeList();
        }
       
       }

       const handleClose = () => setShow(false);
       const handleShow = () => {
        setIsLoading(true);
         fetchGameDetails();
      
        // setEditIndex(index);
        setShow(true);
        setIsLoading(false);
    };


    const fetchGameDetails = () => {


        axios.get(`${API_URL}/view-game-details-by-id/${selectedGameLocation}/${selectedGame}`) 
        .then(response => {
    
                if (response.data.status === 200) {
                   // setGameDetails(response.data.gameDetails);
         
                    setGameDetails({
                        start_time: response.data.gameDetails[0].start_time || '',
                        end_time: response.data.gameDetails[0].end_time || '',
                        time_slot_interval: response.data.gameDetails[0].time_slot_interval || '',
                    });
                    setIsTimeSlotSaved(true);
                   
                } else if (response.data.status === 404) {
                    setGameDetails([]);
                    setIsTimeSlotSaved(false);
                }
          
            }).catch(error => console.error('Error fetching game details:', error));
          
    };


     
       const handleSubmit = (event) => {
         event.preventDefault();
         // Handle form submission for editing the game
     
         handleClose();
       };

       
       const handleShowModal = (index = null) => {
        setEditingIndex(index);
        if (index !== null) {
            setFormData(gameTypes[index]);
        } else {
            setFormData({
                game_type_name: '',
                common_title: '',
                common_description: '',
                min_players: '',
                max_players: '',
                max_players_in_a_slot: '',
                adults_first_price: '',
                adults_second_price: '',
                adults_third_price: '',
                deposit_method: '',
                deposit_amount_per_person:'',
                deposit_percentage:'',
            });
        }
        setShowModal(true);
    };

       

    const handleCloseModal = () => setShowModal(false);


    const handlePackageShowModal = (index = null) => {
      setEditingIndex(index);
      if (index !== null) {
          setPackageFormData(gamePackages[index]);
      } else {
        setPackageFormData({
          sub_package_name: '',
              description: '',
              min_games: '',
              basic_price: '',
              food_description: '',
              food_quantity: '',
              food_details: '',
              drink_description: '',
              drink_quantity: '',
              drink_details: '',
              birthday_kid: '',
              price_per_game: '',
              deposit_required: '',
              deposit_amount: '',
          });
      }
      setPackageShowModal(true);
  };

  const handleCorporateShowModal = (index = null) => {
    setEditingIndex(index);
    if (index !== null) {
        setCorporateFormData(corporateEvents[index]);
    } else {
        setCorporateFormData({
            sub_package_name: '',
            description: '',
            min_games: '',
            basic_price: '',
            food_description: '',
            food_quantity: '',
            food_details: '',
            drink_description: '',
            drink_quantity: '',
            drink_details: '',
            price_per_game: '',
            deposit_required: '',
            deposit_amount: '',
        });
    }
    setCorporateShowModal(true);
};

  const handlePackageCloseModal = () => setPackageShowModal(false);

  const handleCorporateCloseModal = () => setCorporateShowModal(false);

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });

        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'deposit_method' ? Number(value) : value,
        });

   
    };

    const handlePackageInputChange = (e) => {
      
      setPackageFormData({
          ...packageFormData,
          [e.target.name]: e.target.value,
      });

      const { name, value } = e.target;
    setPackageFormData({
        ...packageFormData,
        [name]: name === 'deposit_required' ? Number(value) : value,
    });
  };

  const handleCorporateInputChange = (e) => {
      
    setCorporateFormData({
        ...corporateFormData,
        [e.target.name]: e.target.value,
    });

    const { name, value } = e.target;
    setCorporateFormData({
      ...corporateFormData,
      [name]: name === 'deposit_required' ? Number(value) : value,
  });
};

    const handleFormSubmit = async () => {
   
     setLoading(true);
        const postData = {
            ...formData,
            location_id: selectedGameLocation,
            game_id: selectedGame,
            created_by: userContext.userDetails.id,
        };
  

        try {
                        if (editingIndex !== null) {
                            postData.id = gameTypes[editingIndex].id;
                    
                            await axios.post(`${API_URL}/update-game-type/${postData.id}`, postData).then(res => {
                                const updatedGameTypes = [...gameTypes];
                                updatedGameTypes[editingIndex] = res.data.gameType;
                                setGameTypes(updatedGameTypes);
                                handleCloseModal();
                                Swal.fire({
                                    icon: "success",
                                    text: res.data.message
                                }).then(() => {
                                    setLoading(false);
                                    setErrorList([]);
                                    getGameTypeList();
                                });
                            });
        } else {
            console.log(postData);
            await axios.post(`${API_URL}/save-game-type`, postData).then(res => {
                setGameTypes([...gameTypes, res.data.gameType]);
                handleCloseModal();
                Swal.fire({
                    icon: "success",
                    text: res.data.message
                }).then(() => {
                    setLoading(false);
                    setErrorList([]);
                    getGameTypeList();
                });
            });
            
        }
    } catch (error) {
        setLoading(false);
        setErrorList(error.response.data.validate_err || {});
        // Swal.fire({
        //     icon: "error",
        //     text: error.response.data.message
        // })
    }
        
       // handleCloseModal();
    };

   

    const handlePackageFormSubmit = async () => {
      setLoading(true);
             const postData = {
                 ...packageFormData,
                 location_id: selectedGameLocation,
                 game_id: selectedGame,
                 main_package_id : 1,
                 created_by: userContext.userDetails.id,
             };
             try {
                             if (editingIndex !== null) {
                                 postData.id = gamePackages[editingIndex].id;
                                 await axios.put(`${API_URL}/update-game-package/${postData.id}`, postData).then(res => {
                                     const updatedGamePackages = [...gamePackages];
                                     updatedGamePackages[editingIndex] = res.data.gamePackages;
                                     setGamePackages(updatedGamePackages);
                                     handlePackageCloseModal();
                                     Swal.fire({
                                         icon: "success",
                                         text: res.data.message
                                     }).then(() => {
                                         setLoading(false);
                                         setErrorList([]);
                                         getGamePackageList();
                                     });
                                 });
             } else {
   
                 await axios.post(`${API_URL}/save-game-package`, postData).then(res => { 
         
                     setGamePackages([...gamePackages, res.data.gamePackages]);
                     handlePackageCloseModal();
                     Swal.fire({
                         icon: "success",
                         text: res.data.message
                     }).then(() => {
                         setLoading(false);
                         setErrorList([]);
                         getGamePackageList();
                     });
                 });
                 
             }
         } catch (error) {
             setLoading(false);
             setErrorList(error.response.data.validate_err || {});
            //  Swal.fire({
            //      icon: "error",
            //      text: error.response.data.message
            //  })
         }
             
            // handlePackageCloseModal();
         };

         const handleCorporateFormSubmit = async () => {
            setLoading(true);
                   const postData = {
                       ...corporateFormData,
                       location_id: selectedGameLocation,
                       game_id: selectedGame,
                       main_package_id : 2,
                       created_by: userContext.userDetails.id,
                   };
                   try {
                                   if (editingIndex !== null) {
                                       postData.id = corporateEvents[editingIndex].id;
                                       await axios.put(`${API_URL}/update-corporate-event/${postData.id}`, postData).then(res => {
                                           const updatedCorporateEvents = [...corporateEvents];
                                           updatedCorporateEvents[editingIndex] = res.data.corporateEvents;
                                           setCorporateEvents(updatedCorporateEvents);
                                           handleCorporateCloseModal();
                                           Swal.fire({
                                               icon: "success",
                                               text: res.data.message
                                           }).then(() => {
                                               setLoading(false);
                                               setErrorList([]);
                                               getCorporateEventList();
                                           });
                                       });
                   } else {
         
                       await axios.post(`${API_URL}/save-corporate-event`, postData).then(res => { 
               
                           setCorporateEvents([...corporateEvents, res.data.corporateEvents]);
                           handleCorporateCloseModal();
                           Swal.fire({
                               icon: "success",
                               text: res.data.message
                           }).then(() => {
                               setLoading(false);
                               setErrorList([]);
                               getCorporateEventList();
                           });
                       });
                       
                   }
               } catch (error) {
                   setLoading(false);
                   setErrorList(error.response.data.validate_err || {});
                  //  Swal.fire({
                  //      icon: "error",
                  //      text: error.response.data.message
                  //  })
               }
                   
                  // handlePackageCloseModal();
               };

    // const handleDelete = (index) => {
    //     const updatedGameTypes = gameTypes.filter((_, i) => i !== index);
    //     setGameTypes(updatedGameTypes);
    // };

    const handleDelete = (index, id) => {
        const buttonClicked = window.confirm("Are you sure you want to delete this game type record?");
    
        if (buttonClicked) {
            setIsLoading(true);
            
            // Make the delete request to the backend
            axios.delete(`${API_URL}/delete-game-type/${id}`)
                .then(res => {
                    setIsLoading(false);
                    Swal.fire({
                        icon: "success",
                        text: res.data.message
                    }).then(() => {
                        // Update frontend state after successful deletion
                        const updatedGameTypes = gameTypes.filter((_, i) => i !== index);
                        setGameTypes(updatedGameTypes);
                    });
    
                    // Refresh the game type list after deletion
                    getGameTypeList();
                })
                .catch(error => {
                    setIsLoading(false);
                    Swal.fire({
                        icon: "error",
                        text: error.response.data.message
                    });
                });
        } else {
            // No action needed if user cancels deletion
        }
    };

    const handlePackageDelete = (index, id) => {
      const buttonClicked = window.confirm("Are you sure you want to delete this game package record?");
  
      if (buttonClicked) {
          setIsLoading(true);
          
          // Make the delete request to the backend
          axios.delete(`${API_URL}/delete-game-package/${id}`)
              .then(res => {
                  setIsLoading(false);
                  Swal.fire({
                      icon: "success",
                      text: res.data.message
                  }).then(() => {
                      // Update frontend state after successful deletion
                      const updatedGamePackages = gamePackages.filter((_, i) => i !== index);
                      setGamePackages(updatedGamePackages);
                  });
  
                  // Refresh the game type list after deletion
                  getGamePackageList();
              })
              .catch(error => {
                  setIsLoading(false);
                  Swal.fire({
                      icon: "error",
                      text: error.response.data.message
                  });
              });
      } else {
          // No action needed if user cancels deletion
      }
  };

  const handleCorporateDelete = (index, id) => {
    const buttonClicked = window.confirm("Are you sure you want to delete this corporate event record?");

    if (buttonClicked) {
        setIsLoading(true);
        
        // Make the delete request to the backend
        axios.delete(`${API_URL}/delete-corporate-event/${id}`)
            .then(res => {
                setIsLoading(false);
                Swal.fire({
                    icon: "success",
                    text: res.data.message
                }).then(() => {
                    // Update frontend state after successful deletion
                    const updatedCorporateEvents = corporateEvents.filter((_, i) => i !== index);
                    setCorporateEvents(updatedCorporateEvents);
                });

                // Refresh the game type list after deletion
                getCorporateEventList();
            })
            .catch(error => {
                setIsLoading(false);
                Swal.fire({
                    icon: "error",
                    text: error.response.data.message
                });
            });
    } else {
        // No action needed if user cancels deletion
    }
};

    const handleEdit = (gameTypeId, dayIndex) => {
        setErrorList([]);
        setUpdateOption('');
        setSelectedDate(null);
  
        const selectedTimeSlot = timeSlotDetails[gameTypeId].days[dayIndex];
    
        // Format the time to HH:MM format
        const formattedStartTime = moment(selectedTimeSlot.start_time, ['h:mm A', 'HH:mm']).format('HH:mm');
        const formattedEndTime = moment(selectedTimeSlot.end_time, ['h:mm A', 'HH:mm']).format('HH:mm');
    
        setFormDataTimeSlot({
            ...selectedTimeSlot,
            start_time: formattedStartTime,
            end_time: formattedEndTime,
        });

        
        setEditingRow({gameTypeIndex: gameTypeId, dayIndex, dayName: selectedTimeSlot.day });

        setShowTimeSlotModal(true);
    }; 
  


    const handleEditSpecificDate = (gameTypeId, dayIndex, dateIndex) => {
      setUpdateOption('specific_date'); // Assuming this is used for distinguishing between specific date edit
      setSelectedDate(dateIndex); // Set selected date index if needed for other operations
    

      const selectedTimeSlot = timeSlotDetails[gameTypeId].days[dayIndex];
      const selectedSpecificDate = timeSlotDetails[gameTypeId].days[dayIndex].specific_dates[dateIndex];
    
      // Format the time to HH:MM format
      const formattedStartTime = moment(selectedSpecificDate.start_time, ['h:mm A', 'HH:mm']).format('HH:mm');
      const formattedEndTime = moment(selectedSpecificDate.end_time, ['h:mm A', 'HH:mm']).format('HH:mm');
    
     
      // Set form data for editing specific date
      setFormDataTimeSlot({
        ...selectedTimeSlot,
        ...selectedSpecificDate,
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        day: selectedTimeSlot.day,
      });

      setEditingRow({
        gameTypeIndex: gameTypeId,
        dayIndex,
        dayName: selectedTimeSlot.day,
        specificDate: selectedSpecificDate.specific_date, // Include dateIndex if needed for updating specific date
      });

      
   setShowTimeSlotDateModal(true);
  }; 


  const handleDeleteSpecificDate = async (gameTypeId, dayIndex, dateIndex) => {

    var buttonClicked = window.confirm("Are you sure you want to delete specific date ?");
    if(buttonClicked == true){
    const selectedTimeSlot = timeSlotDetails[gameTypeId].days[dayIndex];
    const selectedSpecificDate = timeSlotDetails[gameTypeId].days[dayIndex].specific_dates[dateIndex];
  
    const formattedStartTime = moment(selectedSpecificDate.start_time, ['h:mm A', 'HH:mm']).format('HH:mm');
    const formattedEndTime = moment(selectedSpecificDate.end_time, ['h:mm A', 'HH:mm']).format('HH:mm');


    const updatedFormDataTimeSlot = {
        ...selectedTimeSlot,
        ...selectedSpecificDate,
        start_time: formattedStartTime,
        end_time: formattedEndTime,
        day: selectedTimeSlot.day,
        is_active: false,
    };

    
    setFormDataTimeSlot(updatedFormDataTimeSlot);

    const postData = {
        ...updatedFormDataTimeSlot,
        location_id: selectedGameLocation,
        game_id: selectedGame,
        game_type_id: gameTypeId,
        day: updatedFormDataTimeSlot.day, // Include the day name in the postData
        created_by: userContext.userDetails.id,
        specific_date: updatedFormDataTimeSlot.specific_date, // Ensure this is correctly set
        is_active: false,
    };

    
      try {

       
        const response = await axios.post(`${API_URL}/save-game-exception`, postData);
    
        // Refetch data after saving
        await fetchData(gameTypeId);
    
        // Update the game exceptions state
        await getGameExceptionList();
    
        // handleCancel();
        // handleDateCancel();
        Swal.fire({
          icon: "success",
          text: response.data.message
        }).then(() => {
          setLoading(false);
        });
      } catch (error) {
        setLoading(false);
        setErrorList(error.response.data.validate_err);
        Swal.fire({
          text: error.response.data.message,
          icon: "error"
        });
      }
    }
  };

  
   


const handleDateCancel = () => {
  setShowTimeSlotDateModal(false);
  setEditingRow(null);
  setFormDataTimeSlot({});
};
    const handleCancel = () => {
        setShowTimeSlotModal(false);
        setEditingRow(null);
        setFormDataTimeSlot({});
    };

    const handleTimeSlotInputChange = (e) => {
        const { name, value } = e.target;
        setFormDataTimeSlot({ ...formDataTimeSlot, [name]: value });
    };

   

    const handleSaveTimeSlotChange = async () => {
        setLoading(true);
      
        const { gameTypeIndex, dayIndex,dayName } = editingRow;
        const postData = {
          ...formDataTimeSlot,
          location_id: selectedGameLocation,
          game_id: selectedGame,
          game_type_id: gameTypeIndex,
          day: dayName, // Include the day name in the postData
          created_by: userContext.userDetails.id,
          is_active: true,
          updateOption: updateOption,
        };
     

        if (updateOption === 'specific_date') {
          // Convert selectedDate to UTC before formatting
          const formattedDate = selectedDate ? new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate())).toISOString().split('T')[0] : '';
          postData.specific_date = formattedDate;
        } else {
          postData.specific_date = null;
        }
      
        try {
          const response = await axios.post(`${API_URL}/save-game-exception`, postData);
      
          // Refetch data after saving
          await fetchData(gameTypeIndex);
      
          // Update the game exceptions state
          await getGameExceptionList();
      
          handleCancel();
          handleDateCancel();
          Swal.fire({
            icon: "success",
            text: response.data.message
          }).then(() => {
            setErrorList([]);
            setLoading(false);
          });
        } catch (error) {
          setLoading(false);
          setErrorList(error.response.data.validate_err);
        //   Swal.fire({
        //     text: error.response.data.message,
        //     icon: "error"
        //   });
        }
      };

      const handleSaveTimeSlotDateChange = async () => {
        setLoading(true);
      
        const { gameTypeIndex, dayIndex,specificDate,dayName } = editingRow;
        const postData = {
          ...formDataTimeSlot,
          location_id: selectedGameLocation,
          game_id: selectedGame,
          game_type_id: gameTypeIndex,
          day: dayName, // Include the day name in the postData
          created_by: userContext.userDetails.id,
          specific_date : specificDate,
          is_active: true,
        };
     
      
        try {
          const response = await axios.post(`${API_URL}/save-game-exception`, postData);
      
          // Refetch data after saving
          await fetchData(gameTypeIndex);
      
          // Update the game exceptions state
          await getGameExceptionList();
      
          handleCancel();
          handleDateCancel();
          Swal.fire({
            icon: "success",
            text: response.data.message
          }).then(() => {
            setLoading(false);
          });
        } catch (error) {
          setLoading(false);
          setErrorList(error.response.data.validate_err);
          Swal.fire({
            text: error.response.data.message,
            icon: "error"
          });
        }
      };
                
    //   const handleSaveTimeSlotChange = async () => {
    //     try {
    //       setLoading(true);
      
    //       const { gameTypeIndex, dayIndex, dayName } = editingRow;
    //       const postData = {
    //         ...formDataTimeSlot,
    //         location_id: selectedGameLocation,
    //         game_id: selectedGame,
    //         game_type_id: gameTypeIndex,
    //         day: dayName, // Include the day name in the postData
    //         created_by: userContext.userDetails.id,
    //       };
         
      
    //       if (updateOption === 'specific_date') {
    //         // Convert selectedDate to UTC before formatting
    //         const formattedDate = selectedDate ? new Date(Date.UTC(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate())).toISOString().split('T')[0] : '';
      
    //         postData.specific_date = formattedDate;
      
    //         await axios.post(`${API_URL}/save-game-exception`, postData).then(res => {
    //           const updatedGameTypes = [...timeSlotDetails];
    //           updatedGameTypes[gameTypeIndex - 1].days[dayIndex] = {
    //             ...updatedGameTypes[gameTypeIndex - 1].days[dayIndex],
    //             ...formDataTimeSlot
    //           };
    //           setTimeSlotDetails(updatedGameTypes);
    //           handleCancel();
    //           Swal.fire({
    //             icon: "success",
    //             text: res.data.message
    //           }).then(() => {
    //             setErrorList([]);
    //             getGameExceptionList();
    //             getGameTypeList();
    //             setLoading(false);
    //           });
    //         });
    //       } else if (updateOption === 'update_all') {
    //         // Update all created game types
    //          const gameTypePromises = timeSlotDetails.map(async (gameType, index) => {
    //            const updatedPostData = {
    //             ...postData,
    //         //     game_type_id: gameType.game_type_id,
    //            };
      
    //         console.log('postdate',postData)
    //           return axios.post(`${API_URL}/save-game-exception`, updatedPostData).then(res => {
    //             const updatedGameTypes = [...timeSlotDetails];
    //             updatedGameTypes[index].days.forEach((day) => {
    //               if (day.name === formDataTimeSlot.day) {
    //                 day.start_time = formDataTimeSlot.start_time;
    //                 day.end_time = formDataTimeSlot.end_time;
    //                 day.adults_first_price = formDataTimeSlot.adults_first_price;
    //                 day.adults_second_price = formDataTimeSlot.adults_second_price;
    //                 day.adults_third_price = formDataTimeSlot.adults_third_price;
    //               }
    //             });
      
    //             return updatedGameTypes;
    //           });
    //         });
      
    //         // Wait for all promises to resolve
    //         Promise.all(gameTypePromises).then((updatedGameTypesArray) => {
    //           setTimeSlotDetails(updatedGameTypesArray[0]); // Assuming only one array element needed to update state
    //           handleCancel();
              
    //           Swal.fire({
    //             icon: "success",
    //             text: "Time slots updated successfully"
    //           }).then(() => {
    //             setErrorList([]);
    //             setEditingRow(null);
    //             setFormDataTimeSlot({});
    //             getGameExceptionList();
    //             getGameTypeList();
    //             setLoading(false);
    //           });
      
    //         }).catch(error => {
    //           setLoading(false);
    //           setErrorList(error.response.data.validate_err);
    //           Swal.fire({
    //             text: error.response.data.message,
    //             icon: "error"
    //           });
    //         });
    //       }
      
    //       // Reset editing state and form data
    //       setEditingRow(null);
    //       setFormDataTimeSlot({});
      
    //     } catch (error) {
    //       setLoading(false);
    //       setErrorList(error.response.data.validate_err);
    //       Swal.fire({
    //         text: error.response.data.message,
    //         icon: "error"
    //       });
    //     }
    //   };

    



    const handleEditException = (item) => {
        setSelectedException(item);
        setShowEditModal(true);
    };
    
    const handleDeleteException = (e,id) =>{
        e.preventDefault();
        var buttonClicked = window.confirm("Are you sure you want to delete time slot change record ?");
        if(buttonClicked == true){
            setIsLoading(true);
           // const thisClicked = e.currentTarget;
           // thisClicked.innerText = "Deleting";
            axios.delete(`${API_URL}/delete-game-exception/`+id).then(res => {
                //fetchData();
                setIsLoading(false);
          Swal.fire({
                  icon:"success",
                  text:res.data.message
                }).then(function(){
                   // thisClicked.closest("tr").remove();  
                    
              });

               getGameExceptionList();
            })
        }
        else
        {
            getGameExceptionList();
        }
       
       }

    const handleUpdateException = async (e) => {
        e.preventDefault();
        setLoading(true);  
        
    

            // await axios.put(`${API_URL}/update-game-exception/${selectedException.id}`, selectedException).then(res =>{
                await axios.put(`${API_URL}/update-game-exception/${selectedException.id}`,selectedException).then(res =>{   
               
                //fetchData();
                getGameExceptionList();
                Swal.fire({
                    icon:"success",
                    text:res.data.message
                  }).then(function(){ 
                       
                    setShowEditModal(false); 
                    setLoading(false);  
                       
                });
                }).catch(function(error) {
                 
                    setErrorList(error.response.data.validate_err);
                    Swal.fire({
                      text:error.response.data.message,
                      icon:"error"
                    })
                    setLoading(false);
                })
        

    }
  

    const handleChange = (e) => {
        setGameDetails({
            ...gameDetails,
            [e.target.name]: e.target.value,
        });
    };


    const handleAddNewGameType = () => {

        if (!isTimeSlotSaved) {
            setValidationMessage('Please click above to enter time slot details.');
        } else {
            setValidationMessage('');
            handleShowModal(); // Open the "Add New" modal
        }
    };

    return (
        <div>
            <SideNav />
            <TopBar />
            {isLoading?
     <div className="loader-container" style ={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: "fixed",top: "0",left: "0",width: "100%",height: "100%",display: "flex",justifyContent: "center",alignItems: "center",zIndex: "9999"}}>
    <div class="d-flex justify-content-center">
  <div class="spinner-border" role="status">
  </div>
  </div>
</div>:null}

            <div className="right_panel position contact_section vh-100" id="right_panel">
                <div className="main_title d-flex align-items-center">
                    <h1><i className="bi bi-calendar-check"></i>Game Type</h1>
                </div>

                <div className="content_wrapper pt-0">
                    <div className="vm_content_box_1">
                        <div className="title">
                            <h2>Create Game Type</h2>
                        </div>
                        <div className="content pt-3 ">
                          
                          
                                <div className="row">
                                <div class=" col-md-3">
                            <div class="form-group">
                            <label for="game">Game Location</label>
                            <select  class="form-select" id="selectedGameLocation" name="selectedGameLocation" value={selectedGameLocation} onChange={handleGameAvailableLocationChange}>
                            <option value="">Select game location</option>
                        {filteredLocations.map((location) => (
                            <option key={location.id} value={location.id}>
                                {location.location_name}
                            </option>
                        ))}
        </select>
        <span className="text-danger">{error_list.location_id}</span>
                            </div>
                        </div>


                        <div className="col-md-3">
        <div className="form-group d-flex flex-column align-items-start">
          <label htmlFor="game">Game</label>
          <div className="d-flex align-items-center">
            <select className="form-select me-2" id="game" name="game" value={selectedGame} onChange={handleGameChange}>
              <option value="">Select a game</option>
              {game_available.map((game) => (
                <option key={game.id} value={game.id}>
                  {game.name}
                </option>
              ))}
            </select>
            <button className="btn btn-link p-0" onClick={() => handleShow(selectedGame)}>
              <i className="bi bi-pencil-square"></i>
              
            </button>
            
           
          </div>
          {validationMessage && <div className="text-danger" style={{padding:'5px', marginLeft:'130px'}}>{validationMessage}</div>}
          <span className="text-danger">{error_list.game_id}</span>
        </div>
        
      </div>
                                  

                                    </div>

                                    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Time Slot details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={saveGameDetails}>
            <div className='content py-2'>
          <div className='row'>
          <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="start_time">Start Time</label>
                                            <input type="time" className="form-control" id="start_time" name="start_time"  value={gameDetails.start_time}
                                            onChange={handleChange} placeholder="select start time" />
                                            <span class="text-danger">{error_list.start_time}</span>
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="end_time">End Time</label>
                                            <input type="time" className="form-control" id="end_time" name="end_time" value={gameDetails.end_time} onChange={handleChange} placeholder="select end time" />
                                            <span class="text-danger">{error_list.end_time}</span>
                                        </div>
                                    </div>


                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="time_slot_interval">Time Slot Interval</label>
                                            
                        <select
                            className="form-control" name="time_slot_interval"
                            id="time_slot_interval"
                            value={gameDetails.time_slot_interval}
                            onChange={handleChange}
                        >
                            <option value="">Select Time Interval</option>
                            <option value="15">15 minutes</option>
                            <option value="30">30 minutes</option>
                            <option value="60">1 hour</option>
                            <option value="120">2 Hours</option>
                            <option value="180">3 hours</option>
                            <option value="240">4 hours</option>
                            <option value="300">5 hours</option>

                        </select>
                                            <span class="text-danger">{error_list.time_slot_interval}</span>
                                        </div>
                                    </div>
                                    </div>

                                    <div class="d-md-flex align-items-center justify-content-end">
                                    <div>
                                        <button type="submit"   class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i>{loading ? "Saving..." : "Save"} </button>
                                    </div>
                                </div>
                                    </div>
            
          </Form>
        </Modal.Body>
      </Modal>



      <TabContainer activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
                <Nav variant="tabs">
                <NavItem>
                        <NavLink eventKey="gameTypes">Game Types</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink eventKey="packages">Game Packages</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink eventKey="corporateEvents">Corporate Events</NavLink>
                    </NavItem>
                   
                </Nav>
                <TabContent>


<TabPane eventKey="gameTypes">
<div class="title d-flex justify-content-between" style={{padding:'0px'}}>
                        <h2><i class="bi bi-controller"></i> Game Type</h2>
                        <button class="btn_secondary text-white my-1 py-2" onClick={() => handleAddNewGameType ()}>Add New</button>
                        
                    </div>
                 
                    {gameTypeRecordsExist && (
                                <>
                                   <div class="table-responsive">
                                <table id="example" class="display dataTable dtr-inline" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th>Game Type</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Min Players</th>
                                            <th>Max Players</th>
                                            <th>Max players</th>
                                            <th>First Slot Price</th>
                                            <th>Second Slot Price</th>
                                            <th>Third Slot Price</th>
                                            <th>Deposit Method</th>
                                            <th>Deposit Amount Per Person</th>
                                            <th>Deposit Percentage</th>

                                            <th class="text-end">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                    {filteredResult.length === 0 ? (
        <tr>
          <td colSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
            <p className="odd" style={{ margin: 0 }}>No records found</p>
          </td>
        </tr>
      ) : (


                                        gameTypes.map((gameType, index) => (
                                            <tr key={index}>
                                                <td>{gameType.game_type_name}</td>
                                                <td>{gameType.common_title}</td>
                                                <td>{gameType.common_description}</td>
                                                <td>{gameType.min_players}</td>
                                                <td>{gameType.max_players}</td>
                                                <td>{gameType.max_players_in_a_slot}</td>
                                                <td>{gameType.adults_first_price}</td>
                                                <td>{gameType.adults_second_price}</td>
                                                <td>{gameType.adults_third_price}</td>
                                                <td>{gameType.deposit_method === 1 ? 'Amount' : gameType.deposit_method === 2 ?'Percentage': '' }</td>
                                                <td>{gameType.deposit_method === 1 ? gameType.deposit_amount_per_person : '-'}</td>
                                                <td>{gameType.deposit_method === 2 ? gameType.deposit_percentage : '-'}</td>
                                                

                                                <td class="d-flex justify-content-end">
                                                <button class="btn btn-sm btn_secondary"  onClick={() => handleViewTimeSlots(gameType.id)} style={{ background: "orange", color: "white", margin: "0px 10px 0px 0px"}} >
                                                <i class="bi bi-eye"></i></button>

                                                    <button class="btn btn-sm btn_secondary" onClick={() => handleShowModal(index)}>
                                                        <i class="bi bi-pencil-square"></i></button>
                                                    <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => handleDelete(index, gameType.id)}><i class="bi bi-trash3"></i></button>
                                                </td>


                                            </tr>
                                        )))}
                                        

                                    </tbody>

                                    <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="13" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                                    {/* <tfoot>
                                     <tr>
                                            <th>Game Type</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Min Players</th>
                                            <th>Max Players</th>
                                            <th>Max players</th>
                                            <th>First Slot Price</th>
                                            <th>Second Slot Price</th>
                                            <th>Third Slot Price</th>
                                            <th>Deposit Method</th>
                                            <th>Deposit Amount Per Person</th>
                                            <th>Deposit Percentage</th>
                                            <th class="text-end">Action</th>
                                        </tr>
                                    </tfoot> */}
                                </table>
                                </div>
                                <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredResult.length)} of ${filteredResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />

                                    </div></>

)}  



                    <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingIndex !== null ? 'Edit Game Type' : 'New Game Type'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                                    <div className="row">
                                    <div className="col-md-6">
                                    <div className="form-group">
                                            <label htmlFor="game_type">Game Type Name</label>
                                            
                        <input
                            className="form-control" name="game_type_name" placeholder='Enter Game type name'
                            id="game_type_name"
                            value={formData.game_type_name}
                            onChange={handleInputChange}
                        />
                           
                                            <span class="text-danger">{error_list.game_type_name}</span>
                                        </div>
                                        </div>


                                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="common_title">Title</label>
                                <input
                                    type="text" className="form-control" name="common_title" id="common_title" value={formData.common_title} placeholder="Enter title"
                                    onChange={handleInputChange}
                                />
                                <span className="text-danger">{error_list.common_title}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="common_description">Description</label>
                                <input
                                    type="text" className="form-control" name="common_description" id="common_description" value={formData.common_description} placeholder="Enter description"
                                    onChange={handleInputChange}
                                />
                                <span className="text-danger">{error_list.common_description}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="min_players">Min players</label>
                                <input
                                    type="number" className="form-control" name="min_players" id="min_players" value={formData.min_players} placeholder="Enter min players"
                                    onChange={handleInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.min_players}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="max_players">Max players</label>
                                <input
                                    type="number" className="form-control" name="max_players" id="max_players" value={formData.max_players} placeholder="Enter max players"
                                    onChange={handleInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.max_players}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="max_players_in_a_slot">Max players in a slot</label>
                                <input
                                    type="number" className="form-control" name="max_players_in_a_slot" id="max_players_in_a_slot" value={formData.max_players_in_a_slot} placeholder="Enter max players in a slot"
                                    onChange={handleInputChange} min="0"
                                />          
                                <span className="text-danger">{error_list.max_players_in_a_slot}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="adults_first_price">First Slot Price</label>
                                <input
                                    type="number" className="form-control" name="adults_first_price" id="adults_first_price" value={formData.adults_first_price} placeholder="First slot price"
                                    onChange={handleInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.adults_first_price}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="adults_second_price">Second Slot Price</label>
                                <input
                                    type="number" className="form-control" name="adults_second_price" id="adults_second_price" value={formData.adults_second_price} placeholder="Second slot price"
                                    onChange={handleInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.adults_second_price}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="adults_third_price">Third Slot Price</label>
                                <input
                                    type="number" className="form-control" name="adults_third_price" id="adults_third_price" value={formData.adults_third_price} placeholder="Third slot price"
                                    onChange={handleInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.adults_third_price}</span>
                            </div>
                        </div>

                       

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="deposit_method">Deposit Method</label>
                                <select  class="form-select" id="deposit_method" name="deposit_method" value={formData.deposit_method} 
                                 onChange={handleInputChange }>
          <option value="">Select Method </option>
        
            <option value={1}>Amount</option>
            <option value={2}>Percentage</option>
         
        </select>
                                <span className="text-danger">{error_list.deposit_method}</span>
                            </div>
                        </div>
            
                        {formData.deposit_method === 1 && (
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="deposit_amount_per_person">Amount Per person</label>
                                <input
                                    type="number" className="form-control" name="deposit_amount_per_person" id="deposit_amount_per_person" value={formData.deposit_amount_per_person} placeholder="Amount per person"
                                    onChange={handleInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.deposit_amount_per_person}</span>
                            </div>
                        </div>
                              )}   

                                {formData.deposit_method === 2 && (
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="deposit_percentage">Deposit percentage</label>
                                <input
                                    type="number" className="form-control" name="deposit_percentage" id="deposit_percentage" value={formData.deposit_percentage} placeholder="Deposit percentage"
                                    onChange={handleInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.deposit_percentage}</span>
                            </div>
                        </div>
                              )}         

                   
              
               
                </div>

                        
                            
                                <div class="d-md-flex align-items-center justify-content-end">
                                    <div>
                                        <button onClick={handleFormSubmit} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i> 
                                         {loading ?'Saving' : 'Save'}
                                        </button>
                                    </div>
                                </div>

                                </Modal.Body>
                                </Modal>


                                {selectedGameTypeForView && (
        <div>

{timeSlotDetails[selectedGameTypeForView] ? (  
             <>
          <div className="title d-flex justify-content-between col-md-12" style={{ padding: '0px' }}>
            <h2><i className="bi bi-clock"></i> Daily Time Slots - {gameTypes.find(gt => gt.id === selectedGameTypeForView)?.game_type_name}</h2>
          </div>
        
            
            <div className="day-box-container">
              {timeSlotDetails[selectedGameTypeForView].days.map((dayDetail, dayIndex) => (
                <div key={dayIndex} className="day-box">
                  <div className="day-box-header d-flex justify-content-between align-items-center">
                    <h3>{dayDetail.day}</h3>
                    <button className="btn btn-sm btn_secondary" onClick={() => handleEdit(selectedGameTypeForView, dayIndex)}>
                      <i className="bi bi-pencil-square"></i>
                    </button>
                  </div>
                  <div className="day-box-body">
                    <div className='row'>
                      <div className="form-group col-md-3">
                        <label>Start Time</label>
                        <input
                          className="form-control"
                          name="start_time"
                        //   value={moment(dayDetail.start_time, 'HH:mm:ss').format('h:mm A') || '-'}
                          value={dayDetail.start_time || '-'}
                          disabled
                        />
                      </div>
                      <div className="form-group col-md-3">
                        <label>End Time</label>
                        <input
                          className="form-control"
                          name="end_time"
                         // value={moment(dayDetail.end_time, 'HH:mm:ss').format('h:mm A') || '-'}
                          value={dayDetail.end_time || '-'}
                          disabled
                        />
                      </div>
                      <div className="form-group col-md-2">
                        <label>First Price</label>
                        <input
                          type="text"
                          className="form-control"
                          name="adults_first_price"
                          value={dayDetail.adults_first_price || '-'}
                          disabled
                        />
                      </div>
                      <div className="form-group col-md-2">
                        <label>Second Price</label>
                        <input
                          type="text"
                          className="form-control"
                          name="adults_second_price"
                          value={dayDetail.adults_second_price || '-'}
                          disabled
                        />
                      </div>
                      <div className="form-group col-md-2">
                        <label>Third Price</label>
                        <input
                          type="text"
                          className="form-control"
                          name="adults_third_price"
                          value={dayDetail.adults_third_price || '-'}
                          disabled
                        />
                      </div>
                      
                    </div>
                  </div>
                  <div className="specific-dates-container">
          
            {dayDetail.specific_dates.length > 0 ? (
                <>
                <ul>
                        {dayDetail.specific_dates.map((dateDetail, dateIndex) => (
                          <li key={dateIndex} className="date-box">
                            <div className="date-box-header d-flex justify-content-between align-items-center">
                            <h4>Specific Date -{moment(dateDetail.specific_date).format('MMMM Do YYYY')}</h4>
                              <div>
                              <button class="btn btn-sm btn_secondary"  onClick={() => handleEditSpecificDate(selectedGameTypeForView, dayIndex,dateIndex)}>
                                                            <i class="bi bi-pencil-square"></i></button>
                                                        <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                            onClick={() => handleDeleteSpecificDate(selectedGameTypeForView, dayIndex,dateIndex)}><i class="bi bi-trash3"></i></button>
                              </div>
                            </div>
                            <div className="date-box-body">
                              <div className='row'>
                                <div className="form-group col-md-3">
                                  <label>Start Time</label>
                                  <input
                                    className="form-control"
                                    name="start_time"
                                    value={dateDetail.start_time || '-'}
                                    disabled />
                                </div>
                                <div className="form-group col-md-3">
                                  <label>End Time</label>
                                  <input
                                    className="form-control"
                                    name="end_time"
                                    value={dateDetail.end_time || '-'}
                                    disabled />
                                </div>
                                <div className="form-group col-md-2">
                                  <label>First Price</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="adults_first_price"
                                    value={dateDetail.adults_first_price || '-'}
                                    disabled />
                                </div>
                                <div className="form-group col-md-2">
                                  <label>Second Price</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="adults_second_price"
                                    value={dateDetail.adults_second_price || '-'}
                                    disabled />
                                </div>
                                <div className="form-group col-md-2">
                                  <label>Third Price</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="adults_third_price"
                                    value={dateDetail.adults_third_price || '-'}
                                    disabled />
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul></>
            ) : 
              null
            }
          </div>
           
            

<div class="d-flex justify-content-end">
                  <button className="btn btn-sm btn_secondary" onClick={() => toggleHistory(selectedGameTypeForView, dayIndex)}  
                 style={{
                    border: 'none',
                    background: 'none',
                    padding: 0,
                    textDecoration: 'underline',
                    color: 'blue',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                    
                    <i className={`bi bi-${showHistory[`${selectedGameTypeForView}-${dayIndex}`] ? 'dash' : 'plus'}`}>
    {showHistory[`${selectedGameTypeForView}-${dayIndex}`] ? ' Show Less' : ' Show More'}
</i>

            </button>
            </div>



            
            {showHistory[`${selectedGameTypeForView}-${dayIndex}`] && (
                 <div class="py-2 mt-4 border-top ">
              <div className="day-box-history">
                <div className="title d-flex justify-content-between col-md-12" style={{ padding: '0px'}}>
                  <h2><i className="bi bi-clock"></i> Time Slot Change History</h2>
                </div>
              


                <div className="row pt-3">
                  <div className="col-md-9 form-group">
                    <div className="d-flex input_wrapper">
                      <div>
                        <label>   Show{' '}
          <select
            name="example_length"
            aria-controls="example"
            value={perPage}
            onChange={(e) => { setCurrentPage(0); setPerPage(Number(e.target.value)) }}
          > 
         
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          {' '}entries
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table id="example" className="display dataTable dtr-inline" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Update Option</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>First Price</th>
                        <th>Second Price</th>
                        <th>Third Price</th>
                        <th>Updated By</th>
                        <th>Updated on</th>
                        <th>Action</th>
                        {/* <th class="text-end">Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                   


{filteredTimeSlotChangeResults
 .filter(item => getDayIndex(item.day) === dayIndex)
.length === 0 ? (
        <tr>
          <td colSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
            <p className="odd" style={{ margin: 0 }}>No records found</p>
          </td>
        </tr>
      ) : (
                      
                        filteredTimeSlotChangeResults.filter(item =>  getDayIndex(item.day) === dayIndex).slice((currentPage * perPage), ((currentPage * perPage) + perPage))
                            .map((item, index) => {
                          const userDetails = team.find(team => team.id === item.created_by);
                          return (
                            <tr key={index}>
                              <td>{item.specific_date ? moment(item.specific_date).format('DD-MM-YYYY') : 'All'}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>{moment(item.start_time, 'HH:mm:ss').format('h:mm A')}</td>
                              <td style={{ whiteSpace: 'nowrap' }}>{moment(item.end_time, 'HH:mm:ss').format('h:mm A')}</td>
                              <td>{item.adults_first_price}</td>
                              <td>{item.adults_second_price}</td>
                              <td>{item.adults_third_price}</td>
                              <td>{userDetails.first_name}</td>
                              <td style={{ whiteSpace: 'nowrap' }}> {moment(item.created_at).format('DD-MM-YYYY, h:mm A')}</td>
                              <td>
                              {item.is_active === 0 ? 'Deleted' : 'Updated'}</td>
                           
                           
                            </tr>
                          );
                          
                        }))
                    
            }
                    </tbody>
                    <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                    {/* <tfoot>
                      <tr>
                        <th>Update Option</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>First Price</th>
                        <th>Second Price</th>
                        <th>Third Price</th>
                        <th>Updated By</th>
                        <th>Updated on</th>
                        <th>Action</th>
                     
                      </tr>
                    </tfoot> */}
                  </table>
                  <div className="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                  {filteredTimeSlotChangeResults.filter(item =>  getDayIndex(item.day) === dayIndex).length > 0 && `Show 1 to ${Math.min(perPage, filteredTimeSlotChangeResults.filter(item =>getDayIndex(item.day) === dayIndex).length)} of ${filteredTimeSlotChangeResults.filter(item =>  getDayIndex(item.day) === dayIndex).length} entries`}
                  </div>
                  <div className="col-md-9" style={{ float: "right" }}>
                    <ReactPaginate style={{ float: "right" }}
                      previousLabel={"< Prev"}
                      nextLabel={"Next >"}
                      breakLabel={'...'}
                      pageCount={Math.ceil(filteredTimeSlotChangeResults.filter(item =>  getDayIndex(item.day) === dayIndex).length / perPage)}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination pagination-sm justify-content-center custom-pagination"}
                      disabledClassName={"disabled"}
                      breakClassName={['page-item']}
                      breakLinkClassName={'page-link'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousClassName={'page-item'}
                      previousLinkClassName={'page-link'}   
                      nextClassName={'page-item'}
                      nextLinkClassName={'page-link'}
                      activeClassName={['active']}
                   />
                  </div>
                </div>
              </div>
              </div>
            )}
          </div>
      

              ))}
       
       </div></>
          ) : ( ''
            // <p>No time slots available for selected game type.</p>
          )} 
          
        
        </div>
      )}









</TabPane>

<TabPane eventKey="packages" title="Game Packages">
<div class="title d-flex justify-content-between col-md-12" style={{padding:'0px'}}>
                        <h2><i class="bi bi-box-seam"></i> Game Package</h2>
                        <button class="btn_secondary text-white my-1 py-2" onClick={() => handlePackageShowModal()}>Add New</button>
                           
                    </div> 



                    <Modal show={packageShowModal} onHide={handlePackageCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingIndex !== null ? 'Edit Game Package' : 'New Game Package'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                                    <div className="row">
                                    <div className="col-md-6">
                                    <div className="form-group">
                                            <label htmlFor="game_type">Package Name</label>
                                            
                        <input
                            className="form-control" name="sub_package_name" placeholder="Enter Sub Package Name"
                            id="sub_package_name"
                            value={packageFormData.sub_package_name}
                            onChange={handlePackageInputChange}
                        />
                                            <span class="text-danger">{error_list.sub_package_name}</span>
                                        </div>
                                        </div>


                                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <input
                                    type="text" className="form-control" name="description" id="description" value={packageFormData.description} placeholder="Enter description"
                                    onChange={handlePackageInputChange}
                                />
                                <span className="text-danger">{error_list.description}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="min_games">Min Games</label>
                                <input
                                    type="number" className="form-control" name="min_games" id="min_games" value={packageFormData.min_games} placeholder="Enter min games"
                                    onChange={handlePackageInputChange} min={0}
                                />
                                <span className="text-danger">{error_list.min_games}</span>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="min_players">Min Players</label>
                                <input
                                    type="number" className="form-control" name="min_players" id="min_players" value={packageFormData.min_players} placeholder="Enter Min players"
                                    onChange={handlePackageInputChange}  min={0}
                                />
                                <span className="text-danger">{error_list.min_players}</span>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="max_players_in_a_slot">Max Players</label>
                                <input
                                    type="number" className="form-control" name="max_players_in_a_slot" id="max_players_in_a_slot" value={packageFormData.max_players_in_a_slot} placeholder="Enter Max players"
                                    onChange={handlePackageInputChange}  min={0}
                                />
                                <span className="text-danger">{error_list.max_players_in_a_slot}</span>
                            </div>
                        </div>
                       
                      
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="food_description">Food Description</label>
                                <input
                                    type="text" className="form-control" name="food_description" id="food_description" value={packageFormData.food_description} placeholder="Enter Food description"
                                    onChange={handlePackageInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.food_description}</span>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="food_quantity">Food Quantity</label>
                                <input
                                    type="number" className="form-control" name="food_quantity" id="food_quantity" value={packageFormData.food_quantity} placeholder="Enter Food quantity"
                                    onChange={handlePackageInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.food_quantity}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="food_details">Food Details</label>
                                <input
                                    type="text" className="form-control" name="food_details" id="food_details" value={packageFormData.food_details} placeholder="Enter Food details"
                                    onChange={handlePackageInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.food_details}</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="drink_description">Drink Description</label>
                                <input
                                    type="text" className="form-control" name="drink_description" id="drink_description" value={packageFormData.drink_description} placeholder="Enter Drink description"
                                    onChange={handlePackageInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.drink_description}</span>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="drink_quantity">Drink Quantity</label>
                                <input
                                    type="number" className="form-control" name="drink_quantity" id="drink_quantity" value={packageFormData.drink_quantity} placeholder="Enter Drink quantity"
                                    onChange={handlePackageInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.drink_quantity}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="drink_details">Drink Details</label>
                                <input
                                    type="text" className="form-control" name="drink_details" id="drink_details" value={packageFormData.drink_details} placeholder="Enter Drink details"
                                    onChange={handlePackageInputChange} min="0"
                                />          
                                <span className="text-danger">{error_list.drink_details}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="price_per_game">Price per game</label>
                                <input
                                    type="number" className="form-control" name="price_per_game" id="price_per_game" value={packageFormData.price_per_game} placeholder="Enter Price per game"
                                    onChange={handlePackageInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.price_per_game}</span>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="birthday_kid">Birthday Kid</label>
                                <input
                                    type="text" className="form-control" name="birthday_kid" id="birthday_kid" value={packageFormData.birthday_kid} placeholder="Enter Birthday kid"
                                    onChange={handlePackageInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.birthday_kid}</span>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="basic_price">Basic Price</label>
                                <input
                                    type="number" className="form-control" name="basic_price" id="basic_price" value={packageFormData.basic_price} placeholder="Enter basic price"
                                    onChange={handlePackageInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.basic_price}</span>
                            </div>
                        </div>
                        
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="deposit_required">Deposit Required:</label>
                                <select  class="form-select" id="deposit_required" name="deposit_required" value={packageFormData.deposit_required} 
                                 onChange={handlePackageInputChange }>
          <option value="">Select deposit required</option>
        
            <option value={1}>Yes</option>
            <option value={0}>No</option>
         
        </select>
                                <span className="text-danger">{error_list.deposit_required}</span>
                            </div>
                        </div>
            
                        {packageFormData.deposit_required === 1 && (
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="deposit_amount">Deposit Amount</label>
                                <input
                                    type="number" className="form-control" name="deposit_amount" id="deposit_amount" value={packageFormData.deposit_amount} placeholder="Enter deposit amount"
                                    onChange={handlePackageInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.deposit_amount}</span>
                            </div>
                        </div>
                              )}         

                </div>

                        
                            
                                <div class="d-md-flex align-items-center justify-content-end">
                                    <div>
                                        <button onClick={handlePackageFormSubmit} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i> 
                                         {loading ?'Saving' : 'Save'}
                                        </button>
                                    </div>
                                </div>

                                </Modal.Body>
                                </Modal>



                                {gamePackageRecordsExist && (
                                <>
                                   <div class="table-responsive">
                                <table id="example" class="display dataTable dtr-inline" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                        <th>Package Name</th>
                            {/* <th>Description</th> */}
                            <th>Min Games</th>
                            <th>Min Players</th>
                            <th>Max Players</th>
                                <th>Basic Price</th>
                                <th>Food description</th>
                                <th>Food Quantity</th>
                                <th>Food details</th>
                                <th>Drink description</th>
                                <th>Drink Quantity</th>
                                <th>Drink details</th>
                                <th>Birthday Kid</th>
                                <th>Price per Game</th>
                                <th>Deposit Required</th>
                                <th>Deposit Amount</th>
                                <th class="text-end">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                     


{filteredPackageResult.length === 0 ? (
        <tr>
          <td colSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
            <p className="odd" style={{ margin: 0 }}>No records found</p>
          </td>
        </tr>
      ) : (



                                        gamePackages.map((gamePackage, index) => (
                                            <tr key={index}>
                                            <td><p>{gamePackage.sub_package_name}</p></td>
                                            {/* <td><p>{gamePackage.description}</p></td> */}
                                            <td><p>{gamePackage.min_games}</p> </td>
                                            <td><p>{gamePackage.min_players}</p> </td>
                                            <td><p>{gamePackage.max_players_in_a_slot}</p> </td>
                                            <td><p>{gamePackage.basic_price}</p> </td>
                                            <td><p>{gamePackage.food_description}</p></td>
                                            <td><p>{gamePackage.food_quantity}</p></td>
                                            <td><p>{gamePackage.food_details}</p></td>
                                            <td><p>{gamePackage.drink_description}</p></td>
                                            <td><p>{gamePackage.drink_quantity}</p></td>
                                            <td><p>{gamePackage.drink_details}</p></td>
                                            <td><p>{gamePackage.birthday_kid}</p></td>
                                            <td><p>{gamePackage.price_per_game}</p> </td>
                                            <td><p>{gamePackage.deposit_required === 1 ? 'Yes' : gamePackage.deposit_required === 0 ? 'No' : ''}</p> </td>
                                            <td><p>{gamePackage.deposit_amount}</p></td>

                                                <td class="d-flex justify-content-end">
                                                    <button class="btn btn-sm btn_secondary" onClick={() => handlePackageShowModal(index)}>
                                                        <i class="bi bi-pencil-square"></i></button>
                                                    <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => handlePackageDelete(index, gamePackage.id)}><i class="bi bi-trash3"></i></button>
                                                </td>



                                            </tr>
                                        )))}
                                     

                                    </tbody>
                                    <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                                    {/* <tfoot>
                                        <tr>
                                        <th>Package Name</th>
                            <th>Description</th>
                            <th>Min Games</th>
                            <th>Max Players in a slot</th>
                                <th>Basic Price</th>  
                                <th>Food description</th>
                                <th>Food Quantity</th>
                                <th>Food details</th>
                                <th>Drink description</th>
                                <th>Drink Quantity</th>
                                <th>Drink details</th>
                                <th>Birthday Kid</th>
                                <th>Price per Game</th>
                                <th>Deposit Required</th>
                                <th>Deposit Amount</th>
                                <th class="text-end">Action</th>
                                        </tr>
                                    </tfoot> */}
                                </table>
                                </div>
                                <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredPackageResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredPackageResult.length)} of ${filteredPackageResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredPackageResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />

                                    </div></>

)}    

</TabPane>   

<TabPane eventKey="corporateEvents" title="Corporate Events">
<div class="title d-flex justify-content-between col-md-12" style={{padding:'0px'}}>
                        <h2><i class="bi bi-box-seam"></i> Corporate Event</h2>
                        <button class="btn_secondary text-white my-1 py-2" onClick={() => handleCorporateShowModal()}>Add New</button>
                           
                    </div> 



                    <Modal show={corporateShowModal} onHide={handleCorporateCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{editingIndex !== null ? 'Edit Corporate Event' : 'New Corporate Event'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                                    <div className="row">
                                    <div className="col-md-6">
                                    <div className="form-group">
                                            <label htmlFor="game_type">Package Name</label>
                                            
                        <input
                            className="form-control" name="sub_package_name" placeholder="Enter Sub Package Name"
                            id="sub_package_name"
                            value={corporateFormData.sub_package_name}
                            onChange={handleCorporateInputChange}
                        />
                                            <span class="text-danger">{error_list.sub_package_name}</span>
                                        </div>
                                        </div>


                                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="description">Description</label>
                                <input
                                    type="text" className="form-control" name="description" id="description" value={corporateFormData.description} placeholder="Enter description"
                                    onChange={handleCorporateInputChange}
                                />
                                <span className="text-danger">{error_list.description}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="min_games">Min Games</label>
                                <input
                                    type="number" className="form-control" name="min_games" id="min_games" value={corporateFormData.min_games} placeholder="Enter min games"
                                    onChange={handleCorporateInputChange}   min={0}
                                />
                                <span className="text-danger">{error_list.min_games}</span>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="min_players">Min Players</label>
                                <input
                                    type="number" className="form-control" name="min_players" id="min_players" value={corporateFormData.min_players} placeholder="Enter Min players"
                                    onChange={handleCorporateInputChange}  min={0}
                                />
                                <span className="text-danger">{error_list.min_players}</span>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="max_players_in_a_slot">Max Players in a slot</label>
                                <input
                                    type="number" className="form-control" name="max_players_in_a_slot" id="max_players_in_a_slot" value={corporateFormData.max_players_in_a_slot} placeholder="Enter Max players"
                                    onChange={handleCorporateInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.max_players_in_a_slot}</span>
                            </div>
                        </div>
                       
                        
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="food_description">Food Description</label>
                                <input
                                    type="text" className="form-control" name="food_description" id="food_description" value={corporateFormData.food_description} placeholder="Enter Food description"
                                    onChange={handleCorporateInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.food_description}</span>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="food_quantity">Food Quantity</label>
                                <input
                                    type="number" className="form-control" name="food_quantity" id="food_quantity" value={corporateFormData.food_quantity} placeholder="Enter Food quantity"
                                    onChange={handleCorporateInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.food_quantity}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="food_details">Food Details</label>
                                <input
                                    type="text" className="form-control" name="food_details" id="food_details" value={corporateFormData.food_details} placeholder="Enter Food details"
                                    onChange={handleCorporateInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.food_details}</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="drink_description">Drink Description</label>
                                <input
                                    type="text" className="form-control" name="drink_description" id="drink_description" value={corporateFormData.drink_description} placeholder="Enter Drink description"
                                    onChange={handleCorporateInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.drink_description}</span>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="drink_quantity">Drink Quantity</label>
                                <input
                                    type="number" className="form-control" name="drink_quantity" id="drink_quantity" value={corporateFormData.drink_quantity} placeholder="Enter Drink quantity"
                                    onChange={handleCorporateInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.drink_quantity}</span>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor="drink_details">Drink Details</label>
                                <input
                                    type="text" className="form-control" name="drink_details" id="drink_details" value={corporateFormData.drink_details} placeholder="Enter Drink details"
                                    onChange={handleCorporateInputChange} min="0"
                                />          
                                <span className="text-danger">{error_list.drink_details}</span>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="price_per_game">Price per game</label>
                                <input
                                    type="number" className="form-control" name="price_per_game" id="price_per_game" value={corporateFormData.price_per_game} placeholder="Enter Price per game"
                                    onChange={handleCorporateInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.price_per_game}</span>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="basic_price">Basic Price</label>
                                <input
                                    type="number" className="form-control" name="basic_price" id="basic_price" value={corporateFormData.basic_price} placeholder="Enter basic price"
                                    onChange={handleCorporateInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.basic_price}</span>
                            </div>
                        </div>
                        
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="deposit_required">Deposit Required:</label>
                                <select  class="form-select" id="deposit_required" name="deposit_required" value={corporateFormData.deposit_required} 
                                 onChange={handleCorporateInputChange }>
          <option value="">Select deposit required</option>
        
            <option value={1}>Yes</option>
            <option value={0}>No</option>
         
        </select>
                                <span className="text-danger">{error_list.deposit_required}</span>
                            </div>
                        </div>
            
                        {corporateFormData.deposit_required === 1 && (
                        <div className="col-md-4">
                            <div className="form-group">
                                <label htmlFor="deposit_amount">Deposit Amount</label>
                                <input
                                    type="number" className="form-control" name="deposit_amount" id="deposit_amount" value={corporateFormData.deposit_amount} placeholder="Enter deposit amount"
                                    onChange={handleCorporateInputChange} min="0"
                                />
                                <span className="text-danger">{error_list.deposit_amount}</span>
                            </div>
                        </div>
                              )}         

                </div>

                        
                            
                                <div class="d-md-flex align-items-center justify-content-end">
                                    <div>
                                        <button onClick={handleCorporateFormSubmit} class="btn btn-sm btn_secondary text-white"><i class="bi bi-save me-2"></i> 
                                         {loading ?'Saving' : 'Save'}
                                        </button>
                                    </div>
                                </div>

                                </Modal.Body>
                                </Modal>



                                {corporateEventRecordsExist && (
                                <>
                                   <div class="table-responsive">
                                <table id="example" class="display dataTable dtr-inline" style={{ width: "100%" }}>
                                    <thead>
                                        <tr>
                                        <th>Package Name</th>
                            {/* <th>Description</th> */}
                            <th>Min Games</th>
                            <th>Min Players</th>
                            <th>Max Players in a slot</th>
                                <th>Basic Price</th>
                                <th>Food description</th>
                                <th>Food Quantity</th>
                                <th>Food details</th>
                                <th>Drink description</th>
                                <th>Drink Quantity</th>
                                <th>Drink details</th>
                                <th>Price per Game</th>
                                <th>Deposit Required</th>
                                <th>Deposit Amount</th>
                                <th class="text-end">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                       

{filteredCorporateResult.length === 0 ? (
        <tr>
          <td colSpan="4" style={{ textAlign: "center", verticalAlign: "middle" }}>
            <p className="odd" style={{ margin: 0 }}>No records found</p>
          </td>
        </tr>
      ) : (



                                        corporateEvents.map((corporateEvent, index) => (
                                            <tr key={index}>
                                            <td><p>{corporateEvent.sub_package_name}</p></td>
                                            {/* <td><p>{corporateEvent.description}</p></td> */}
                                            <td><p>{corporateEvent.min_games}</p> </td>
                                            <td><p>{corporateEvent.min_players}</p> </td>
                                            <td><p>{corporateEvent.max_players_in_a_slot}</p> </td>
                                            <td><p>{corporateEvent.basic_price}</p> </td>
                                            <td><p>{corporateEvent.food_description}</p></td>
                                            <td><p>{corporateEvent.food_quantity}</p></td>
                                            <td><p>{corporateEvent.food_details}</p></td>
                                            <td><p>{corporateEvent.drink_description}</p></td>
                                            <td><p>{corporateEvent.drink_quantity}</p></td>
                                            <td><p>{corporateEvent.drink_details}</p></td>
                                            <td><p>{corporateEvent.price_per_game}</p> </td>
                                            <td><p>{corporateEvent.deposit_required === 1 ? 'Yes' : corporateEvent.deposit_required === 0 ? 'No' : ''}</p> </td>
                                            <td><p>{corporateEvent.deposit_amount}</p></td>

                                                <td class="d-flex justify-content-end">
                                                    <button class="btn btn-sm btn_secondary" onClick={() => handleCorporateShowModal(index)}>
                                                        <i class="bi bi-pencil-square"></i></button>
                                                    <button class="btn btn-sm btn_secondary" style={{ background: "red", color: "white", margin: "0px 0px 0px 10px" }}
                                                        onClick={(e) => handleCorporateDelete(index, corporateEvent.id)}><i class="bi bi-trash3"></i></button>
                                                </td>



                                            </tr>
                                        )))}
                                     

                                    </tbody>
                                    <tfoot style={{ borderTop: "1px solid #dee2e6", height: "1px" }}>
    <tr>
        <td colSpan="16" style={{ padding: 0, margin: 0, height: "1px" }}></td>
    </tr>
</tfoot>
                                    {/* <tfoot>
                                        <tr>
                                        <th>Package Name</th>
                            <th>Description</th>
                            <th>Min Games</th>
                            <th>Max Players in a slot</th>
                                <th>Basic Price</th>  
                                <th>Food description</th>
                                <th>Food Quantity</th>
                                <th>Food details</th>
                                <th>Drink description</th>
                                <th>Drink Quantity</th>
                                <th>Drink details</th>
                                <th>Birthday Kid</th>
                                <th>Price per Game</th>
                                <th>Deposit Required</th>
                                <th>Deposit Amount</th>
                                <th class="text-end">Action</th>
                                        </tr>
                                    </tfoot> */}
                                </table>
                                </div>
                                <div class="col-md-3" style={{ float: "left", padding: "20px 0px 0px 0px" }}>
                                        {filteredCorporateResult.length > 0 && `Showing 1 to ${Math.min(perPage, filteredCorporateResult.length)} of ${filteredCorporateResult.length} entries`}
                                    </div><div class="col-md-9" style={{ float: "right" }}>

                                        <ReactPaginate style={{ float: "right" }}
                                            previousLabel={"< Prev"}
                                            nextLabel={"Next >"}
                                            breakLabel={'...'}
                                            pageCount={Math.ceil(filteredCorporateResult.length / perPage)}
                                            onPageChange={handlePageChange}
                                            containerClassName={"pagination pagination-sm justify-content-center custom-pagination"} // Bootstrap pagination class
                                            disabledClassName={"disabled"}
                                            breakClassName={['page-item']}
                                            breakLinkClassName={'page-link'}
                                            pageClassName={'page-item'}
                                            pageLinkClassName={'page-link'}
                                            previousClassName={'page-item'}
                                            previousLinkClassName={'page-link'}
                                            nextClassName={'page-item'}
                                            nextLinkClassName={'page-link'}
                                            activeClassName={['active']} />

                                    </div></>

)}    

</TabPane>   
</TabContent>
</TabContainer>



        <Modal show={showTimeSlotModal} onHide={handleCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Daily Time Slots - {formDataTimeSlot.day}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        
                        <div className='row'>

                      
                        <div className="form-group col-md-6">
                            <label>Start Time</label>
                            <input
                                type="time"
                                className="form-control"
                                name="start_time"
                                value={formDataTimeSlot.start_time || ''}
                                onChange={handleTimeSlotInputChange}
                            />
                             <span class="text-danger">{error_list.start_time}</span>
                        </div>
                        <div className="form-group col-md-6">
                            <label>End Time</label>
                            <input
                                type="time"
                                className="form-control"
                                name="end_time"
                                value={formDataTimeSlot.end_time || ''}
                                onChange={handleTimeSlotInputChange}
                            />
                             <span class="text-danger">{error_list.end_time}</span>
                        </div>
                        <div className="form-group col-md-4">
                            <label>First Slot Price</label>
                            <input
                                type="text"
                                className="form-control"
                                name="adults_first_price"
                                value={formDataTimeSlot.adults_first_price || ''}
                                onChange={handleTimeSlotInputChange}
                            />
                             <span class="text-danger">{error_list.adults_first_price}</span>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Second Slot Price</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="adults_second_price"
                                        value={formDataTimeSlot.adults_second_price || ''}
                                        onChange={handleTimeSlotInputChange}
                                    />
                                    <span class="text-danger">{error_list.adults_second_price}</span>
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Third Slot Price</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="adults_third_price"
                                        value={formDataTimeSlot.adults_third_price || ''}
                                        onChange={handleTimeSlotInputChange}
                                    />
                                    <span class="text-danger">{error_list.adults_third_price}</span>
                                </div>

                                <div className="form-group col-md-4">
                                    <label>Update Option</label>
                                    <select class="form-select" value={updateOption} onChange={handleUpdateOptionChange} >
                                        <option value="">Select option</option>
                                        <option value="update_all">Update All</option>
                                        <option value="specific_date">Specific Date</option>
                                    </select>
                                    <span class="text-danger">{error_list.updateOption}</span>
                                
                                </div>

                                {updateOption === 'specific_date' && (
                                <div className="form-group col-md-4">
                                <label>Select Date</label>
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={handleDateChange}
                                    filterDate={(date) =>
                                        date >= new Date() &&  // Filter to show only upcoming dates
                                        indexToDayName[date.getDay()] === formDataTimeSlot.day // Filter to show only specific day (e.g., Mondays)
                                    }
                                    placeholderText="Select Date"
                                    className="form-control"
                                />
                                    <span className="text-danger">{error_list.specific_date}</span> 
                       </div>


                        )}
                      

                        </div>


                    </form>
                </Modal.Body>
                <Modal.Footer>
                   
                    <button class="btn btn-sm btn_secondary text-white"  onClick={handleSaveTimeSlotChange}>
                    <i class="bi bi-save me-2"></i>{loading ? "Updating..." : "Update"}
                    </button>

                    
                </Modal.Footer>
            </Modal>


            <Modal show={showTimeSlotDateModal} onHide={handleDateCancel}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit specific date - {formDataTimeSlot.specific_date}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        
                        <div className='row'>

                      
                        <div className="form-group col-md-6">
                            <label>Start Time</label>
                            <input
                                type="time"
                                className="form-control"
                                name="start_time"
                                value={formDataTimeSlot.start_time || ''}
                                onChange={handleTimeSlotInputChange}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label>End Time</label>
                            <input
                                type="time"
                                className="form-control"
                                name="end_time"
                                value={formDataTimeSlot.end_time || ''}
                                onChange={handleTimeSlotInputChange}
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label>First Slot Price</label>
                            <input
                                type="text"
                                className="form-control"
                                name="adults_first_price"
                                value={formDataTimeSlot.adults_first_price || ''}
                                onChange={handleTimeSlotInputChange}
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label>Second Slot Price</label>
                            <input
                                type="text"
                                className="form-control"
                                name="adults_second_price"
                                value={formDataTimeSlot.adults_second_price || ''}
                                onChange={handleTimeSlotInputChange}
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label>Third Slot Price</label>
                            <input
                                type="text"
                                className="form-control"
                                name="adults_third_price"
                                value={formDataTimeSlot.adults_third_price || ''}
                                onChange={handleTimeSlotInputChange}
                            />
                        </div>

                    
                      

                        </div>


                    </form>
                </Modal.Body>
                <Modal.Footer>
                   
                    <button class="btn btn-sm btn_secondary text-white"  onClick={handleSaveTimeSlotDateChange}>
                    <i class="bi bi-save me-2"></i>{loading ? "Updating..." : "Update"}
                    </button>

                    
                </Modal.Footer>
            </Modal>


          

                    <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
    <Modal.Header closeButton>
        <Modal.Title>Edit Time Slot Change - {selectedException?.day || ''}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <form onSubmit={handleUpdateException}>
        
           <div className='row'>
            
            <div className="form-group col-md-6">
                <label>Start Time</label>
                <input
                    type="time"
                    className="form-control"
                    value={selectedException?.start_time || ''}
                    onChange={(e) => setSelectedException({ ...selectedException, start_time: e.target.value })}
                />
            </div>
            <div className="form-group col-md-6">
                <label>End Time</label>
                <input
                    type="time"
                    className="form-control"
                    value={selectedException?.end_time || ''}
                    onChange={(e) => setSelectedException({ ...selectedException, end_time: e.target.value })}
                />
            </div>
            <div className="form-group col-md-4">
                <label>First Slot Price </label>
                <input
                    type="text"
                    className="form-control"
                    value={selectedException?.adults_first_price || ''}
                    onChange={(e) => setSelectedException({ ...selectedException, adults_first_price: e.target.value })}
                />
            </div>
            <div className="form-group col-md-4">
                <label>Second Slot Price</label>
                <input
                    type="text"
                    className="form-control"
                    value={selectedException?.adults_second_price || ''}
                    onChange={(e) => setSelectedException({ ...selectedException, adults_second_price: e.target.value })}
                />
            </div>
            <div className="form-group col-md-4">
                <label>Third Slot Price</label>
                <input
                    type="text"
                    className="form-control"
                    value={selectedException?.adults_third_price || ''}
                    onChange={(e) => setSelectedException({ ...selectedException, adults_third_price: e.target.value })}
                />
            </div>
            {selectedException?.specific_date && (
                    <div className="form-group col-md-6">
                        <label>Specific Date</label>
                        <DatePicker
                            selected={selectedException?.specific_date ? new Date(selectedException.specific_date) : null}
                            onChange={(date) => {
                                const formattedDate = date ? date.toISOString().split('T')[0] : '';
                                setSelectedException({ ...selectedException, specific_date: formattedDate });
                            }}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            filterDate={(date) => date.getDay() === getDayIndex(selectedException.day)}
                    
                        />
                    </div>
                )}
           
          
            </div>
            <Modal.Footer>
            <button class="btn btn-sm btn_secondary text-white" type="submit" >
                    <i class="bi bi-save me-2"></i>{loading ? "Updating..." : "Update"}
                    </button>
            </Modal.Footer>
        </form>
    </Modal.Body>
</Modal>

               
                    </div>
        </div>

      

                   
                </div>
            </div>
        </div>
    );
}

export default SettingCreateGames;
